import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren} from "@angular/core";

import {SiteService} from "../../shared/services/site.service";
import {
    Plot
} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ModelManager} from "../../shared/helpers/ModelManager";
import {H} from "../../shared/helpers/H";
import {K} from "../../shared/models/K";
import * as Highcharts from 'highcharts';
import {Optimise} from "../../shared/helpers/Optimise";

@Component({
    selector: 'statmens-table',
    template: `
        <div fxLayout="row">
            <div fxFlex>
                <mat-card class="m-4   p-8">
                    <h3>Modelisation des données</h3>
                    <p>Choix des variables explicatives pour le calcul du modèle</p>
                    <table class="bloc-statmens-table " *ngIf="modelManager&&modelManager.currYearCalcedRows.length">
                        <tr class="row-head" *ngFor="let row of modelManager.currYearCalcedRows">
                            <ng-container *ngIf="row.releve_num===2">
                                <td>Date</td>
                                <td (dblclick)="toggleVar('DAYS_CUMUL')">No Jour</td>
                                <td *ngFor="let v of row.var_meteo|keyvalue"
                                    (dblclick)="toggleVar(v.key)">
                                    {{ v.key }}
                                    <span *ngIf="v.key==='djEP'">{{ modelManager.used_cassure|number }}</span>
                                </td>
                                <td *ngFor="let v of row.var_extra|keyvalue"
                                    (dblclick)="toggleVar(v.key)">
                                    {{ row.var_labels[v.key] }}
                                </td>
                                <td *ngFor="let v of row.var_regs|keyvalue"
                                    (dblclick)="toggleVar(v.key)">
                                    {{ row.var_labels[v.key] }}
                                </td>
                                <td></td>
                                <td>VE</td>
                            </ng-container>

                        </tr>
                        <tr #row *ngFor="let row of modelManager.currYearCalcedRows">
                            <td class="value">{{ row.releve_num }}:{{ row.date }}</td>
                            <td class="value"
                                [attr.type]="'DAYS_CUMUL'">{{ row.days_cumul }}
                            </td>
                            <td class="value"
                                [attr.type]=v.key
                                *ngFor="let v of row.var_meteo|keyvalue">
                                {{ v.value|number:"1.2-2" }}
                            </td>
                            <td class="value {{v.key}}"
                                [attr.type]=v.key
                                *ngFor="let v of row.var_extra|keyvalue">
                                {{ v.value|number:"1.2-2" }}
                            </td>
                            <td class="value {{v.key}}"
                                [attr.type]=v.key
                                *ngFor="let v of row.var_regs|keyvalue">
                                {{ v.value|number:"1.2-2" }}
                            </td>
                            <td class="arrow-svg-holder">
                                <img width="60px" src="assets/images/long-right-double-arrow.svg" alt="" class="mb-8">
                            </td>
                            <td class="value">
                                {{ row.ve.raw|number:"1.2-2" }}
                            </td>
                        </tr>
                    </table>
                </mat-card>
            </div>
            <div fxFlex="750px">

                <mat-card class="m-4 p-8" *ngIf="modelManager">
                    <h3 fxLayout="row" fxLayoutAlign="space-between center">
                        <span fxFlex> Correspondance modèle - réalité</span>
                        <button mat-raised-button class="btn-xs mr-8" color="primary"
                                (click)="site.updateModel(chosenVars,modelManager.model)">
                            Sauvegarder le modèle
                        </button>
                        <button mat-raised-button class="btn-xs" color="primary" (click)="calcModel()">Calculer le modèle</button>
                    </h3>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="p-4" *ngFor="let cv of chosenVars;let i=index">
                            <span *ngIf="modelManager.model&&modelManager.model[i]">
                                {{ modelManager.model[i]|number }} x 
                            </span>
                            <b>{{ varLabel(cv) }}</b>
                        </div>
                        <div fxFlex></div>
                        <div>R2: {{ modelManager.r2|number }}</div>
                    </div>

                    <highcharts-chart *ngIf="chartOptions" [Highcharts]="Highcharts"
                                      [options]="chartOptions"></highcharts-chart>
                </mat-card>
            </div>
        </div>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensTableComponent implements OnInit, OnChanges {
    @ViewChildren('row') tableRows: QueryList<any>;
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;

    modelManager: ModelManager;
    chosenVars = [];

    constructor(public myapp: AppService, public site: SiteService, private snackBar: MatSnackBar, public sanitizer: DomSanitizer) {
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {

            if (eventName === SiteService.PDMS_YEARLY_PLOT_DATA_CALCED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_SELECTED) {
                this.bootstrap();
            }
        });
    }

    ngOnInit() {

    }

    bootstrap() {
        this.chosenVars = this.site.selectedConfigForPdmAndYear.vars_used;
        this.modelManager = new ModelManager(this.site.selectedPdmYearlyPlotDataMap, this.site.selectedConfigForPdmAndYear);

        console.log("StatmensTableComponent", this.site.year, this.chosenVars, this.modelManager);
        this.calcModel();
        this.highlightChosenVars();
    }

    varLabel(key) {
        if (this.modelManager.currYearCalcedRows && this.modelManager.currYearCalcedRows.length && this.modelManager.currYearCalcedRows[0].var_labels[key])
            return this.modelManager.currYearCalcedRows[0].var_labels[key];
        else return key;
    }

    toggleVar(key) {
        if (this.chosenVars.includes(key))
            this.chosenVars = this.chosenVars.filter(item => item !== key);
        else this.chosenVars.push(key);

        this.site.selectedConfigForPdmAndYear.vars_used = this.chosenVars;
        this.bootstrap();
    }

    calcModel() {
        this.modelManager.calcModel();
        // console.log("calcModel()", this.site.year, this.modelManager);

        this.chartOptions = {
            chart: {
                displayErrors: false,
                plotBackgroundColor: '#ffffff',
                plotShadow: false,
                borderColor: '#000000',
                borderWidth: 0,
                plotBorderWidth: K.lineWidthBase,
                reflow: false,
                width: 750,
                height: 500,
                style: {
                    fontFamily: "Roboto",
                    fontSize: "14px"
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: undefined
            },
            lang: {
                thousandsSep: "'",
                numericSymbols: null,
            },
            xAxis: {
                lineWidth: 1,
                lineColor: '#000000',
                categories: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
                gridLineColor: "#000000",
                gridLineWidth: 0.2,
                labels: {
                    style: {
                        color: '#000000',
                        fontSize: '14px',
                    }
                }
            },
            yAxis: [{
                lineWidth: 1,
                lineColor: '#000000',
                tickAmount: 7,
                title: {
                    text: undefined
                },
                min: 0,
                labels: {
                    style: {
                        color: '#000000',
                        fontSize: '14px',
                    }
                }
            }, { // Secondary yAxis
                gridLineWidth: 0,
                opposite: true,
                // min: Optimise.min(this.modelManager.error) * 2,
                // max: Optimise.max(this.modelManager.error) * 2,
                title: {
                    text: 'Economie KWh',
                }
            }],
            series: [
                {
                    yAxis: 0,
                    lineWidth: 2,
                    color: 'black',
                    name: "Valeurs réelles",
                    type: "line",
                    data: this.modelManager.consVector || []
                },
                {
                    yAxis: 0,
                    lineWidth: 2,
                    color: K.chartColors[0],
                    name: "Prédiction",
                    type: "line",
                    data: this.modelManager.predictedVector || []
                },
                {
                    tooltip: {
                        headerFormat: "{series.name}",
                        pointFormat: '<br>{point.category}, {point.y:.2f} ',
                    },
                    yAxis: 1,
                    borderWidth: 0.5,
                    borderColor: 'black',
                    color: '#ff9999',
                    name: "Delta",
                    type: "column",
                    data: this.modelManager.error || []
                }
            ]
        };
    }

    highlightChosenVars() {
        if (this.tableRows) {
            this.tableRows.forEach((row, rowIndex) => {
                const rowEl = row.nativeElement as HTMLTableRowElement;
                for (let i = 0; i < rowEl.cells.length; i++) {
                    const cellEl = rowEl.cells[i];
                    const varName = cellEl.getAttribute('type');
                    if (varName && this.chosenVars.includes(varName)) {
                        cellEl.classList.add('selected');
                    } else {
                        cellEl.classList.remove('selected');
                    }
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
    }

}
