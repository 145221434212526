<div class="flex-container" fxLayout="column" style="height: 100%" #plotHolder>


    <div fxLayout="row" class="toolbar-top" fxFlexAlign="space-between end"
         [@animate]="{ value: '*', params: { y: '-190px', delay: '300ms' } }">
        <h2 fxFlex="400px" class="mt-8 ml-16">
            Documents
        </h2>
        <div fxFlex="row">
            <div class="search-bar">
                <mat-icon inline>search</mat-icon>
                <input matInput [(ngModel)]="filterKey" *ngIf="site.documents&&site.documents.length"
                       type="text" fxFlex="400px"
                       class="search-key"
                       (keyup)="refresh($event)"
                       placeholder="Mot clé"/>
            </div>

        </div>

        <div fxFlex></div>
        <span class="vert-divider"></span>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
        <div fxFlex="400px">
            <mat-card class="m-8">
                <h2 class="color-blue"> Chapitres</h2>
                <div class="list-view">
                    <div class="list-item cursor-pointer"
                         [ngClass]="{active:!selectedChapter}"
                         (click)="selectChapter(null)">
                        Tout voir ({{ chapterToCountMap.get('NC') }})
                    </div>
                    <ng-container *ngFor="let ch of site.docsChapters">
                        <div class="list-item cursor-pointer"
                             *ngIf="!isEmptyChapter(ch)"
                             (click)="selectChapter(ch)"
                             [ngClass]="{active:ch===selectedChapter}">
                            {{ ch }} ({{ chapterToCountMap.get(ch) }})
                        </div>
                    </ng-container>

                </div>
            </mat-card>


            <div class="m-8 mt-16">
                <app-storage-uploader *ngIf="myapp.appInitStatusLevel===3&&myapp.getIfUserCanDoForRoute('DOCUMENTS','UPLOAD')"
                                      (uploadComplete)="cbFromUploader($event)"></app-storage-uploader>
            </div>

            <mat-card class="m-8" *ngIf="meta&&myapp.isAdminOrSuperUser">
                <div *ngFor="let m of meta  |keyvalue" fxLayout="row" class="p-4 border-bottom">
                    <b fxFlex="150px">{{ m.key }}:</b>
                    <span>{{ m.value }}</span>
                </div>
            </mat-card>
        </div>
        <div fxFlex>
            <mat-card class="m-8">
                <div class="component-holder relative">
                    <h2 class="color-blue"> Documents</h2>
                    <div class="scroller">
                        <table class="table-actions">
                            <tr class="table-actions-head">
                                <td></td>
                                <td (click)="sort('chapter')" style="width: 120px">Catégorie</td>
                                <td (click)="sort('title')">Nom du fichier</td>
                                <td (click)="sort('ts_file_created')">Création</td>
                                <td (click)="sort('ts')">Ajout</td>
                                <td (click)="sort('firstName')" style="width: 140px">Auteur</td>
                                <td (click)="sort('updated')" style="width: 160px;">Infos</td>
                                <td></td>
                            </tr>
                            <ng-container *ngFor="let doc of docsToShow">
                                <tr class="table-actions-row">
                                    <td style="width: 30px">
                                        <ng-container *ngIf="doc.storage_metas as metas">
                                            <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline
                                                      *ngIf="metas.contentType==='application/pdf'">picture_as_pdf
                                            </mat-icon>
                                            <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline
                                                      *ngIf="metas.contentType.includes('stream')">archive
                                            </mat-icon>
                                            <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline
                                                      *ngIf="metas.contentType.includes('sheet')">article
                                            </mat-icon>
                                            <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline
                                                      *ngIf="metas.contentType.includes('png')">image
                                            </mat-icon>
                                            <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline
                                                      *ngIf="metas.contentType.includes('jpeg')">image
                                            </mat-icon>
                                        </ng-container>
                                    </td>
                                    <td style="width: 200px">
                                        <span *ngIf="!myapp.getIfUserCanDoForRoute('DOCUMENTS','UPLOAD')">
                                            {{ doc.chapter }}
                                        </span>
                                        <mat-select
                                                *ngIf="myapp.getIfUserCanDoForRoute('DOCUMENTS','UPLOAD')"
                                                [value]="doc.chapter" placeholder="Non catégorisé">
                                            <mat-option [value]="ch" (onSelectionChange)="setChapter($event,doc)"
                                                        *ngFor="let ch of site.docsChapters">{{ ch }}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                    <td>{{ doc.title }}</td>
                                    <td>{{ doc.file_creation_date }}</td>
                                    <td>{{ doc.dateAddedStr }}</td>
                                    <td *ngIf="doc.user_metas">
                                        <b>{{ doc.user_metas.firstName }} {{ doc.user_metas.lastName }}</b>
                                    </td>
                                    <td style="width: 200px;font-size: 12px">
                                        <ng-container *ngIf="doc.storage_metas as metas">
                                            {{ metas.updated|amDateFormat:'LLL' }}
                                            <br>
                                            {{ metas.contentType }}/{{ myapp.fileSize(metas.size) }}
                                        </ng-container>
                                    </td>

                                    <td>
                                        <button (click)="selectDoc(doc)"
                                                mat-raised-button color="primary"
                                                class="btn-xs-25">Ouvrir
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
