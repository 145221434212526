import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import {Pdm} from "../../../../shared/models/Pdm";
import {AppService} from "../../../../shared/services/app.service";
import {SiteService} from "../../../../shared/services/site.service";
import {PdmYearlyConfig} from "../../../../shared/models/PdmYearConfig";

@Component({
    selector: 'pdm-yearly-config',
    template: `
        <div fxLayout="column" [ngClass]="{'highlight-row':true}">
            <mat-card>
                Chaque ligne de configuratiuon sera automatiquement générée quand l'année de réference est choisie dans la page
                <a [routerLink]="['/statmens-conf']" class="font-weight-bold color-blue">"Statmens-config"</a>
            </mat-card>
            <div class="m-16" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="start center" class="form-yearly-config mb-4 head-row">
                    <div class="head">Année</div>
                    <div class="label" fxFlex="150px">CEGES<br> <span style="font-weight: lighter;font-size: 11px">[kg CO2/MWh]</span></div>
                    <span class="label">FP: </span>
                    <div fxFlex></div>
                </div>
                <div *ngFor="let yConf of yearlyConfigs" fxLayout="row" fxLayoutAlign="start center" class="form-yearly-config mb-4">
                    <div class="head"> {{ yConf.year }}</div>
                    <input class="input" fxFlex="150px" [(ngModel)]="yConf.ceges" placeholder="CEGES">
                    <input class="input" fxFlex="150px" [(ngModel)]="yConf.fp" placeholder="FP">
                    <button class="btn-xs-25 m-4" fxFlex="80px" color="primary" mat-raised-button (click)="saveConfigAndReload(yConf)">
                        Save
                    </button>
                    <div fxFlex></div>
                </div>
            </div>

            <div fxFlex></div>
        </div>
    `,

    styleUrls: ['./shared.component.scss']
})
export class PdmYearlyConfigComponent implements OnInit, AfterViewInit, OnChanges {
    @Input('ts') ts: number;
    @Input('pdm') pdm: Pdm;

    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    yearlyConfigs: PdmYearlyConfig[] = [];

    constructor(public myapp: AppService, public site: SiteService) {
    }

    ngOnInit() {
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            if (eventName === SiteService.PDMS_DATA_POPULATED) {
                this.bootstrap();
            }
        });
    }

    bootstrap() {
        if (this.pdm) {

        }
    }

    ngAfterViewInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['ts'] && changes['ts'] && changes['ts'].currentValue > 0) {
            // console.log("PdmYearlyConfigComponent:ngOnChange", changes, changes['ts'].currentValue, changes['ts'].currentValue > 0);
            this.bootstrap();
        }
    }


    saveConfigAndReload(newConfig: PdmYearlyConfig) {
        // console.log("PdmYearlyConfigComponent:saveConfigAndReload", newConfig);

    }
}
