import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BmensData, BmensMeter} from 'app/shared/models/Bmens.model';
import {EnergyStats, EnergyStatsSubMeters} from 'app/shared/models/EnergyStats.model';
import {ReportSuivi} from 'app/shared/models/Suivi.model';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";

@Component({
    selector: 'suivi-submeters',
    template: `
        <div class="component-holder">
            <h1 class="color-blue"> Consommation d’énergie par compteur</h1>
            <p>
                <mat-icon inline>fiber_manual_record</mat-icon>
                Détails de consommation d'énergie par sous-compteurs basée sur le bilan mensuel de déc {{ report.year }}
            </p>

            <div>
                <table class="energy-table">
                    <tr class="submeter-row-head">
                        <td class="">Année</td>
                        <td class="text-right">Quantité</td>
                        <td class="text-right">Evolution</td>
                        <td class="text-right">Coûts</td>
                        <td class="submeter-row-notes pl-16">Remarques</td>
                    </tr>
                    <ng-container *ngFor="let group of site.clientSite.bmens_groups">
                        <tr class="submeter-row" *ngIf="visibleGroups[group.uid]">
                            <td colspan="5">
                                <h2 (click)="myapp.showConsole(visibleGroups)">{{ group.label }}</h2>
                            </td>
                        </tr>
                        <ng-container *ngFor="let meter of bmensMeters">
                            <tr *ngIf="metersAvailable.includes(meter.uid)&&meter.uid_group===group.uid&&visibleMeters[meter.label]"
                                class="submeter-row">
                                <td colspan="5" class="meter-label">Identifiant du sous-compteur: {{ meter.label }}</td>
                            </tr>
                            <ng-container *ngFor="let y of availableYears">
                                <ng-container *ngIf="y<=report.year&&metersDataMap.get(meter.uid+y) as releve">
                                    <tr *ngIf="meter.uid_group===group.uid" class="submeter-row">
                                        <td class="year-col color-blue">{{ y }}</td>
                                        <td class="text-right w140">
                                            <span class="value">{{ releve.rows_cache.energy_usage }}</span>
                                            <span class="unit">{{ meter.energyUnit }}</span>
                                        </td>
                                        <td class="text-right w140">
                                            <span class="value">{{ releve._evol|number:'1.1-1' }}</span>
                                            <span class="unit">%</span>
                                        </td>
                                        <td class="text-right w140">
                                            <span class="value">{{ releve.rows_cache.energy_cost|number:'1.0' }}</span>
                                            <span *ngIf="releve.rows_cache.energy_cost" class="unit">
                                               {{ meter.cost_unit }}
                                            </span>
                                        </td>
                                        <td class="submeter-row-notes pl-16 cursor-pointer"
                                            (dblclick)="releveToAddNote=releve.uid">
                                            <span *ngIf="releveToAddNote===''&&report.notes_meters&&report.notes_meters[releve.uid]">
                                            {{ report.notes_meters[releve.uid] }}
                                            </span>
                                            <input *ngIf="releveToAddNote===releve.uid" type="text" class="remarks"
                                                   (keyup.escape)="releveToAddNote=''"
                                                   (keyup.enter)="saveMeterNote()"
                                                   [(ngModel)]="report.notes_meters[releve.uid]"
                                                   placeholder="Remarques"/>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviSubMetersComponent implements OnInit, OnChanges {
    @Input('year') year: number;
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('report') report: ReportSuivi;
    @Input('energyStats') energyStats: EnergyStats;

    releveToAddNote = '';
    bmensMeters: BmensMeter[] = [];
    bmensMetersMap: Map<string, BmensMeter> = new Map<string, BmensMeter>();//for convenience, getById like

    amountForRefYearByMeter = {};
    metersAvailable: string[] = [];
    metersDataMap: Map<string, BmensData> = new Map<string, BmensData>();// 12th relevé mapped to [meter.uid+year]

    groups: Map<string, string> = new Map<string, string>();
    groupsLabels: string[] = [];// previous line map values
    availableYears: number[];
    selectedYear: number = 0;

    /// TODO: remove next line and clean
    submetersMapToCategories: Map<string, EnergyStatsSubMeters[]> = new Map<string, EnergyStatsSubMeters[]>();

    visibleGroups = {};
    visibleMeters = {};

    constructor(public myapp: AppService, public site: SiteService) {
    }

    ngOnInit() {
        this.site.siteLoadingStatus.subscribe(eventName => {
            if (eventName === SiteService.MAX_SITE_LOADING_STATUS) {
                this.bootstrap();
            }
        });
    }

    bootstrap() {
        this.getMeters();
        this.getMetersData();

        /// TODO: Clean next bloc
        if (this.energyStats && this.energyStats.subMeters) {
            this.submetersMapToCategories = new Map<string, EnergyStatsSubMeters[]>();
            this.energyStats.subMeters.forEach(item => {
                if (!this.submetersMapToCategories.has(item.category))
                    this.submetersMapToCategories.set(item.category, []);
                const stored = this.submetersMapToCategories.get(item.category);
                stored.push(item);
                this.submetersMapToCategories.set(item.category, stored);
            });
        }
    }

    getMeters() {
        this.myapp.loader.open("Reception des compteurs créés");
        this.site.api.getBmensMeters(this.site.uid).subscribe(resp => {
            this.myapp.loader.close();
            this.bmensMeters = [];
            this.groupsLabels = [];
            this.groups = new Map<string, string>();
            if (resp.body && resp.body.length > 0) {
                //this.myapp.showMessage("Compteurs Reçus: ");
                resp.body.forEach(it => {
                    const meter = new BmensMeter(it);
                    if (meter.is_in_suivi) {
                        //console.log("getMeters()", meter);
                        this.groups.set(meter.uid_group, meter.group_label);
                        this.groupsLabels.push(meter.group_label);
                        this.bmensMeters.push(meter);
                        this.bmensMetersMap.set(meter.uid, meter);
                    }

                });
                // add sorting
                // this.groupsLabels = this.groupsLabels.sort((a, b) => a.disp_order - b.disp_order);
                this.bmensMeters = this.bmensMeters.sort((a, b) => a.disp_order - b.disp_order);
            } else this.myapp.showMessage("Aucun compteur");
        });

    }

    getMetersData() {
        this.site.api.getAllMetersData(this.site.uid).subscribe(resp => {
            //console.log("meterdata", resp);
            this.availableYears = [];
            resp.body.forEach(item => {
                const meterData = new BmensData(item);
                const meterParent = this.bmensMetersMap.get(meterData.uid_meter);
                if (meterParent) {
                    const keyForRefYearValue = meterParent.label + meterParent.group_label;
                    if (!this.availableYears.includes(Number(meterData.year)))
                        this.availableYears.push(Number(meterData.year));
                    if (meterData.month === 12) {
                        if (meterData.year <= this.report.year)
                            this.metersAvailable.push(meterData.uid_meter);
                        if (meterData.year === this.reportRef.year) {
                            this.amountForRefYearByMeter[keyForRefYearValue] = meterData.rows_cache.energy_usage;
                            console.log('---------------amountForRefYearByMeter', meterData.rows_cache.energy_usage)
                        }
                        this.visibleGroups[meterParent.uid_group] = 1;
                        this.visibleMeters[meterParent.label] = 1;
                        if (this.amountForRefYearByMeter[keyForRefYearValue]) {
                            const numerator = 100 * (meterData.rows_cache.energy_usage - this.amountForRefYearByMeter[keyForRefYearValue]);
                            const denominator: number = this.amountForRefYearByMeter[keyForRefYearValue];
                            meterData._evol = numerator / denominator;
                        } else {
                            meterData._evol = 2.1;
                        }

                        //   row['evol'] = amountOfrefYear ? 100 * (row['amount'] - amountOfrefYear) / amountOfrefYear : 0;
                        this.metersDataMap.set(meterData.uid_meter + meterData.year, meterData);
                    }
                }

            });
            this.availableYears.sort();
            this.calcEvolution();
        });

    }

    calcEvolution() {
        this.bmensMeters.forEach(meter => {
            this.availableYears.forEach(y => {
                if (y <= this.report.year && this.metersDataMap.has(meter.uid + y)) {
                    const releve = this.metersDataMap.get(meter.uid + y);
                    //console.log(this.reportRef.year, y, meter.label, releve.rows_cache.energy_usage, releve._evol, this.amountForRefYearByMeter);
                }
            });
        });
    }

    saveMeterNote() {
        const dataToDave = {};
        dataToDave['notes_meters'] = this.report.notes_meters;
        this.saveSuivi(this.report, dataToDave);
    }

    saveSuivi(suivi: ReportSuivi, dataToSave: any) {
        this.site.api.saveSuivi(suivi, dataToSave)
            .subscribe(resp => {
                if (resp.status == 1) {
                    this.myapp.showMessage("Rapport mit à jour !");
                    this.releveToAddNote = '';
                }
                console.log("saveSuivi ", suivi, dataToSave, resp);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
    }

}
