<div class="flex-container full-width" fxLayout="column" style="height: 100%" #plotHolder>
    <div fxLayout="row" class="toolbar-top" fxLayoutAlign="start center"
         *ngIf="site.clientSite&&(site.selectedSiteReports.length||site.selectedSiteReportsToValidate.length) ">

        <button mat-button (click)="sidenav1.toggle()" class="toggle-Mbtn new--item">
            <i class="material-icons color-white">list_alt</i>
        </button>
        <div style="margin:0px 20px 0 20px" class="report-date-bloc"
             (click)="menuLeftType='reportList';sidenav1.toggle(true)"
             *ngIf="site.selectedSiteReport" fxFlex="200px">
            <label>Rapport du:</label>
            <div>{{ site.selectedSiteReport.date_str_full }} | Sem: {{ site.selectedSiteReport.week }}</div>
        </div>
        <span class="vert-divider"></span>


        <div class="bloc-stepper" fxFlex="220px" fxLayout="row" fxFlexAlign="start center" *ngIf="currentPage">
            <button mat-flat-button class="btn-xs "
                    (click)="gotoPage('left')">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <input type="number" [value]="currentPage.position"
                   (change)="loadJson('',$event.target.valueAsNumber)"/>
            <button mat-flat-button class="btn-xs"
                    (click)="gotoPage('right')">
                <mat-icon>chevron_right</mat-icon>
            </button>
            <span fxFlex class="bloc-stepper-page-count" *ngIf="reportPages">{{ reportPages.length }} pages</span>
        </div>

        <span class="vert-divider"></span>

        <div *ngIf="currentPage" class="page-title" fxFlex>{{ currentPage.title }}</div>
        <div *ngIf="currentPage&&pagesGenDurationMap[currentPage.page_key]" class="page-duration" fxFlex="50px">
            {{ pagesGenDurationMap[currentPage.page_key].genDuration }}
        </div>
        <div fxFlex>
            <mat-button-toggle-group class="mt-4 year-selector" [multiple]="false">
                <mat-button-toggle class="btn-toggle-sm"
                                   (click)="annotServ.displayOnlyPendingCommentFor('year',0);annotServ.loadComments(null, true);"> Tout
                </mat-button-toggle>
                <mat-button-toggle *ngFor="let yearKV of annotServ.commentCountPerYearMap|keyvalue"
                                   class="btn-toggle-sm"
                                   (click)="annotServ.displayOnlyPendingCommentFor('year',yearKV.key)"> {{ yearKV.key }} ({{ yearKV.value.length }})
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <mat-icon *ngIf="pleaseWait">hourglass_empty</mat-icon>

        <div fxFlex="">
        </div>

        <div fxFlex="170px" class="mr-4" *ngIf="site.selectedSiteReport&&currentPage&&annotServ.currentPage.position>2">
            <mat-select [value]="site.selectedSiteReport.date_str"
                        placeholder="Comparer"
                        class="date-compare-report">
                <mat-option *ngFor="let rapDate of site.selectedSiteReports"
                            (click)="loadSamePageOfDate(rapDate.date_str,$event)"
                            [value]="rapDate.report_key">
                    {{ rapDate.date_str }}  ({{ rapDate.page_count }})
                </mat-option>
            </mat-select>
        </div>


        <mat-checkbox [(ngModel)]="showList" (change)="togglePlotsViewMode()">Vue liste</mat-checkbox>
        <span fxFlex="20px"></span>
        <button mat-button *ngIf="site.selectedSiteReport&&site.selectedSiteReport.isReady&&canShare"
                (click)="initShareEvent(); myapp.openBS_Mailer()">
            <mat-icon>share</mat-icon>
        </button>
        <!--
        <button mat-button *ngIf="compareTargetDate" (click)="republishReportOfdate(compareTargetDate)">
            <mat-icon>sync</mat-icon>
        </button>
        -->
        <button mat-button (click)="sideNavMode='comment';sidenav2.toggle(true)">
            <mat-icon>comment</mat-icon>
        </button>
        <button mat-button (click)="preparePrint()" *ngIf="pagesToPrint.size>0">
            <mat-icon [matBadge]="pagesToPrint.size+''" matBadgeColor="primary">preview</mat-icon>
        </button>
        <button mat-button (click)="addPageToPrint();">
            <mat-icon>print</mat-icon>
        </button>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="">
        <mat-sidenav-container class="sidenav-full" fxFlex>
            <mat-sidenav #sidenav1 style="width: 330px;" mode="side" position="start" fxLayout="column">
                <div fxLayout="column" class="bloc-chapitres" fxFlex>
                    <div class="main-bloc" fxFlex>
                        <h3 mat-subheader *ngIf="annotServ.listPagesPerYear===0&&annotServ.listPagesPerLevel===0"
                            (dblclick)="resetFullScreen()">
                            <span class="cursor-pointer" (click)="menuLeftType='chapters'">Chapitres</span>
                            <span fxFlex></span>
                            <span class="cursor-pointer" (click)="menuLeftType='events'">Journal</span>
                        </h3>
                        <h3 mat-subheader *ngIf="annotServ.listPagesPerYear>0"
                            (dblclick)="resetFullScreen()">
                            Commentaires non traités {{ annotServ.listPagesPerYear }}
                        </h3>
                        <div *ngIf="site.selectedSiteReportsToValidate.length&&myapp.isAdminOrSuperUser">
                            <h4 mat-subheader>A valider</h4>
                            <mat-list>
                                <div *ngFor="let r of site.selectedSiteReportsToValidate">
                                    <mat-list-item [routerLink]="['/proto-hebdo/'+site.clientSite.uid+'/'+r.year+'/'+r.week]"
                                                   [ngClass]="{'active':site.selectedSiteReport&&site.selectedSiteReport.uid===r.uid}"
                                                   class="chapters-level-0 report-to-validate {{'report-status-'+r.pub_status}}">
                                        <mat-icon mat-list-icon *ngIf="r.pub_status==='PENDING'">fiber_new</mat-icon>
                                        <mat-icon mat-list-icon *ngIf="r.pub_status==='PUB_OK'">spellcheck</mat-icon>
                                        <h4 mat-line>Sem: {{ r.week }} | {{ r.date_str_full }}</h4>
                                    </mat-list-item>
                                </div>
                            </mat-list>
                        </div>

                        <ng-container *ngIf="menuLeftType==='reportList'">
                            <mat-list>
                                <div *ngFor="let r of site.selectedSiteReports">
                                    <mat-list-item [routerLink]="['/proto-hebdo/'+site.clientSite.uid+'/'+r.year+'/'+r.week]"
                                                   [ngClass]="{'active':site.selectedSiteReport&&site.selectedSiteReport.uid===r.uid}"
                                                   class="chapters-level-0">
                                        <mat-icon mat-list-icon>folder</mat-icon>
                                        <h4 mat-line>Sem: {{ r.week }} | {{ r.date_str_full }}</h4>
                                    </mat-list-item>
                                </div>
                            </mat-list>
                        </ng-container>
                        <ng-container *ngIf="menuLeftType==='chapters'">
                            <mat-list *ngIf="annotServ.listPagesPerYear===0&&annotServ.listPagesPerLevel===0">
                                <div *ngFor="let level of getKeys(levelsPages)">
                                    <mat-list-item class="chapters-level-0"
                                                   [ngClass]="{'selected-chapter':currentPageIndex===1}"
                                                   (click)="loadJson('',1)">
                                        <mat-icon mat-list-icon>home</mat-icon>
                                        <h4 mat-line [id]="getMd5(0)">Couverture </h4>

                                    </mat-list-item>
                                    <mat-list-item [id]="'chapter-'+levelsPages[level].p"
                                                   class="chapters-level-0"
                                                   (click)="loadJson('',levelsPages[level].p)">
                                        <mat-icon mat-list-icon>folder</mat-icon>
                                        <h4 mat-line [id]="getMd5(level)">{{ level }} - </h4>
                                    </mat-list-item>
                                    <div *ngFor="let sublevel of getKeys(levelsPages[level].items)" style="padding-left:1px; ">
                                        <mat-list-item [id]="'chapter-'+levelsPages[level].items[sublevel].p" class="chapters-level-1"
                                                       (click)="loadJson('',levelsPages[level].items[sublevel].p);">
                                            <mat-icon mat-list-icon>
                                                {{ levelsPages[level].items[sublevel] && getKeys(levelsPages[level].items[sublevel].items).length > 0 ? 'add' : 'fiber_manual_record' }}
                                            </mat-icon>
                                            <h4 mat-line fxLayout="row" [id]="getMd5(sublevel)">
                                                {{ sublevel|excerpt:25 }}
                                                <div fxFlex=""></div>
                                                <span class="color-blue">p{{ levelsPages[level].items[sublevel].p }}</span>
                                            </h4>
                                        </mat-list-item>

                                        <div *ngIf="levelsPages[level].items[sublevel]"
                                             [ngClass]="{'closed':currentPage&&(currentPage.position<levelsPages[level].items[sublevel].p)}"
                                             style="padding-left:10px; " class="slider closed">
                                            <div *ngFor="let subsublevel of getKeys(levelsPages[level].items[sublevel].items)">
                                                <mat-list-item
                                                        [id]="'chapter-'+levelsPages[level].items[sublevel].items[subsublevel].p"
                                                        (click)="loadJson('',levelsPages[level].items[sublevel].items[subsublevel].p)">
                                                    <mat-icon mat-list-icon>fiber_manual_record</mat-icon>
                                                    <h4 mat-line [id]="getMd5(subsublevel)" fxLayout="row">
                                                        {{ subsublevel|excerpt:25 }}
                                                        <div fxFlex=""></div>
                                                        <span class="color-blue">p{{ levelsPages[level].items[sublevel].items[subsublevel].p }}</span>
                                                    </h4>
                                                </mat-list-item>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </mat-list>

                            <h4 mat-subheader *ngIf="annotServ.listPagesPerYear>0">
                                Pages avec commentaires à traiter
                            </h4>
                            <mat-list *ngIf="annotServ.commentCountPerYearMap&&annotServ.listPagesPerYear>0">
                                <div *ngFor="let p of reportPages">
                                    <ng-container *ngIf="annotServ.commentCountPerYearMap.get(annotServ.listPagesPerYear.toString()) as pendingPages">
                                        <div *ngIf="pendingPages.includes(p.page_key)">
                                            <mat-list-item class="chapters-level-0"
                                                           [ngClass]="{active:currentPage&&currentPage.position===p.position}"
                                                           (click)="loadJson('tto',p.position)">
                                                <mat-icon mat-list-icon style="font-size: 20px">article</mat-icon>
                                                <h4 mat-line fxLayout="row">
                                                    <span fxFlex *ngIf="p.title">{{ p.title|excerpt:25 }}</span>
                                                    <span fxFlex *ngIf="!p.title">Titre vide</span>
                                                    <span class="color-blue font-weight-bold" matBadgeColor="warn" style="width: 35px"
                                                          [matBadge]="p.comments_pending">p{{ p.position }}</span>
                                                </h4>
                                            </mat-list-item>
                                        </div>
                                    </ng-container>


                                </div>
                            </mat-list>
                        </ng-container>

                        <!-- journal -->
                        <ng-container *ngIf="menuLeftType==='events'">
                            <mat-list *ngIf="annotServ.listPagesPerYear===0&&annotServ.listPagesPerLevel===0">
                                <mat-list-item class="chapters-level-0" (click)="selectEvent(event)"
                                               *ngFor="let event of events">
                                    <mat-icon mat-list-icon>folder</mat-icon>
                                    <h4 mat-line>
                                        <span>{{ event['date_str'] }}</span> <strong class="pl-4">{{ event.tag }}</strong>
                                        <span fxFlex></span>
                                        <b class="color-blue" *ngIf="event['user_metas']">{{ event['user_metas']['lastName'] }}</b>
                                    </h4>
                                </mat-list-item>
                            </mat-list>
                        </ng-container>

                        <mat-divider></mat-divider>
                    </div>

                    <div fxLayout="column" fxFlex="110px">
                        <div fxLayout="column" *ngIf="myapp.isAdminOrSuperUser">

                        </div>
                    </div>
                </div>

            </mat-sidenav>

            <mat-sidenav-content>
                <mat-sidenav-container class="sidenav-full">
                    <mat-sidenav #sidenav2 style="width: 330px;" opened mode="side" position="end" role="navigation">
                        <div fxLayout="row" class="sidenav-head" fxLayoutAlign="space-between center">
                            <div *ngIf="sideNavMode">
                                {{ sideBarTitle }}
                            </div>
                            <div fxFlex=""></div>
                            <button class="btn-xs" mat-stroked-button (click)="annotServ.cancelEditng()" *ngIf="annotServ.annotKeyToEdit.length">
                                Annuler
                            </button>
                            <button class="btn-xs" mat-stroked-button (click)="sidenav2.toggle()">
                                Fermer
                            </button>
                        </div>

                        <ng-container *ngIf="annotServ.plotClicClosestPoints&&annotServ.plotClicClosestPoints.length">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="serie-bloc mat-card"
                                 [@animate]="{ value: '*', params: { y: '100px', delay: '200ms' } }"
                                 *ngFor="let pt of annotServ.plotClicClosestPoints,let i=index">
                                <div class="serie-selector" [style.background]="pt.color"></div>
                                <div class="d-flex flex-column justify-content-center flex-grow-1">
                                    <h6 class="mb-4">{{ pt.name }}</h6>
                                    <small class="text-muted">X: <b>{{ pt.x }}</b> ({{ pt.xTitle|excerpt:"20" }})</small><br>
                                    <small class="text-muted">Y: <b>{{ pt.y }}</b> ({{ pt.yTitle|excerpt:"20" }})</small>
                                    <small *ngIf="pt.text" class="text-muted">Label: <b>{{ pt.text }}</b> </small>
                                </div>
                                <button mat-icon-button *ngIf="myapp.getIfUserCanDoForRoute('PROTOHEBDO','COMMENT')||myapp.isAdminOrSuperUser">
                                    <mat-icon class="text-muted" (click)="annotServ.selectPointToAnnotate(pt)">gps_fixed</mat-icon>
                                </button>
                            </div>
                        </ng-container>

                        <mat-divider></mat-divider>

                        <ng-container *ngIf="sideNavMode==='openAnnotMap'&&annotServ.commentToEdit">
                            <div fxLayout="column" fxLayoutAlign="space-between stretch" class="comment-bloc p-8" [style]="{height:clientHeight+200}">
                                <h2 class="color-blue">Prévisualisation du commentaire</h2>
                                <h3 [innerHTML]="annotServ.commentToEdit.title"></h3>
                                <span [innerHTML]="annotServ.commentToEdit.finalComment"></span>

                                <mat-divider></mat-divider>
                                <h2 class="color-blue mt-16">Edition {{ annotServ.commentToEdit.priority }}</h2>
                                <mat-card class="p-4 m-0">
                                    <mat-button-toggle-group fxLayout="row"
                                                             class="full-width mb-4"
                                                             [(ngModel)]="annotServ.commentToEdit.priority"
                                                             (change)="annotServ.setAllAnnotsPriority($event)">
                                        <mat-button-toggle value="HIGH" fxFlex="33"> Haut</mat-button-toggle>
                                        <mat-button-toggle value="NORMAL" fxFlex="33"> Normal</mat-button-toggle>
                                        <mat-button-toggle value="INFO" fxFlex="33"> Info</mat-button-toggle>

                                    </mat-button-toggle-group>
                                    <mat-form-field class="full-width ">
                                        <mat-label>Titre</mat-label>
                                        <textarea matInput #autosize
                                                  cdkTextareaAutosize="true" [cdkAutosizeMinRows]="0"
                                                  [(ngModel)]="annotServ.commentTitle"
                                                  placeholder="Texte  ici..."></textarea>
                                        <mat-icon matSuffix (click)="annotServ.commentToEdit.title=null">close</mat-icon>
                                    </mat-form-field>
                                    <br>
                                    <mat-form-field class="full-width ">
                                        <mat-label>Commentaire</mat-label>
                                        <textarea matInput #autosize cdkTextareaAutosize="true" [cdkAutosizeMinRows]="0"
                                                  [(ngModel)]="annotServ.commentText"
                                                  placeholder="Texte  ici..."></textarea>
                                        <mat-icon matSuffix (click)="annotServ.commentToEdit.comment=null">close</mat-icon>
                                    </mat-form-field>
                                </mat-card>

                            </div>

                            <div fxLayout="column" fxLayoutAlign="space-between stretch" [style]="{height:clientHeight+200}">
                                <h2 class="color-blue pl-8 pt-8">Annotations attachées</h2>
                                <annot-editor *ngFor="let annKey of annotServ.annotKeyToEdit"
                                              [annotationUID]="annKey" [type]="annotServ.isCommentCreatingMode?'creation':'selectedComment'"
                                              (cb)="annotServ.updateAnnotationsFromEditor($event)"
                                              [forceUpdate]="annotServ.editorForceUpdate"></annot-editor>
                                <div fxLayout="row" class="p-8" *ngIf="annotServ.annotKeyToEdit.length" fxLayoutAlign="space-between center">
                                    <button class="" (click)="annotServ.clearNewAnnotations()" mat-raised-button color="accent">Annuler</button>
                                    <div fxFlex=""></div>
                                    <button-loading class="" color="primary" [loading]="annotServ.annotationSavingInProgress" (click)="annotServ.saveComment()">
                                        Sauvegarder
                                    </button-loading>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="sideNavMode==='comment'">
                            <mat-button-toggle-group fxLayout="row" class="m-4">
                                <mat-button-toggle (click)="annotServ.commentType='completed'" fxFlex="50" [checked]="annotServ.commentType==='completed'">
                                    Traités
                                </mat-button-toggle>
                                <mat-button-toggle (click)="annotServ.commentType='pending'" fxFlex="50" [checked]="annotServ.commentType==='pending'">
                                    A traiter
                                </mat-button-toggle>
                            </mat-button-toggle-group>

                            <mat-button-toggle-group fxLayout="row" class="m-4">
                                <mat-button-toggle *ngFor="let sortingType of['page','date','user']"
                                                   (click)="annotServ.sort(sortingType)"
                                                   [checked]="annotServ.sortingType===sortingType"
                                                   fxLayoutAlign="space-between center"
                                                   fxFlex> {{ myapp.k.SortingLabel[sortingType] }}
                                    <mat-icon *ngIf="annotServ.sortingType===sortingType&&annotServ.sortingDir===1">arrow_drop_up</mat-icon>
                                    <mat-icon *ngIf="annotServ.sortingType===sortingType&&annotServ.sortingDir===-1">arrow_drop_down</mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>

                            <mat-button-toggle-group fxLayout="row" class="full-width mb-4">
                                <mat-button-toggle value="HIGH" fxFlex="33" [class.active]="annotServ.filterPriority===''"
                                                   (click)="annotServ.filterByPriority('')"> Tout
                                </mat-button-toggle>
                                <mat-button-toggle value="HIGH" fxFlex="33" [class.active]="annotServ.filterPriority==='HIGH'"
                                                   (click)="annotServ.filterByPriority('HIGH')"> Haut
                                </mat-button-toggle>
                                <mat-button-toggle value="NORMAL" fxFlex="33" [class.active]="annotServ.filterPriority==='NORMAL'"
                                                   (click)="annotServ.filterByPriority('NORMAL')"> Normal
                                </mat-button-toggle>
                                <mat-button-toggle value="INFO" fxFlex="33" [class.active]="annotServ.filterPriority==='INFO'"
                                                   (click)="annotServ.filterByPriority('INFO')"> Info
                                </mat-button-toggle>

                            </mat-button-toggle-group>

                            <mat-card *ngFor="let comment of annotServ.getCommentsForPage()"
                                      (click)="annotServ.commentClickFromSideNav(comment);"
                                      [ngClass]="{selected:annotServ.selectedComment&&annotServ.selectedComment.uid===comment.uid}"
                                      [id]="'comment-'+comment.uid" class="p-8 m-8 comment-{{comment.priority}} comment-card">

                                <div class="comment-header" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div fxFlex class="color-blue name" fxLayout="column">
                                        <div>
                                            {{ comment.display_name }}
                                            <i *ngIf="comment.page_position">(p {{ comment.page_position }})</i>
                                            <mat-icon *ngIf="!comment.page_position" inline>error_outline</mat-icon>
                                            <span class="badge-notif"
                                                  *ngIf="annotsWithNotifs.includes(comment.uid)">
                                            </span>
                                        </div>
                                    </div>

                                    <span class="comment-date" title="{{ comment.ts_created * 1000|amTimeAgo }}">
                                        {{ comment.ts_created * 1000  | date }}
                                    </span>
                                    <mat-checkbox *ngIf="myapp.isAdminOrSuperUser"
                                                  [checked]="comment.status==='COMPLETED'"
                                                  (change)="annotServ.setCommentStatus(comment.uid,$event.checked)" name="agreed" class="ml-8">
                                    </mat-checkbox>
                                </div>

                                <!--Selected comment display mode-->
                                <ng-container *ngIf="!annotServ.selectedComment||comment.uid!==annotServ.selectedComment.uid">
                                    <span class="comment-title" [innerHTML]="comment.title|excerpt:30"></span>:
                                    <span class="comment-body" [innerHTML]="comment.finalComment">
                                </span>
                                </ng-container>
                                <!--Selected comment display mode-->
                                <ng-container *ngIf="annotServ.selectedComment&&comment.uid===annotServ.selectedComment.uid">
                                    <span class="comment-title" [innerHTML]="comment.title"></span>:
                                    <span class="comment-body" [innerHTML]="comment.fullCommentText">
                                </span>
                                </ng-container>

                                <div class="text-muted comment-actions-div " fxLayout="row" fxLayoutAlign="space-between center"
                                     [@animate]="{ value: '*', params: { y: '100px', delay: '200ms' } }"
                                     *ngIf="comment.repliesItems.length||(annotServ.selectedComment&&annotServ.selectedComment.uid===comment.uid)">

                                    <div fxFlex>
                                   <span class="cursor-pointer" (click)="pan.toggle()" *ngIf="comment.repliesItems.length">
                                       {{ comment.repliesItems.length }} réponse(s)
                                   </span>
                                    </div>

                                    <span fxflex></span>
                                    <span fxFlex="50px" class="text-green cursor-pointer"
                                          *ngIf="annotServ.selectedComment&&annotServ.selectedComment.uid===comment.uid"
                                          (click)="sideNavMode='openAnnotMap'">Editer</span>
                                    <span fxFlex="60px" class="text-green cursor-pointer" (click)="commentReplyMode=true;pan.open();">
                                        Répondre
                                    </span>
                                </div>
                                <mat-expansion-panel #pan [expanded]="false">
                                    <div *ngIf="comment.repliesItems.length" class="comment-reply-bloc">
                                        <div *ngFor="let rep of comment.repliesItems" class="reply-bloc">
                                            <b>{{ rep['name'] }}</b> |
                                            <small class="text-muted">{{ rep['timestamp'] | date }}</small>
                                            <div>{{ rep['message'] }}</div>
                                        </div>
                                    </div>
                                    <div class="comment-reply-form">
                                        <div *ngIf="commentReplyMode&&canCommentReply" fxLayout="column">
                                            <mat-form-field>
                                                <mat-label>Votre réponse</mat-label>
                                                <textarea matInput #autosize cdkTextareaAutosize="true" [cdkAutosizeMinRows]="0"
                                                          [(ngModel)]="annotServ.replyText" placeholder="Réponse  ici..."></textarea>
                                            </mat-form-field>

                                            <mat-divider></mat-divider>
                                            <div fxLayout="row">
                                                <button class="btn-xs" mat-stroked-button (click)="commentReplyMode=false;pan.close()">Annuler</button>
                                                <div fxFlex></div>
                                                <button-loading
                                                        [btnClass]="'btn-xs mat-stroked-button'"
                                                        [loading]="annotServ.commentSavingInProgress"
                                                        [raised]="false"
                                                        color="primary"
                                                        (click)="annotServ.sendReply(comment.uid)">Sauvegarder
                                                </button-loading>
                                            </div>
                                        </div>
                                        <div fxLayout="row" *ngIf="!commentReplyMode">
                                            <button class="btn-xs" mat-stroked-button (click)="pan.close()">Fermer</button>
                                            <div fxFlex></div>
                                            <button class="btn-xs" mat-stroked-button
                                                    *ngIf="canCommentReply"
                                                    (click)="commentReplyMode=true">Répondre
                                            </button>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-card>
                        </ng-container>

                        <mat-card *ngIf="sideNavMode==='print'" style="padding: 5px;margin: 5px;">
                            <ng-container *ngFor="let page of getPagesToPrint()">
                                <div fxLayout="row" style=" padding: 10px 3px;border-bottom: 1px solid #f5f5f5">
                                    <div class="w-40 h-40 mr-16  ">
                                        <button mat-mini-fab color="default"
                                                (click)="loadJson('',page.position);"
                                                class="mat-elevation-z2">{{ page.position + 1 }}
                                        </button>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center flex-grow-1">
                                        <small class="text-muted">{{ page.title }}</small>
                                    </div>
                                    <button mat-icon-button (click)="removePageFromPrint(page.position)">
                                        <mat-icon class=" text-muted  " data-mat-icon-type="font">clear</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                            <mat-card-actions>
                                <button mat-raised-button (click)="downloadAsPDF()">
                                    <mat-icon>print</mat-icon>
                                </button>
                                <a mat-raised-button *ngIf="pdfSource" [@animate]="{value:'*',params:{delay:'800ms',y:'50px'}}"
                                   color="warn" class="download-button mr-4 btn-xs-25" [href]="pdfSource" target="_blank">
                                    <mat-icon inline>download</mat-icon>
                                </a>
                            </mat-card-actions>
                        </mat-card>

                    </mat-sidenav>

                    <!--body  -->
                    <mat-sidenav-content>
                        <mat-card id="plot-holder" class="plot-holder p-0" fxLayout="column"
                                  *ngIf="sideNavMode!=='print'" [fxLayoutAlign]="sideNavMode==='print'?'start center':''">

                            <mat-card
                                    *ngIf="site.clientSite&&!site.selectedSiteReport&&!site.selectedSiteReports.length&&!site.selectedSiteReportsToValidate.length">
                                <h3>Ce site n'a pas de rapports publiés encore {{ site.selectedSiteReports.length }}</h3>
                                <div *ngFor="let jarPath of site.selectedSiteReportsJars" class="p-4">
                                    <label>{{ jarPath }}</label>
                                    <button (click)="regenFromJarAlias(jarPath)">Gén.</button>
                                </div>
                            </mat-card>

                            <div *ngIf="site.selectedSiteReport&&false">
                                {{ reportPages.length }}
                                {{ graphsAdapted.length }}
                                {{ site.selectedSiteReport.pages.length }}
                            </div>

                            <!--ERR  -->
                            <mat-card *ngIf="site.selectedSiteReport &&(reportPages.length===0||graphsAdapted.length===0)">
                                <mat-card-title>Pages du rapport non chargées</mat-card-title>

                                <button mat-raised-button class="btn-xs-25 m-4" (click)="selectReport()">
                                    Recharger
                                </button>

                                <div class="jar-infos m-4" *ngIf="site.selectedSiteReport.jarData&&myapp.isAdminOrSuperUser">
                                    <div class="mt-8 mb-4">
                                        <h4>Fichier d'archive disponible</h4>
                                    </div>
                                    <div fxLayout="row" *ngFor="let jar of site.selectedSiteReport.jarData">
                                        <div fxFlex>
                                            <div>
                                                <label>Date de génération</label>
                                                <span>{{ jar.date_str_full }}</span>
                                            </div>
                                            <div>
                                                <label>Taille</label>
                                                <span>{{ jar.sizeStr }}</span>
                                            </div>
                                        </div>

                                        <button (click)="regenFromJar(jar.regenUrl)" mat-raised-button
                                                class="btn-xs-25">Régénérer
                                        </button>
                                    </div>
                                </div>
                            </mat-card>

                            <!-- PLOTS  and comments-->
                            <ng-container *ngIf="showList">
                                <mat-card class="p-4 h-4" fxLayout="row" fxLayoutAlign="space-between center">
                                    <button mat-raised-button class="btn-xs-25" (click)="downloadAsPDF(true)">
                                        <mat-icon inline>print</mat-icon>
                                        Imprimer
                                    </button>
                                    <div fxFlex></div>
                                    <button mat-raised-button class="btn-xs-25 ml-4"
                                            (click)="annotServ.selectedYear=y"
                                            [class.active]="annotServ.selectedYear===y"
                                            *ngFor="let y of annotServ.report_comments_years">{{ y }}
                                    </button>
                                </mat-card>
                                <div *ngIf="!currentPage" #pdfBloc class="plot-comm-holder">
                                    <plot-comm *ngFor="let c of annotServ.getCommentsForPage(); index as i"
                                               [year]="annotServ.selectedYear"
                                               [index]="i"
                                               [reportDate]="site.selectedSiteReport.date_str"
                                               [comment]="c"></plot-comm>
                                </div>
                            </ng-container>

                            <!-- PLOTS  -->
                            <div *ngIf="currentPage" fxLayout="row wrap" #plotBloc id="pdf-table" fxFlex="1600">
                                <div *ngIf="currentPage.position===1" fxLayout="column" class="bloc-plot">
                                    <report-cover *ngIf="site.selectedSiteReport"
                                                  [report]="site.selectedSiteReport"></report-cover>
                                </div>

                                <div *ngIf="isTable" fxLayout="column" class="bloc-plot">
                                    <table-page [data]="graphsAdapted"></table-page>
                                </div>
                                <ng-container *ngIf="!isTable">
                                    <div [fxFlex]="cellsWidth[g]" *ngFor="let g of [0,1,2,3,4,5,6,7]"
                                         fxLayout="column" class="bloc-plot" [id]="'bloc-plot-'+g">
                                        <div *ngIf="graphsAdapted&&graphsAdapted[g]&&graphsAdapted[g].data" fxLayout="column">
                                            <div class="plot-title" [title]="graphsAdapted[g].key"
                                                 (click)="test(g)"
                                                 (dblclick)="fullScreenPlot(g)">
                                                {{ graphsTitlesMap.get(graphsAdapted[g].key) }}
                                            </div>

                                            <plotly-plot #plot fxFlex class="plotly-plot holder"
                                                         (contextmenu)="overrideContextMenu($event, g)"
                                                         [divId]="graphsAdapted[g].key"
                                                         (plotlyClick)="annotServ.plotClic($event,g,plot)"
                                                         (clickAnnotation)="annotServ.plotAnnotationClic($event,g,graphsAdapted[g].key,plot)"
                                                         (relayout)="annotServ.plotUpdate($event,g,graphsAdapted[g].key,'relayout',plot)"
                                                         (animated)="annotServ.plotUpdate($event,g,graphsAdapted[g].key,'animated',plot)"
                                                         (afterPlot)="doneLoading($event)"
                                                         [class]="'plot-'+g"
                                                         [hidden]="!graphsAdapted[g].data"
                                                         [data]="graphsAdapted[g].plotData.data" [layout]="graphsAdapted[g].plotData.layout"
                                                         [updateOnLayoutChange]="true"
                                                         [config]="defaultConfig"
                                                         [useResizeHandler]="false">
                                            </plotly-plot>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </mat-card>
                        <!-- -->
                        <div *ngIf="sideNavMode==='print'" #pdfBloc fxLayout="column"
                             class="print-preview-holder" fxLayoutAlign="space-between center" [style]="{height:clientHeight+200}">


                            <div>
                                <app-print-proto-page *ngFor="let page of getPagesToPrint();let i=index"
                                                      [page]="page"
                                                      [index]="i"
                                                      [visualKeysToAnnotationsMap]="annotServ.visualKeysToAnnotationsMap"
                                                      [selectedReport]="site.selectedReport"
                                                      [pageList]="pages"
                                                      [pagesGraphs]="pagesGraphsMap"></app-print-proto-page>
                            </div>


                        </div>

                    </mat-sidenav-content>
                </mat-sidenav-container>
            </mat-sidenav-content>

        </mat-sidenav-container>
    </div>

</div>
