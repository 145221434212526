import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {H} from "../../../../shared/helpers/H";
import {PdmRegDef} from "../../../../shared/models/PdmRegDef";
import {PdmDataRow} from "../../../../shared/models/PdmDataRow";
import {Pdm} from "../../../../shared/models/Pdm";
import {AppService} from "../../../../shared/services/app.service";
import {MatMenuTrigger} from "@angular/material/menu";
import {SiteService} from "../../../../shared/services/site.service";

@Component({
    selector: 'releve-row',
    template: `
        <div fxLayout="column" [ngClass]="{'highlight-row':replaceCompteurMode}">
            <div *ngIf="replaceCompteurMode"
                 fxLayout="row" fxLayoutAlign="space-between none" class="form-replace-compteur">
                <div class="head"> Replacement de compteur</div>
                <input class="input" style="width:140px" [(ngModel)]="compteurReplacementDate" placeholder="Date remplacemnt">
                <span class="label">V.End</span>
                <input class="input" [(ngModel)]="compteurOldFinalValue" placeholder="index de fin">
                <span class="label">V.Start</span>
                <input class="input" [(ngModel)]="compteurNewStartupValue" placeholder="index début">
                <span class="label">V.Now</span>
                <input class="input" [(ngModel)]="compteurCurrentValue" placeholder="index courant">
                <button class="btn-xs-25 m-4" fxFlex="80px" color="primary" mat-raised-button (click)="amendCompteurReplacement()">
                    Save
                </button>
                <button class="btn-xs-25 m-4" fxFlex="80px" color="primary" mat-raised-button (click)="cancelCompteurReplacement()">
                    Reset
                </button>
                <button class="btn-xs-25 m-4" fxFlex="80px" color="accent" mat-raised-button (click)="replaceCompteurMode=false;">
                    Fermer
                </button>
                <div fxFlex></div>
            </div>
            <div class="data-row-holder" fxLayoutAlign="space-between center" fxLayout="row"
                 [ngClass]="{'year-end':rowData.releve_num===12,'highlight':replaceCompteurMode}">
                <div class="date item_120 pl-4" fxLayout="row" fxLayoutAlign="space-between center">
                    <div>{{ rowData.releve_year }}
                    </div>
                    <div>
                        <span *ngIf="rowData.releve_num<10">0{{ rowData.releve_num }}</span>
                        <span *ngIf="rowData.releve_num>=10">{{ rowData.releve_num }}</span>
                    </div>

                    <div *ngIf="rowData.days">({{ rowData.days }})</div>
                    <div *ngIf="rowData.days_cumul">({{ rowData.days_cumul }})</div>
                    {{ dateDayName|uppercase }} {{ rowData.date }}
                </div>
                <div fxLayout="row">
                    <ng-container *ngFor="let reg of regs">
                        <ng-container *ngFor="let f of ['i','k','d','a','t','c']">
                            <ng-container *ngIf="reg.shouldShow(f)">
                                <div #cell (click)="clic(reg,f,$event)"
                                     (dblclick)="emitEditStart(reg,f)"
                                     [ngClass]="['field'+f.toUpperCase() ,'field','field-width' ,'reg-num-'+reg.num ]"
                                     [attr.itemtype]="metas[ f + reg.num]"
                                     [attr.field]="f"
                                     [attr.reg]="reg.num"
                                     [attr.date]="rowData.date">
                                    <span *ngIf="editMode!==(f+reg.num)&&rowData.data[f + reg.num]!==undefined">
                                        {{ rowData.data[f + reg.num]|number:'1.0-2' }}
                                    </span>
                                    <mat-form-field *ngIf="editMode===(f+reg.num)" class="full-width" appearance="fill">
                                        <input #input matInput type="number" step="0.001"
                                               (keydown.enter)="manualUpdate((f),$event.target.value )"
                                               (keydown.escape)="dismissAll()"
                                               (paste)="paste()"
                                               [attr.field]="f + reg.num"
                                               [value]="rowData.data[f+reg.num]">
                                        <span matPrefix>{{ f + reg.num }}</span>
                                    </mat-form-field>
                                </div>

                            </ng-container>

                        </ng-container>
                    </ng-container>
                </div>

                <!--cTotPaid/diff/VE
                <div #cell [ngClass]="['field'+'cTotPaid','field','field-width','reg-num-0']">
                    <span *ngIf="rowData.c_tot_paid">{{ rowData.c_tot_paid|number }}</span>
                </div>
                <div #cell
                     [attr.value]="cTotDiff"
                     [ngClass]="['field'+'cTotDiff','cTotDiff','field','field-width','reg-num-0']">
                    <span>{{ cTotDiff|number }}</span>
                </div>
                -->
                <div #cell [ngClass]="['field'+'cTotPaid','field','field-width','reg-num-0']">
                    <span *ngIf="rowData.ve">{{ rowData.ve|number:'1.0-2' }}</span>
                </div>


                <!-- totals
                <div #cell [ngClass]="['field'+'cTotCalc','field','field-width','field-extra-tot','reg-num-0']"
                     *ngFor="let extraf of autoFields"
                     [title]="extraf">
                    <span *ngIf="rowData.tots[extraf]">{{rowData.tots[extraf]|number}}</span>
                </div>-->
                <div #cell [ngClass]="['field'+'cTotCalc','field','field-width','field-extra-tot','reg-num-0']">
                    <span *ngIf="rowData.tots['dTot']">{{ rowData.tots['dTot']|number:'1.0-2' }}</span>
                </div>
                <div #cell [ngClass]="['field'+'cTotCalc','field','field-width','field-extra-tot','reg-num-0']">
                    <span *ngIf="rowData.tots['cTot']">{{ rowData.tots['cTot']|number:'1.0-2' }}</span>
                </div>
                <!-- meteo-->
                <ng-container *ngFor="let meteo of rowData.vars_meteo|keyvalue">
                    <div #cell [title]="meteo.key"
                         *ngIf="meteo.key[0]==='m'"
                         [ngClass]="['vars-meteo','field','field-width','reg-num-0']">
                        <span *ngIf="meteo.value">{{ meteo.value|number:'1.0-2' }}</span>
                    </div>
                </ng-container>
                <!-- varexpl globaux-->
                <ng-container *ngFor="let extraVar of rowData.vars_horaire|keyvalue">
                    <div #cell [title]="extraVar.key"
                         [ngClass]="['vars-horaire','field','field-width','reg-num-0']">
                        <span *ngIf="extraVar.value!==undenined">{{ extraVar.value|number }}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="site.selectedPdmYearlyPlotDataMap.get(rowData.data_key) as calcedPlotData">
                    <div #cell [title]="'djSI'" [ngClass]="['vars-horaire','field','field-width','reg-num-0']">
                    <span (click)="myapp.showConsole('calcedPlotData',calcedPlotData)">
                        SI: {{ calcedPlotData.var_meteo.djSI|number }} 
                    </span>
                    </div>
                    <div #cell [title]="'djEP'" [ngClass]="['vars-horaire','field','field-width','reg-num-0']">
                    <span>
                        {{ calcedPlotData.var_meteo.djEP|number }}
                    </span>
                    </div>
                    <div #cell [title]="'Cassure'" [ngClass]="['vars-horaire','field','field-width','reg-num-0']">
                    <span>
                        {{ calcedPlotData.used_cassure|number }}
                    </span>
                    </div>
                    <div #cell [title]="'Corr'" [ngClass]="['vars-horaire','field','field-width','reg-num-0']">
                    <span>
                       {{ calcedPlotData.ve.corr|number }}
                    </span>
                    </div>
                    <div #cell [title]="'Cumul'" [ngClass]="['vars-horaire','field','field-width','reg-num-0']">
                    <span>
                       {{ calcedPlotData.ve.cumul|number }}
                    </span>
                    </div>
                </ng-container>
            </div>
        </div>
    `,

    styleUrls: ['./releve-row-component.scss']
})
export class ReleveRowComponent implements AfterViewInit, OnChanges {
    @ViewChildren('cell') cells: QueryList<any>;
    @ViewChildren('input') inputEl: QueryList<ElementRef>;

    @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
    @Input('index') index: number; // <--- to force reload of component
    @Input('ts') ts: number;
    @Input('ctrlPressField') ctrlPressField: string;
    @Input('firstRowDateMassEdit') firstRowDateMassEdit: string;
    @Input('lastRowDateMassEdit') lastRowDateMassEdit: string;
    @Input('regs') regs: PdmRegDef[];
    @Input('selectedReg') selectedReg: PdmRegDef;
    //@Input('reg') reg: PdmRegDef;
    @Input('rowData') rowData: PdmDataRow;

    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    replaceCompteurMode = false;
    compteurReplacementDate = '';
    compteurOldFinalValue = 0;
    compteurNewStartupValue = 0;
    compteurCurrentValue = 0;
    selectedRegForEdition: PdmRegDef = null;

    autoFields = [];
    editMode = '';
    classList = [];
    metas = {};
    dateDayName = "";
    cTotDiff = 0;

    constructor(private cdRef: ChangeDetectorRef, public myapp: AppService, public site: SiteService) {
    }

    cancelCompteurReplacement() {
        const obj = {rowDate: this.rowData.date};
        this.cb.emit({arg: 'replaceCompteurReset', reg: this.selectedRegForEdition, obj});
    }

    amendCompteurReplacement() {
        console.log("amendCompteurReplacement()", this.rowData);
        const dataItemsObj = {};

        this.compteurOldFinalValue = H.cleanNumberString(this.compteurOldFinalValue);
        this.compteurNewStartupValue = H.cleanNumberString(this.compteurNewStartupValue);
        this.compteurCurrentValue = H.cleanNumberString(this.compteurCurrentValue);
        // f= final index, s=start index of new cpt
        dataItemsObj['f'] = Number(this.compteurOldFinalValue);
        dataItemsObj['s'] = Number(this.compteurNewStartupValue);
        dataItemsObj['i'] = Number(this.compteurCurrentValue);

        const evObj = {evDate: this.compteurReplacementDate, evType: "EVENT_CPT_REPLACE"};
        const obj = {rowDate: this.rowData.date, evObj, dataItemsObj};
        this.cb.emit({arg: 'replaceCompteur', reg: this.selectedRegForEdition, obj});
    }

    emitEditStart(reg: PdmRegDef, f: string) {
        if (f === 'i') {
            this.selectedRegForEdition = reg;
            this.replaceCompteurMode = true;
            /*
            if (this.rowData.events && this.rowData.events['i' + reg.num]) {
                const ev = this.rowData.events['i' + reg.num];
                this.compteurReplacementDate = ev.evDate;
            } else {
                this.compteurReplacementDate = this.rowData.date;
            }*/

            this.compteurCurrentValue = this.rowData.data['i' + reg.num];
            this.compteurOldFinalValue = this.rowData.data['f' + reg.num];
            if (!this.compteurOldFinalValue) this.compteurOldFinalValue = this.rowData.data['p' + reg.num];
            this.compteurNewStartupValue = this.rowData.data['s' + reg.num];

            /*
              this.compteurOldFinalValue=Number(this.rowData)
        dataItemsObj['f'] = Number(this.compteurOldFinalValue);
        dataItemsObj['s'] = Number(this.compteurNewStartupValue);
        dataItemsObj['i'] = Number(this.compteurCurrentValue);
             */
            return;
        }
        if (reg.calced_field !== f) {
            this.editMode = (f + reg.num);
            //console.log("this.inputEl 11", this.inputEl.first);
            this.cdRef.detectChanges();
            // console.log("this.inputEl 22", this.inputEl.first.nativeElement);
            this.inputEl.first.nativeElement.focus();
            this.cb.emit({arg: 'setEditStartRowDate', value: this.rowData.date, reg});
        } else {
            this.myapp.toastr.error("On ne peut éditer les valeurs calculées", "Attention !", {timeOut: 5000});
        }
    }

    manualUpdate(field, value) {
        const obj = {field, value};
        this.cb.emit({arg: 'massUpdate', obj});// save actual state for undo
        this.editMode = '';
    }

    clic(reg, f, $event) {
        const cellEl = $event.target as HTMLDivElement;
        const liveCalcedTots = Pdm.getTotFields(this.regs, this.rowData);
        // console.log("clic: ",
        //     this.rowData.date, this.rowData.date_prev, this.rowData.days,
        //     this.rowData.dist, this.rowData, 'liveCalcedTots: ', liveCalcedTots);
        console.log("clic: ", this.rowData, cellEl.classList);
        if (cellEl.parentElement.classList.contains('ctrl')) {
            const date = cellEl.parentElement.getAttribute('date');
            this.cb.emit({arg: 'setEditEndRowDate', value: date});
            console.log("clic:setEditEndRowDate ", this.rowData);
        }
    }

    adaptCellsStyling() {
        if (this.cells) {

            const firstDate = new Date(this.firstRowDateMassEdit);
            const lastDate = new Date(this.lastRowDateMassEdit);
            const currDate = new Date(this.rowData.date);
            this.cells.forEach(cell => {
                const cellEl = cell.nativeElement as HTMLDivElement;
                const cellVal = cellEl.getElementsByTagName('span');
                const dataInputType = cellEl.getAttribute('itemtype');
                const dataField = cellEl.getAttribute('field');
                const regNum = cellEl.getAttribute('reg');
                const cellValue = Number(cellEl.getAttribute('value'));
                const varName = dataField + regNum;

                //reset classes
                cellEl.classList.remove('massEditTarget');
                cellEl.classList.remove('selected-reg-cell');

                /*
                const fieldLastMeta = this.rowData.getLatestFieldChange(dataField, Number(regNum));
                if (fieldLastMeta)
                    cellEl.title = fieldLastMeta.metas + " - " + H.dateToStr(new Date(this.rowData.ts.ts * 1000), false);
                 */

                if (this.selectedReg && this.selectedReg.num == Number(regNum))
                    cellEl.classList.add("selected-reg-cell");
                if (this.rowData.isValueManualyEdited(dataField, Number(regNum)))
                    cellEl.classList.add("field-read-only");

                if (cellEl.classList.contains('cTotDiff')) {
                    if (cellValue < 0) cellEl.classList.add("paidLess");
                    if (cellValue > 0) cellEl.classList.add("paidMore");
                }

                if (this.ctrlPressField && varName === this.ctrlPressField) {
                    if (firstDate.getTime() <= currDate.getTime())
                        cellEl.classList.add('ctrl');
                }

                if (this.lastRowDateMassEdit && this.lastRowDateMassEdit.length > 5) {
                    cellEl.classList.remove('ctrl');
                    if (lastDate && currDate &&
                        currDate.getTime() <= lastDate.getTime() &&
                        firstDate.getTime() <= currDate.getTime() &&
                        varName === this.ctrlPressField) {
                        cellEl.classList.add('massEditTarget');
                    }
                }
                if (cellVal.length === 0)
                    cellEl.classList.add('empty');
                else {
                    cellEl.classList.remove('empty');

                    if (dataInputType && dataInputType.includes('MACRO:'))
                        cellEl.classList.add('field-macro');
                    if (dataInputType && dataInputType.includes('CALC:'))
                        cellEl.classList.add('field-calc');
                    if (dataInputType && dataInputType.includes('MAP:'))
                        cellEl.classList.add('field-map');
                    if (dataInputType && dataInputType.includes('EDITONE'))
                        cellEl.classList.add('field-edit-one');
                    if (dataInputType && dataInputType.includes('EDITMANY'))
                        cellEl.classList.add('field-edit-many');
                }

                // {'field'+f.toUpperCase() ,'field' ,'reg-num-'+reg.num ,'field-map':rowData['_'+f + reg.num]&&rowData['_'+f + reg.num].includes('MAP')}
            });
        }

    }

    dismissAll() {
        this.replaceCompteurMode = false;
        this.compteurReplacementDate = null;
        this.compteurCurrentValue = null;
        this.compteurNewStartupValue = null;
        this.editMode = '';
        this.ctrlPressField = '';
        this.cb.emit({arg: 'cancelEdit'});
    }

    async paste() {
        try {
            const text = await navigator.clipboard.readText();
            console.log(text.split("\n"));
        } catch (error) {
            console.log('Failed to read clipboard');
        }
    }

    ngAfterViewInit(): void {
        // this.adaptCellsStyling();
    }

    ngOnChanges(changes: SimpleChanges) {
        // this.extraCells = Pdm.getTotFields(this.regs, this.rowData);
        //  console.log("ngOnChange", changes);

        if (changes['ctrlPressField']) {
            // console.log("--> ngOnChange-ctrlPressField", changes['ctrlPressField']);
        }
        if (changes['selectedReg']) {
            // console.log("--> ngOnChange-selectedReg", changes);
        }
        this.autoFields = Object.keys(Pdm.getTotFields(this.regs));
        this.metas = this.rowData.metas;
        // const cTotPaid = Number(this.rowData.c_tot_paid) | 0;
        // const cTotCalced = Number(this.rowData.tots['cTot']) | 0;
        // this.cTotDiff = cTotPaid - cTotCalced;
        setTimeout(() => {
            this.adaptCellsStyling();
            this.dateDayName = H.getDayName(this.rowData.date).replace(".", "");
        }, 20);

    }

}
