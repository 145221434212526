import {Component, OnDestroy, OnInit} from '@angular/core';
import {Plot, Resp, User} from "../../../shared/models/models";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {H} from "../../../shared/helpers/H";
import {BaseComponent} from "../../../shared/BaseComponent";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {Observable, Subject, Subscription, from, of} from "rxjs";
import {concatMap, delay, map, mergeMap} from 'rxjs/operators';
import {AppConfirmService} from "../../../shared/services/app-confirm/app-confirm.service";
import {ApiService} from "../../../shared/services/api.service";
import {HttpEvent, HttpResponse} from "@angular/common/http";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {PdmFieldConfig, PdmRegDef, PdmRegSyncConfig} from "../../../shared/models/PdmRegDef";
import {PdmDataRow, PdmDataRowExported, PdmRowStatefulIterator, RegFieldXlsMappingConfigItem} from "../../../shared/models/PdmDataRow";
import {PdmDataSummary} from "../../../shared/models/PdmDataSummary";
import {Pdm} from "../../../shared/models/Pdm";
import {K} from "../../../shared/models/K";

@Component({
    selector: 'app-pdm-editor',
    templateUrl: './pdm-editor.component.html',
    styleUrls: ['./pdm-editor.component.scss'],
    animations: egretAnimations
})
export class PdmEditorComponent extends BaseComponent implements OnInit, OnDestroy {
    mode = 'std';
    dispMode = 'std';
    regs: PdmRegDef[] = [];
    selectedReg: PdmRegDef = null;
    selectedField: string = null;
    showPopup = false;
    showPopupSniffer = false;

    ctrlPressed = '';
    firstRowDateMassEdit = '';
    lastRowDateMassEdit = '';

    selectedPdmData: Map<string, PdmDataRow> = new Map<string, PdmDataRow>();
    selectedPdmDataCalcHolder: Map<string, any> = new Map<string, any>();
    barsPlot: Plot;
    plotCassure: Plot;
    replaceCompteurMode = false;
    selectedReleveForActions: PdmDataRow = null;
    selectedFieldForActions = '';
    saveProgress = 0;

    dbKeyName = '';
    snifferSelectedCol: string;
    sniffer_field: string;
    mapFieldsToSnifferCol = {
        mon: 'i', wed: 'i', month_end: 'i', hebdo: 'd', daily: 'd'
    };
    snifferNoiseForceUpdate: number;
    releveForceUpdate = 0;

    regFieldsToDisplaySummary = ['num', 'label'];
    regFieldsToDisplayInReleve = [
        'label',
        //'is_derniere_cons',
    ];

    assignTot: number = 0;
    assignProgress: number = 0;
    errMessages: string[] = [];
    autoFields = {};
    tableWidth = 1000;

    xlsFileLink = "";
    stateFulIterator: PdmRowStatefulIterator = new PdmRowStatefulIterator();
    emptyMetas = 0;

    fieldMenuOffset: number = 0;

    get pdmHasSyncedRegs() {
        let retVal = false;
        if (this.site.selectedPdm && this.regs && this.regs.length > 0) {
            this.regs.forEach(reg => {
                if (reg.type === PdmRegDef.REG_TYPE_SYNCED) retVal = true;
            });
        }
        return retVal;
    }

    constructor(
        public myapp: AppService,
        public site: SiteService,
        public confirmService: AppConfirmService,
    ) {
        super();
        this.myapp.isDisplayedComponentAdmin = true;

        this.site.siteLoadingStatus.subscribe(status => {
            // console.log('siteLoadingStatus', status);
            if (status === SiteService.MAX_SITE_LOADING_STATUS) {
                //this.renderFluidSelector();
                //this.loadingStatus = status;
                //this.site.loadSitePdms();
                this.site.loadStatmensConfigs();
            }
        });
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            if (eventName === SiteService.PDMS_DATA_POPULATED) {
                if (this.site.year) {
                    this.site.selectYear(this.site.year); // restart routine
                }
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.site.scanReleveData();
                this.releveForceUpdate = H.unixTs();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_SELECTED) {
                this.calcPlotDataFromrows();
                this.releveForceUpdate = H.unixTs();
            }
        });

        this.myapp.globalPopupsIgniter.subscribe(({type, arg, field}) => {
            console.log("globalPopupsIgniter", type, arg, field);
            if (type === 'sniffer') {
                if (arg === "close") {
                    this.showPopupSniffer = false;
                    this.dbKeyName = null;
                    this.sniffer_field = null;
                } else {
                    this.dbKeyName = arg;
                    this.sniffer_field = field;
                    this.showPopupSniffer = true;
                }

            }
        });
    }

    /// TODO; should be called after calculateFields or yearlyConfig change and output stored in pdm
    /// TODO: calc 12 iteMs: ve_corr, ve_cumul, djSI, djEP and store inside PdmDataRow locally: no bdd persistance
    /// ---> should calc for 5 years
    /// quality only calced for selected year
    //// prevision calced for selected year and model of year_ref
    /// TODO: make PdmDataRow method that takes previous row in arg
    /// TODO: for each year store calced in obj containing 12 keys: num_1 to num_12
    calcPlotDataFromrows() {
        const filteredRows = Array.from(this.site.selectedPdm.relevesCache.values()).filter(row => {
            return row.releve_year > (this.site.year - 10)
                && row.releve_year <= this.site.year
                && !(row.releve_year === this.site.year && row.releve_num > (this.site.selectedMonthIndex + 1));
        });

        this.site.dataSummaryMap = new Map<string, PdmDataSummary>();
        filteredRows.forEach(pdmData => {
            if (this.site.dataSummaryMap.has(pdmData.releve_year.toString())) {
                const currentAccumulator: PdmDataSummary = this.site.dataSummaryMap.get(pdmData.releve_year.toString());
                currentAccumulator.releves_count = pdmData.releve_num;
                currentAccumulator.days += pdmData.days;
                currentAccumulator.dist = pdmData.dist;
                currentAccumulator.last_date = pdmData.date;
                currentAccumulator.ve2Label = pdmData.exported.ve2Label;

                Object.keys(pdmData.exported).forEach(k => {
                    if (k.includes("_")) {
                        currentAccumulator[k + "_last"] = 30 * pdmData.exported[k] / pdmData.days;
                        currentAccumulator[k + "_cumul"] += pdmData.exported[k];
                    }
                });
                //console.log("YEAR exists: Num:" + pdmData.releve_year, currentAccumulator);
            } else {
                //console.log("YEAR NEWW: Num:" + pdmData.releve_num, pdmData.metas, pdmData.date);
                let accumulator = {
                    releves_count: 1,
                    last_date: pdmData.date,
                    dist: pdmData.dist || 0,
                    days_cumul: pdmData.days_cumul || 0,
                    days: pdmData.days,
                    ve2Label: pdmData.exported.ve2Label
                };

                Object.keys(pdmData.exported).forEach(k => {
                    if (k.includes("_")) {
                        // otherwise its a label or other meta
                        accumulator[k + "_last"] = 30 * pdmData.exported[k] / pdmData.days;
                        accumulator[k + "_cumul"] = pdmData.exported[k];
                    }
                });
                const constDataSummary = new PdmDataSummary(accumulator);
                if (constDataSummary.dist > 15)
                    this.site.dataSummaryMap.set(pdmData.releve_year.toString(), constDataSummary);
            }
        });

        this.plotCassure = new Plot(this.site.year, 1, 'scatter', Plot.SECTION_SIGNATURE_FINDER, 'CONS', false, false,
            PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);
        this.barsPlot = new Plot(this.site.year, 5, 'line', Plot.SECTION_BASIC,
            'CONS', true, false, "VE1_D".toLowerCase(), this.site.selectedMonthIndex);
    }

    createDraftPdm(fluid) {
        const varname = fluid.substring(0, 2).toLowerCase() + "" + H.randomStr(5).toLowerCase();
        const pdmJson = {
            uid: varname + H.randomInt(10000, 99999),
            uid_site: this.site.uid,
            uid_client: this.site.clientSite.uid_client,
            fluid,
            label: "Draft-" + fluid + "-" + H.randomInt(100, 999),
            label_short: "Draft-" + fluid + "-" + H.randomInt(100, 999),
            status: Pdm.STATUS_DRAFT
        } as Pdm;
        const pdm: Pdm = new Pdm(pdmJson);
        this.site.api.savePdm(pdm, true).subscribe(resp => {
            console.log("Saved Pdm", resp);
            this.site.loadSitePdms();
        });
    }

    selectPdm(pdm: Pdm) {
        this.errMessages = [];
        this.site.selectedPdm = pdm;
        this.selectedReg = null;
        this.showPopupSniffer = false;
        this.selectedField = null;
        this.selectedFieldForActions = '';
        console.log("selectPdm", this.site.selectedPdm);
        this.loadRegs();
        this.initLocalDataMap('Select Pdm');

        //localStorage.removeItem("pdmToEdit");
    }

    selectReg(reg: PdmRegDef) {
        this.selectedReg = new PdmRegDef(reg);
        console.log("selectReg", this.selectedReg);
        this.myapp.pdmEditorTabIndex = 1;
    }

    /*
    HTTP REQ
     */
    loadRegs(updatedReg: PdmRegDef = null) {
        this.regs = [];
        if (updatedReg)
            this.selectedReg = new PdmRegDef(updatedReg);
        this.site.api.getPdmRegs(this.site.selectedPdm.uid).subscribe(resp => {
            this.regs = [];
            resp.body.forEach(rawReg => {
                this.regs.push(new PdmRegDef(rawReg));
            });
            this.regs = this.sortArrayByKeyVal(this.regs, 'disp_order');
            this.autoFields = Pdm.getTotFields(this.regs);
            // this.tableWidth = this.regs.length * 4 * 74 + Object.keys(this.autoFields).length * 104 + 6 * 110;
            this.tableWidth = this.regs.length * 220 + 3 * 74 + 8 * 74;
            this.tableWidth = this.regs.length * 450 + 3 * 80 + 8 * 74;
            this.myapp.toastr.success('Registres chargées', 'Evenement!');
        });
    }

    syncFtp(wat: string) {
        this.site.api.syncFtp(wat).subscribe(resp => {
            console.log("reloadEPFromSftp()", resp);
        });
    }

    saveToServer() {
        let sizeToSend = 0;
        this.saveProgress = 0;
        const stateFulIterator: PdmRowStatefulIterator = new PdmRowStatefulIterator();
        const reqObj: Map<string, string> = new Map<string, string>();
        let prevRow: PdmDataRow = null;
        this.selectedPdmData.forEach((v, k) => {
            // if after many assignments from different sources it happens that the previous line date changes, reassign prev date
            v.populateFirstNumDaysAndYear(prevRow);
            // v.countReleveAndSetNum(stateFulIterator);
            this.saveProgress++;
            const objToSave = v.getJsonToSave(this.site.clientSite, this.site.selectedPdm, this.regs);
            // console.log("RowData after: ", objToSave);
            if (v.ts > 0 && sizeToSend < (1024 * 7000)) {
                const strJsonData = JSON.stringify(ApiService.bs64FromJsonStr(objToSave));
                sizeToSend += strJsonData.length;
                reqObj.set(k, strJsonData);
            }
            stateFulIterator.prevDate = k;
            prevRow = v;
        });
        console.log("SaveToServer", "Rows count:", reqObj.size, " Character count: ", sizeToSend);
        this.myapp.spinner.show('spinnerSaveServer', {fullScreen: true});
        this.site.api.savePdmDataOneChunk(Object.fromEntries(reqObj), this.site.selectedPdm.is_vpdm)
            .subscribe(resp => {
                    console.log("Saving data to server " + resp.type, resp);
                    this.saveProgress = resp.type * 25;
                    if (resp.type < 4) {
                        const event = resp as HttpEvent<any>;
                        // console.log("Resp < 4, EVENT: " + resp.type, this.saveProgress, event);
                    } else {
                        // console.log("Else type >=4 " + resp.type, resp);
                    }
                    if (resp.type === 4) {
                        this.myapp.spinner.hide('spinnerSaveServer');
                        const httpResp = resp as HttpResponse<Resp>;
                        console.log("Saving data to server in 4 " + resp.type, httpResp.body);
                        if (httpResp.body.status === 1) {
                            this.selectedPdmData = new Map<string, any>();
                            this.site.populatePdmCache(true, true, "afterOneChunkSave");
                        } else {
                            console.error("ERROR: saving data to server", resp);
                        }
                    }
                }, err => {
                    console.log(" saveToServer() ERROR ::", err);
                }
            );
    }

    syncMeteo() {
        if (!this.site.clientSite.weather)
            this.myapp.toastr.error('Station meteo non définie', 'Erreur !');
        this.site.api.syncPdmsMeteo(this.site.clientSite, this.site.selectedPdm.uid).subscribe(resp => {
            console.log("Resp", resp);
            this.site.siteSpecificEventTriggerer.next(SiteService.PDMS_DATA_LOADED_METEO_INTO_SERVER);
            this.myapp.toastr.success('Meteo chargée synchronisée', 'Evenement !');
        });
    }

    syncHoraire() {
        if (!this.site.clientSite.vars_extra || this.site.clientSite.vars_extra.length === 0) {
            this.myapp.toastr.error('Pas de variables suplémentaires', 'Evenement !');
            return;
        }
        this.site.api.syncPdmsHoraire(this.site.clientSite, this.site.selectedPdm.uid).subscribe(resp => {
            console.log("Resp", resp);
            this.site.siteSpecificEventTriggerer.next(SiteService.PDMS_DATA_LOADED_VAREXPL_INTO_SERVER);
            this.myapp.toastr.success('Variables Expl. supplémentaires synchronisée', 'Evenement !');
        });
    }

    exportXls() {
        this.xlsFileLink = null;
        const pdmWithoutRows = {...this.site.selectedPdm, relevesCache: "2"};
        const chunks = {
            pdm: pdmWithoutRows,
            regs: this.regs,
            rows: Array.from(this.selectedPdmData.values())
        };
        const chunksJsonArr = JSON.stringify(chunks);
        console.log("ExportXlsx", chunks);
        //console.log("ExportXlsx: " + chunksJsonArr.length, chunksJsonArr);
        this.site.api.printPdmToXls(chunks).subscribe(resp => {
            console.log("Resp", resp);
            this.saveProgress = resp.type * 25;
            if (resp.type < 4) {
                const event = resp as HttpEvent<any>;
                //console.log("Resp < 4, EVENT: " + resp.type, this.saveProgress, event);
            }
            if (resp.type === 4) {
                const httpResp = resp as HttpResponse<Resp>;
                if (httpResp.body.status === 1) {
                    this.xlsFileLink = this.site.api.getLinkXls(this.site.selectedPdm.label);
                } else {
                    this.xlsFileLink = null;
                    console.error("ERROR: Export xlsx", resp);
                }
            }
        });
    }

    /*
    ASSIGN Hand CALC
     */
    assignDataToFieldFromxls({data, mapConfig}) {
        console.log("assignDataToField", data, mapConfig, this.selectedField);
        this.myapp.spinner.show('spinnerAssignMap', {fullScreen: true});

        // Using an object to hold previous states in loops is because object are passed by ref while primitiv values are not.
        const stateFulIterator: PdmRowStatefulIterator = new PdmRowStatefulIterator();
        mapConfig = mapConfig as RegFieldXlsMappingConfigItem;
        this.assignProgress = 0;
        this.assignTot = data.size;
        let prevRow: PdmDataRow = null;
        data.forEach((value, key) => {
            // check if rowData is older than pdm date start
            const dateFromKey = new Date(key);
            const dateFromPdmStart = new Date(this.site.selectedPdm.date_start);
            const diff = dateFromKey.getTime() - dateFromPdmStart.getTime();
            if (diff < 0) return;

            this.assignProgress++;
            ///TODO: check if following line is reliable
            // if (Number(value) === 0) return;

            if (!this.selectedPdmData.has(key)) {
                console.log("Assigning: ", key, "=> Prev:", stateFulIterator.prevDate, {date: key, date_prev: stateFulIterator.prevDate})
                const newRow = new PdmDataRow({date: key, date_prev: stateFulIterator.prevDate});
                newRow.populateFirstNumDaysAndYear(prevRow);
                //newRow.countReleveAndSetNum(stateFulIterator);
                this.selectedPdmData.set(key, newRow);
            }

            const stored = this.selectedPdmData.get(key);
            stored.setFieldValue(this.selectedReg, this.selectedField, value, mapConfig.getStr());

            if (mapConfig.targetField.startsWith('i')) {
                stored.setFieldValue(this.selectedReg, 'p', stateFulIterator.prevIndex, "");
            }
            this.selectedPdmData.set(key, stored);
            stateFulIterator.prevIndex = value;
            stateFulIterator.prevDate = key;
            prevRow = stored;
            // console.log("Add date:" + key + " to: " + mapConfig.targetField, value, this.assignProgress + '/' + this.assignTot, stored.releve_num, stored.dist);
        });

        this.myapp.spinner.hide('spinnerAssignMap');
        this.showPopup = false;
    }

    assignDataToFieldFromSniffer(data: Map<string, number>, col: string, reg: PdmRegDef, field: string, mode: string) {
        this.myapp.spinner.show('spinnerAssignMap', {fullScreen: true});
        const stateFulIterator: PdmRowStatefulIterator = new PdmRowStatefulIterator();
        this.assignTot = data.size;
        this.assignProgress = 0;
        let prevRow: PdmDataRow = null;
        data.forEach((value, key) => {
            this.assignProgress++;
            /// TODO: check if following line is reliable
            // if (Number(value) === 0) return;
            // console.log("Add date:" + key + " to: " + field, value, this.assignProgress + '/' + this.assignTot);
            if (!this.selectedPdmData.has(key)) {
                const newRow = new PdmDataRow({date: key, date_prev: stateFulIterator.prevDate});
                newRow.populateFirstNumDaysAndYear(prevRow);
                // newRow.countReleveAndSetNum(stateFulIterator);
                this.selectedPdmData.set(key, newRow);
            }

            const metas = "SYNC:" + col + ":" + this.selectedReg[field + "_conf"].db_name;
            const stored = this.selectedPdmData.get(key);
            stored.setFieldValue(this.selectedReg, this.selectedField, value, metas);
            if (field.includes('i')) {
                stored.setFieldValue(this.selectedReg, 'p', stateFulIterator.prevIndex, "");
            }
            this.selectedPdmData.set(key, stored);
            prevRow = stored;
            stateFulIterator.prevIndex = value;
            stateFulIterator.prevDate = key;
        });
        this.myapp.spinner.hide('spinnerAssignMap');
        this.selectedReg.ts_updated++;///force sniffer component refresh
    }

    calculateFields(force = false) {
        // start by populating synced regs
        // this.assignVirtualRegs();
        this.stateFulIterator = new PdmRowStatefulIterator();
        this.selectedPdmDataCalcHolder = new Map();
        this.regs = this.sortArrayByKeyVal(this.regs, 'disp_order');
        let rowsCaledCount = 0, askCount = 0;
        this.site.selectedPdm.data_summary = {};
        let prevRow: PdmDataRow;
        from(Array.from(this.selectedPdmData.values())).pipe(
            concatMap(row => {
                askCount++;
                const obsToReturn = this.getPdmDataRowCalculationObservable(row, prevRow, force);
                prevRow = row;
                return obsToReturn;
            })
        ).subscribe(obs => {
            rowsCaledCount++;
            if (rowsCaledCount === this.selectedPdmData.size) {
                console.log("calculateFields: Force:" + force, " Rows Calced: ", rowsCaledCount, " Size:", this.selectedPdmData.size);

            }
        });
    }

    /// TODO Mark for removal
    getPdmDataRowCalculationObservable(pdmData: PdmDataRow, prevRow: PdmDataRow, force: boolean) {
        return new Observable((observer) => {
            // injecting globalData of row date in pdmData object - k = key = date of row
            const k = pdmData.date;
            pdmData.populateFirstNumDaysAndYear(prevRow);
            pdmData.calcRow(this.regs, this.site.selectedPdm, force);
            // console.error("TODO: REMOVE, SHOULD NOT RUN", prevRow)
            // calc in a new Map, then set it into original one after finish
            this.selectedPdmDataCalcHolder.set(k, pdmData); // update the calc holder
            if (this.selectedPdmDataCalcHolder.size === this.selectedPdmData.size) {
                this.selectedPdmData = new Map();
                this.selectedPdmData = new Map(this.selectedPdmDataCalcHolder);
            }

            this.stateFulIterator.prevDate = pdmData.date;

            observer.next(k);
            observer.complete();
        });
    }

    startEdit(ev: KeyboardEvent) {
        if (ev.ctrlKey && ev.key === "y") {
            const element = ev.target as HTMLElement;
            const field = element.getAttribute('field');
            console.log("startEdit", field, element.classList);
            this.ctrlPressed = field;
        }
    }

    endEdit({field, value}) {
        console.log("endEdit", field, this.firstRowDateMassEdit, this.lastRowDateMassEdit, value);
        const firstDate = new Date(this.firstRowDateMassEdit);
        const lastDate = new Date(this.lastRowDateMassEdit);
        this.selectedPdmData.forEach((rowData, key) => {
            const fName = field + this.selectedReg.num;
            const currDate = new Date(key);
            if (currDate.getTime() === firstDate.getTime() ||
                (currDate.getTime() > firstDate.getTime() && currDate.getTime() <= lastDate.getTime())) {
                if (value === '-1') {
                    delete rowData.data[fName];
                    rowData.clearMetasOfField(field, this.selectedReg.num);
                    rowData.setTs();
                    console.log("DELETE WITH -1 Command:", " fName: " + fName, rowData.data);
                } else {
                    const metas = this.lastRowDateMassEdit === '' ? 'EDITONE' : "EDITMANY:" + this.lastRowDateMassEdit;
                    rowData.setFieldValue(this.selectedReg, field, Number(value), metas);
                }
            }
            this.selectedPdmData.set(key, rowData);
        });
        console.log("this.selectedPdmData", this.selectedPdmData);
        this.resetMassEdit();
    }

    resetMassEdit() {
        this.ctrlPressed = '';
        this.firstRowDateMassEdit = '';
        this.lastRowDateMassEdit = '';
    }

    saveCompteurReplacement(reg: PdmRegDef, {rowDate, evObj, dataItemsObj}) {
        const rowData = this.selectedPdmData.get(rowDate);
        Object.keys(dataItemsObj).forEach(field => {
            const metas = 'EDITONE:CPT_REPLACE' + this.lastRowDateMassEdit;
            rowData.setFieldValue(reg, field, Number(dataItemsObj[field]), metas);
        });
        //if (!rowData.events) rowData.events = {};
        //rowData.events["i" + reg.num] = evObj;
        rowData.calcD(reg);
        rowData.setTs();
        this.selectedPdmData.set(rowDate, rowData);
    }

    /*
    DELETING
     */
    delField(field, arg) {
// notEdited all map
        this.confirmService.confirm({title: 'Etes vous sûrs?', message: "Cette action est irreversible."})
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this._delField(field, arg);
            });
    }

    _delField(field, arg) {
        console.log("delField", field, arg);
        this.selectedPdmData.forEach((rowData, date) => {
            if (arg === 'map') {
                const lastLogChange = rowData.metas[rowData.metas.length - 1];
                if (rowData.data[field] !== undefined && lastLogChange.metas.includes('MAP:')) {
                    delete rowData.data[field];
                    /// TODO: either add log in metas that says it was deleted, or delete metas of field,
                    /// Maybe metas should be a map of fieldname to log
                    // rowData.metas = [];
                    rowData.ts = H.unixTs();
                    rowData.ts_assigned = H.unixTs();
                }
            }
            if (arg === 'all') {
                if (rowData.data[field] !== undefined) {
                    delete rowData.data[field];
                    delete rowData.metas[field];
                    rowData.ts = H.unixTs();
                }
            }
            if (arg === 'notEdited') {
                if (rowData[field] !== undefined && !rowData['_' + field].includes('EDIT')) {
                    delete rowData.data[field];
                    // delete rowData.metas[field];
                    rowData.ts = H.unixTs();
                    rowData.ts_assigned = H.unixTs();
                }
            }
        });
    }

    deleteAll() {
        this.confirmService.confirm({title: 'Etes vous sûrs?', message: "Cette action est irreversible."})
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this._deleteAll();
            });
    }

    _deleteAll() {
        // this.myapp.spinner.show('spinnerSaveServer', {fullScreen: true});
        this.site.api.delPdmData(this.site.selectedPdm.uid).subscribe(resp => {
            this.myapp.spinner.hide('spinnerSaveServer');
            this.site.selectedPdm.relevesCache = new Map<string, PdmDataRow>();
            this.selectedPdmData = new Map<string, any>();
            this.site.populatePdmCache();
        });
    }

    clearLocalStorage() {
        localStorage.removeItem("pdmToEdit");
        localStorage.removeItem("pdmXlsLastSelected");
    }

    deleteReg(uidReg: string) {
        if (this.selectedPdmData.size > 0) {
            this.myapp.toastr.error("Error", "On peut supprimer un registre qu'en absence de données.", {timeOut: 3000});
            return;
        }
        this.site.api.delPdmReg(uidReg).subscribe(resp => {
            this.selectedReg = null;
            this.selectedPdmData = new Map<string, PdmDataRow>();
            this.myapp.toastr.success("Succès", "Registre supprimé.", {timeOut: 3000});
            this.loadRegs();
        });
    }

    deletePdm() {

        if (!this.site.selectedPdm) {
            this.myapp.toastr.error("Error", "Veuillez selectionner le pdm à supprimer.", {timeOut: 3000});
            return;
        }
        if (this.regs.length > 0) {
            this.myapp.toastr.error("Error", "On peut supprimer un PDM contenant des registres.", {timeOut: 3000});
            return;
        }
        this.site.api.delPdm(this.site.selectedPdm.uid).subscribe(resp => {
            this.site.selectedPdm = null;
            this.selectedReg = null;
            this.selectedPdmData = new Map<string, PdmDataRow>();

            this.myapp.toastr.success("Succès", "PDM supprimé.", {timeOut: 3000});
            this.site.loadSitePdms();
        });
    }

    /*
    UI TOOLS
     */

    showFieldMenu(reg: PdmRegDef, f: string, event: MouseEvent) {
        console.log("showFieldMenu", f, event);
        this.selectedReg = reg;
        this.selectedField = f;
        this.selectedFieldForActions = f + reg.num;
        this.fieldMenuOffset = event['layerX'];
    }

    openXlsMapper() {
        this.selectedFieldForActions = '';//disable menu
        if (this.selectedReg && this.selectedReg.type === 'SYNCED') {
            this.myapp.toastr.info("Régistre synchronisé - non importable", "Info", {timeOut: 5000});
            return;
        }
        if (this.selectedField === 'k') {
            this.myapp.toastr.info("Peut pas importer K", "Info", {timeOut: 5000});
            return;
        }
        /*
        if (this.selectedField === 'd' || this.selectedField === 't' || this.selectedField === 'c') {
            const conf = this.selectedReg[this.selectedField + '_conf'] as PdmFieldConfig;
            if (!conf || conf.data_entry !== 'ENTRY_MANUAL') {
                const entryType = conf && conf.data_entry ? conf.data_entry : "Non définit";
                this.myapp.toastr.info("Le champs " + this.selectedField
                    + " Ne peut etre Importé par Xls: Uniquement " + entryType, "Info", {timeOut: 5000});
                return;
            }
        }*/
        this.showPopup = true;
    }

    scrollToBottom() {
        const element = <HTMLElement>document.querySelector('#rightside-content-hold');
        element.scrollTop = element.scrollHeight;
    }

    formCallBack(ev) {
        const {arg, obj, value, col, reg} = ev;
        // console.log("formCallBack", arg, ev);
        // cb.emit({act:'setSnifferCol',col:col})
        if (arg === "selectField") {
            this.selectedField = obj;
        }
        if (arg === "openXlsMapper") {
            this.openXlsMapper();
        }
        if (arg === "plotError") {
            this.errMessages.push(value);
        }
        if (arg === "cancelEdit") {
            this.resetMassEdit();
        }
        if (arg === "setEditStartRowDate") {
            this.firstRowDateMassEdit = value;
            this.selectedReg = reg;
            console.log("formCallBack:" + value, ev);
        }
        if (arg === "setEditEndRowDate") {
            this.lastRowDateMassEdit = value;
            console.log("formCallBack:setEditEndRowDate", value, ev);
        }

        if (arg === "massUpdate") {
            this.endEdit(obj);
        }
        if (arg === "replaceCompteurReset") {
            const {rowDate} = obj;
            const rowData = this.selectedPdmData.get(rowDate);
            delete rowData.data['f' + reg.num];
            delete rowData.data['s' + reg.num];
            /* console.log("--replaceCompteurReset", 'i' + reg.num, rowData.events['i' + reg.num], rowData.events);
           * if (rowData.events['i' + reg.num] !== undefined) {
                 rowData.events['cancelReplace:' + reg.num] = H.unixTs();
                 delete rowData.events['i' + reg.num];
             }*/
            rowData.calcD(reg);
            rowData.setTs();
            this.selectedPdmData.set(rowDate, rowData);
        }
        if (arg === "replaceCompteur") {
            this.saveCompteurReplacement(reg, obj);
        }
        if (arg === "PDM_CLONAGE_DONE") {
            console.log("formCallBack", "received");
            this.loadRegs();
        }
        if (arg === "PDM")
            // obj contains form output
            this.site.loadSitePdms();
        if (arg === "REG")
            this.loadRegs(obj);

        if (arg === "setSnifferCol") {
            this.errMessages.push(value);
        }
        if (arg === "snifAssignHigher") {
            this.showPopupSniffer = false;
            const field = this.mapFieldsToSnifferCol[col];// <- temporarly, sniffer popup should only open at config, but sync automatically every new day
            this.assignDataToFieldFromSniffer(obj, col, reg, field, "onlyHigher");
        }
    }

    ngOnInit(): void {
        /// TODO: review this data cycle
        // this.checkIfDataOkAndManage();
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            // console.log("PdmEditor: ngInInit():", eventName);
            if (eventName === SiteService.PDMS_DATA_POPULATED) {
                this.initLocalDataMap("RX_EVENT: PDMS_DATA_POPULATED");
            }
            if (eventName === SiteService.PDMS_DATA_LOADED_VAREXPL_INTO_SERVER) {
                this.site.populatePdmCache(true, true);
            }
            if (eventName === SiteService.PDMS_DATA_LOADED_METEO_INTO_SERVER) {
                this.site.populatePdmCache(true, true);
            }
            //**** In all cases check metas
            // this.checkIfDataOkAndManage();
        });
        this.myapp.storeCurrentRoute();
    }

    assignVirtualRegs() { // Batir VPDM rows
        const filteredRegs = this.regs.filter(reg => reg.sync_config && reg.sync_config.uid_pdm);
        from(filteredRegs).pipe(
            concatMap(reg => {
                return this.site.api.getPdmData(reg.sync_config.uid_pdm, false, false)
                    .pipe(map(resp => [resp, reg]));
            }),
        ).subscribe(([resp, reg]) => {
            if (resp) {
                this._processVirtualRegResp(resp, reg);
            }
        });
    }

    _processVirtualRegResp(resp: Resp, reg: PdmRegDef) {
        console.log("_processVirtualRegResp:init Map", resp, reg);
        const serverData = resp.body;
        const totType = reg.sync_config.tot_type;
        const foreignRegNum = reg.sync_config.reg_num;
        reg.sync_config = new PdmRegSyncConfig(reg.sync_config);

        /// TODO: Make sure data is sorted on backend
        let prevRow: PdmDataRow = null;
        serverData.forEach(rowReleveFromServer => { // scan pdm data rows
            console.log("rowReleveFromServer", rowReleveFromServer);
            const rowData = new PdmDataRow(rowReleveFromServer, [], false);

            const dateFromPdmStart = new Date(this.site.selectedPdm.date_start);
            const diff = (new Date(rowData.date)).getTime() - dateFromPdmStart.getTime();
            if (diff < 0) {
                this.selectedPdmData.delete(rowData.date);
                return;
            }

            if (!this.selectedPdmData.has(rowData.date)) {
                const newRow = new PdmDataRow({date: rowData.date, date_prev: rowData.date_prev, data: {}});
                newRow.days = rowData.days;
                newRow.dist = rowData.dist;
                newRow.vars_horaire = rowData.vars_horaire;
                newRow.vars_meteo = rowData.vars_meteo;
                newRow.releve_num = rowData.releve_num;
                newRow.releve_year = rowData.releve_year;
                console.log("assign first");
                newRow.populateFirstNumDaysAndYear(prevRow);
                this.selectedPdmData.set(rowData.date, newRow);
            }

            const stored = this.selectedPdmData.get(rowData.date);
            prevRow = stored;
            if (reg.type === PdmRegDef.REG_TYPE_SYNCED) {
                // we only need from this row the data related of {{foreignRegNum}}
                K.regFields.forEach(f => {
                    stored.setFieldValue(reg, f, rowData.data[f + foreignRegNum]);
                });
            }
            // if sync = tot
            /*
            if (reg.reg_type_sub === PdmRegDef.REG_SUB_TYPES_SYNCED_TOT) {
                const d = Number(rowData.tots['d' + totType]);
                const c = Number(rowData.tots['c' + totType]);
                // console.log("_processVirtualRegResp", reg);
                stored.setFieldValue(reg, 'd', d, reg.sync_config.toStr());
                stored.setFieldValue(reg, 'c', c, reg.sync_config.toStr());
                if (d && c && !isNaN(c) && !isNaN(d)) {
                    stored.setFieldValue(reg, 't', c / d, reg.sync_config.toStr());
                }
            }*/

            if (rowData.vars_meteo && rowData.vars_meteo['m1']) {
                stored.vars_meteo_obj = rowData.vars_meteo_obj;
                stored.vars_meteo = rowData.vars_meteo;
            }
            this.selectedPdmData.set(rowData.date, stored);
        });
    }

    /// TODO Mark for removal
    checkIfDataOkAndManage() {
        /// TODO: check this feature reliability
        // this method was made because there were 2 way of loading rows: a light mode without metas and a heavy mode with all data
        // but this complication is safe for removal since all dataRow was simplified
        /// Check metas
        this.emptyMetas = 0;
        const metas = [];
        if (this.site.selectedPdm && this.site.selectedPdm.relevesCache) {
            this.site.selectedPdm.relevesCache.forEach(row => {
                if (!row.metas) this.emptyMetas++;
                else metas.push(row.metas);
            });
            if (this.emptyMetas > 0) {
                console.log("checkIfDataOkAndManage()");
                this.site.populatePdmCache(true, true);// load data WITH METAS, in the call back, poulate local and display
            } else {
                this.initLocalDataMap('checkIfCacheContainsMetas(): Metas not empty');// display data here
            }

            if (!this.regs || this.regs.length === 0)
                this.loadRegs();
        } else {
            // console.error("checkIfCacheContainsMetas(): Something is empty RELOADING, maybe no selected PDM", this.site.selectedPdm);
            // this.site.populatePdmCache(true, true);// load data WITH METAS, in the call back, poulate local and display
        }
    }

    // check if relevesCache is present in pdm and copy it this.selectedPdmData, and if not load it from server
    // releve-row component is linked to local var not to pdm releveCache
    initLocalDataMap(debug = "") {
        console.log("initLocalDataMap: started. Caller: ", debug);
        this.selectedPdmData = this.site.selectedPdm.relevesCache;
        if (!this.selectedPdmData) {
            this.selectedPdmData = new Map<string, PdmDataRow>();
            // console.log("initLocalFromMap IF", this.selectedPdmData);
            this.site.populatePdmCache(true, true, "PdmEditor, initLocalDataMap");
        }
    }

    ngOnDestroy(): void {
        localStorage.removeItem("pdmToEdit");
    }
}
