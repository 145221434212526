import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {AppConfirmService} from "../../../shared/services/app-confirm/app-confirm.service";
import {Pdm} from "../../../shared/models/Pdm";
import {MatSidenav} from "@angular/material/sidenav";
import {PdmRegDef} from "../../../shared/models/PdmRegDef";
import {BaseComponent} from "../../../shared/BaseComponent";
import {PdmDataRow, PdmRowStatefulIterator} from "../../../shared/models/PdmDataRow";
import {H} from "../../../shared/helpers/H";
import {ApiService} from "../../../shared/services/api.service";
import {HttpEvent, HttpResponse} from "@angular/common/http";
import {Resp} from "../../../shared/models/models";

@Component({
    selector: 'app-saisie',
    templateUrl: './saisie.component.html',
    styleUrls: ['./saisie.component.scss']
})
export class SaisieComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('sidenav') sidenav: MatSidenav;
    selectedField: string;
    selectedFieldForActions: string;
    clientWidth: number;
    clientHeight: number;
    tableWidth: number;
    regFieldsToDisplaySummary = ['num', 'reg_label'];
    regFieldsToDisplayInReleve = [
        'reg_label',
        'd_type',
        't_type',
        'c_type',
    ];

    selectedReg: PdmRegDef = null;
    regs: PdmRegDef[] = [];
    rows: PdmDataRow[] = [];
    autoFields = ['dTot', 'cTot'];
    fixedFields = ['i', 'k', 'd', 'a', 't', 'c'];

    selectedPdmData: Map<string, PdmDataRow> = new Map<string, PdmDataRow>();

    selectedRow: PdmDataRow = null;
    selectedRelevesPage = 1;
    relevesListPageSize = 25;
    relevesRowsStartIndex = 1;
    relevesRowsEndIndex = 25;

    errMessages: string[] = [];

    saveProgress = 0;

    ctrlPressed = '';
    firstRowDateMassEdit = '';
    lastRowDateMassEdit = '';
    newRowsCount = 0;

    constructor(public myapp: AppService,
                public site: SiteService,
                public confirmService: AppConfirmService) {
        super();
    }

    ngOnInit(): void {
        // console.log("PdmEditor: ngInInit():", "START--");
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            // console.log("PdmEditor: ngInInit():", eventName);
            if (eventName === SiteService.PDMS_DATA_POPULATED) {
                this.loadRegs();
            }
            if (eventName === SiteService.PDMS_DATA_LOADED_VAREXPL_INTO_SERVER) {
                this.site.populatePdmCache(true, true);
            }
            if (eventName === SiteService.PDMS_DATA_LOADED_METEO_INTO_SERVER) {
                this.site.populatePdmCache(true, true);
            }
        });
    }

    selectPdm(pdm: Pdm) {
        this.site.selectedPdm = pdm;
        console.log("selectPdm", this.site.selectedPdm);
        this.sidenav.close();
        this.site.populatePdmCache(true, true);
        this.loadRegs();
    }

    saveNewRows() {
        this.saveProgress = 0;
        const stateFulIterator: PdmRowStatefulIterator = new PdmRowStatefulIterator();
        const reqObj: Map<string, string> = new Map<string, string>();
        this.rows.forEach(row => {
            if (row.ts > 0)
                this.selectedPdmData.set(row.date, row);
        })
        let prevRow: PdmDataRow = null;
        this.selectedPdmData.forEach((v, k) => {
            // if after many assignments from different sources it happens that the previous line date changes, reassign prev date
            v.populateFirstNumDaysAndYear(prevRow);
            // v.countReleveAndSetNum(stateFulIterator);
            this.saveProgress++;
            const objToSave = v.getJsonToSave(this.site.clientSite, this.site.selectedPdm, this.regs);

            if (v.ts > 0) {
                console.log("RowData after: ", objToSave);
                reqObj.set(k, JSON.stringify(ApiService.bs64FromJsonStr(objToSave)));
            }
            stateFulIterator.prevDate = k;
            prevRow = v;
        });
        this.myapp.spinner.show('spinnerSaveServer', {fullScreen: true});
        this.site.api.savePdmDataOneChunk(Object.fromEntries(reqObj), this.site.selectedPdm.is_vpdm).subscribe(resp => {
                // console.log("Saving data to server" + resp.type, resp);
                this.saveProgress = resp.type * 25;
                if (resp.type < 4) {
                    const event = resp as HttpEvent<any>;
                    console.log("Resp < 4, EVENT: " + resp.type, this.saveProgress, event);
                }
                if (resp.type === 4) {
                    this.myapp.spinner.hide('spinnerSaveServer');
                    const httpResp = resp as HttpResponse<Resp>;
                    console.log("Saving data to server in 4 " + resp.type, httpResp.body);
                    if (httpResp.body.status === 1) {
                        this.selectedPdmData = new Map<string, any>();
                        this.site.populatePdmCache(true, true, "afterOneChunkSave");
                    } else {
                        console.error("ERROR: saving data to server", resp);
                    }
                }
            }
        );
    }

    formCallBack(ev) {
        const {arg, obj, value, col, reg} = ev;
        //cb.emit({act:'setSnifferCol',col:col})
        if (arg === "setSnifferCol") {
            this.errMessages.push(value);
        }
        if (arg === "plotError") {
            this.errMessages.push(value);
        }
        if (arg === "cancelEdit") {
            this.resetMassEdit();
        }
        if (arg === "setEditStartRowDate") {
            this.firstRowDateMassEdit = value;
            this.selectedReg = reg;
            console.log("formCallBack:setEditStartRowDate: " + value, ev);
        }
        if (arg === "setEditEndRowDate") {
            this.lastRowDateMassEdit = value;
            console.log("formCallBack:setEditEndRowDate " + value, ev);
        }

        if (arg === "massUpdate") {
            this.endEdit(obj);
        }
        if (arg === "selectRow") {
            this.selectedRow = value;
        }
        if (arg === "deleteRow") {
            const newArr = [];
            this.rows.forEach(r => {
                if (r.date !== value) newArr.push(r);
            });
            this.rows = [];
            this.rows = newArr;
        }
        if (arg === "PDM")
            // obj contains form output
            this.site.loadSitePdms();
        if (arg === "REG")
            this.loadRegs();
    }

    startEdit(ev: KeyboardEvent) {
        if (ev.ctrlKey && ev.key === "y") {
            const element = ev.target as HTMLElement;
            const field = element.getAttribute('field');
            console.log("BIM" + field, ev);
            this.ctrlPressed = field;
        }
    }

    endEdit({field, reg, value}) {
        console.log("endEdit", field, reg, value, this.firstRowDateMassEdit, this.lastRowDateMassEdit);
        const firstDate = new Date(this.firstRowDateMassEdit);
        const lastDate = new Date(this.lastRowDateMassEdit);
        this.selectedPdmData.forEach((rowData, key) => {
            const fName = field + this.selectedReg.num;
            const currDate = new Date(key);
            if (currDate.getTime() === firstDate.getTime() ||
                (currDate.getTime() > firstDate.getTime() &&
                    currDate.getTime() <= lastDate.getTime())) {
                if (value === '-1') {
                    delete rowData.data[fName];
                    rowData.clearMetasOfField(field, this.selectedReg.num);
                    rowData.setTs();
                    console.log("DELETE WITH -1 Command:", " fName: " + fName, rowData.data);
                } else {
                    const metas = this.lastRowDateMassEdit === '' ? 'EDITONE' : "EDITMANY:" + this.lastRowDateMassEdit;
                    rowData.setFieldValue(this.selectedReg, field, Number(value), metas);
                }
            }
            this.selectedPdmData.set(key, rowData);
        });
        console.log("this.selectedPdmData", this.selectedPdmData);
        this.resetMassEdit();
    }

    resetMassEdit() {
        this.ctrlPressed = '';
        this.firstRowDateMassEdit = '';
        this.lastRowDateMassEdit = '';
    }

    reverseAndGenEmptyRows() {
        if (this.selectedPdmData.size === 0) return;
        this.rows = Array.from(this.selectedPdmData.values());
        this.rows.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        this.rows = this.rows.slice(0, 15);
    }

    addRow() {
        if (this.newRowsCount > 0) return;
        let first = this.rows[0];
        const row = new PdmDataRow(first);
        row.date = H.dateToStrMysql(H.addDaysToDate(first.date, 30));
        //row.date_prev = first.date;

        row.reset();
        row.date_prev = first.date;
        row.is_in_creation = true;
        row.releve_num++;
        row.dist = first.dist + 30;
        row.populateFirstNumDaysAndYear(first);
        row.ts = 0;
        this.rows.unshift(row);
        this.newRowsCount++;
    }

    loadRegs() {
        let visibleRegsFieldsCount = 0;

        this.regs = [];
        this.site.api.getPdmRegs(this.site.selectedPdm.uid).subscribe(resp => {
            this.regs = [];
            resp.body.forEach(rawReg => {
                this.regs.push(new PdmRegDef(rawReg));
            });
            this.regs = this.sortArrayByKeyVal(this.regs, 'disp_order');

            this.regs.forEach(r => {
                this.fixedFields.forEach(f => {
                    if (r.shouldShow(f)) visibleRegsFieldsCount++;
                });
            });

            //this.autoFields = Pdm.getTotFields(this.regs);
            this.tableWidth = visibleRegsFieldsCount * 112 + Object.keys(this.autoFields).length * 112 + 200 + 3 * 112;
            console.log("Visibe regs fields count= ", visibleRegsFieldsCount, "  this.tableWidth ", this.tableWidth, this.autoFields);
            this.myapp.toastr.success('Registres chargées', 'Evenement!');
            this.initLocalDataMap('Select Pdm');
        });
    }

    initLocalDataMap(debug = "") {
        //console.log("initLocalDataMap: started. Caller: ", debug);
        if (!this.site.selectedPdm) return;

        if (!this.site.selectedPdm.relevesCache) {
            this.selectedPdmData = new Map<string, PdmDataRow>();
            //console.log("initLocalFromMap IF", this.selectedPdmData);
            this.site.populatePdmCache(true, true, "PdmEditor, initLocalDataMap");
        } else {
            this.selectedPdmData = this.site.selectedPdm.relevesCache;
            this.reverseAndGenEmptyRows();
        }

    }

    trackByFn(index, item) {
        return item.uid;
    }

    detectClientDimensions() {
        const plot = document.getElementById('plot-holder');
        if (!plot) return;
        this.clientWidth = plot.clientWidth - 40;
        this.clientHeight = window.innerHeight - 150;
    }

    sideNavListener(e) {
        this.detectClientDimensions();
    }

    ngOnDestroy(): void {
        localStorage.removeItem("pdmToEdit");
    }
}
