import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {SiteService} from "../../../shared/services/site.service";
import {Chapter} from 'app/shared/models/Action.model';
import {AppService} from "../../../shared/services/app.service";

@Component({
    selector: 'app-chapters-cpn',
    templateUrl: './chapters-cpn.component.html',
    styleUrls: ['./chapters-cpn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChaptersCpnComponent implements OnInit, OnChanges {
    @Input('uidSite') uidSite: string;
    @Input('type') type: string;
    @Input('chapters') chapters: Chapter[];
    @Output() OnSelectChapter: EventEmitter<Chapter> = new EventEmitter<Chapter>();

    @ViewChildren('actionIcons') actionIcons: QueryList<HTMLElement>;
    selectedChapter: ChapterNode = null;
    chaptersNodes: ChapterNode[] = [];
    treeTogleStatus = {};
    nestedItems = {};
    level1 = [];
    // chapters = [];
    editMode = true;

    constructor(public site: SiteService, private cdRef: ChangeDetectorRef,) {

    }

    ngOnInit(): void {
        this.bootstrap();
    }

    bootstrap(): void {
        this.chapters = this.site.chapters.filter(item => {
            return item.type === this.type;
        }).sort((a, b) => a.display_order - b.display_order);

        // console.log("Chapters", this.chapters);
    }

    getChapterChildren(parentId: string): Chapter[] {
        let chaps: Chapter[] = [];
        let index = 0;
        this.chapters.forEach(ch => {
            if (ch.parent === parentId) {
                index++;
                chaps.push(ch);
            }
        });
        return chaps.sort((a, b) => a.display_order - b.display_order);
        ;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('ngOnChanges', changes);
        this.bootstrap();
        //this.cdRef.detectChanges();
    }

    ngAfterViewChecked() {
        // console.log("ngAfterViewChecked()");
        // this.dateNow = new Date();
        // this.cdRef.detectChanges();
        // this.bootstrap();
    }

    selectChapter(ch) {
        //this.togleTreeItem(ch);
        this.selectedChapter = ch;
        this.OnSelectChapter.emit(ch);
        // console.log('selectChapter', this.treeTogleStatus);
        // this.filterObj.chapterId = ch.uid;
        // this.filterDatatable(null);

    }


}

interface ChapterNode {
    uid: string;
    name: string;
    index?: any;
    children?: ChapterNode[];
}

@Component({
    selector: 'tree-item',
    template: `
        <div class="tree-level tree-level-{{level}}"
             *ngIf="ch"
             cdkDrag cdkDragDisabled
             [cdkDragData]="ch"
             [cdkDropListData]="ch"
             cdkDropList id="drag_level1"
             cdkDropListSortingDisabled
             (cdkDropListDropped)="drop($event)"
             style="width: 100%;overflow: hidden; "
             [id]="'item-'+ch.uid"
             [ngClass]="{hidden:level>1&&!treeTogleStatus[ch.parent],active:selectedChapter&&selectedChapter.uid===ch.uid}">
            <table (click)="selectChapter(ch)" style="float: left">
                <tr *ngIf="!editMode">
                    <td class="w20">
                        <mat-icon *ngIf="getChapterChildren(ch.uid).length>0" matListIcon>
                            {{ level > 1 && treeTogleStatus[ch.uid] ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                        <mat-icon class="icon-small"
                                  *ngIf="getChapterChildren(ch.uid).length===0" matListIcon>
                            fiber_manual_record
                        </mat-icon>
                    </td>
                    <td>
                        <span *ngIf="!editMode"> {{ index + 1 }} :  {{ ch.title }}      </span>
                    </td>
                </tr>
                <tr *ngIf="editMode">
                    <td colspan="2">
                        <mat-form-field appearance="legacy">
                            <input matInput placeholder="Nouveau titre" [(ngModel)]="newTitle" (keyup.enter)="saveChapter($event)">
                        </mat-form-field>
                    </td>
                </tr>


            </table>
            <table *ngIf="!editMode&&myapp.isAdminOrSuperUser" style="width: 40px;float: right; ">
                <tr>
                    <td *ngIf="previousChapter">
                        <mat-icon inline (click)="moveUp(ch.uid)"
                                  matListIcon>keyboard_double_arrow_up
                        </mat-icon>
                    </td>
                    <td *ngIf="nextChapter">
                        <mat-icon inline (click)="moveDown(ch.uid)"
                                  matListIcon>keyboard_double_arrow_down
                        </mat-icon>
                    </td>
                    <td>
                        <mat-icon inline (click)="editMode=true" matListIcon>edit</mat-icon>
                    </td>
                    <td>
                        <mat-icon inline (click)="addChapter(ch)" matListIcon>add</mat-icon>
                    </td>
                </tr>
            </table>

        </div>
    `,

    styleUrls: ['./chapters-cpn.component.scss']
})
export class TreeItem implements AfterViewInit {
    @Input('level') level;
    @Input('ch') ch: Chapter;
    @Input('type') type: string;
    @Input('index') index: number;
    @Input('chapters') chapters: any[];//chapters of current level
    @Input('treeTogleStatus') treeTogleStatus;
    @Input('selectedChapter') selectedChapter;
    //
    newTitle: string;
    editMode = undefined;
    previousChapter: Chapter;
    nextChapter: Chapter;

    constructor(public site: SiteService, public myapp: AppService) {

    }

    ngAfterViewInit(): void {
        this.newTitle = this.ch.title;
        if (this.chapters[this.index - 1]) {
            this.previousChapter = this.chapters[this.index - 1];
        }
        if (this.chapters[this.index + 1]) {
            this.nextChapter = this.chapters[this.index + 1];
        }
    }

    selectChapter(ch) {
        this.selectedChapter = ch;
        this.togleTreeItem(ch);
        //  this.selectedChapter = ch;
        console.log('selectChapter', this.treeTogleStatus);

    }

    getChapterChildren(parentId: string): Chapter[] {
        let chaps: Chapter[] = [];
        let index = 0;
        this.site.chapters.forEach(ch => {
            if (ch.parent === parentId) {
                index++;
                chaps.push(ch);
            }
        });
        return chaps;
    }

    togleTreeItem(ch: Chapter) {
        if (!this.treeTogleStatus[ch.uid])
            this.treeTogleStatus[ch.uid] = 1;
        else delete this.treeTogleStatus[ch.uid];
    }

    moveUp(ev) {
        this.site.permutateChapters(this.previousChapter.uid, this.ch.uid, this.ch.display_order, this.previousChapter.display_order);
        console.log('moveUp' + ev);
    }

    moveDown(ev) {
        this.site.permutateChapters(this.nextChapter.uid, this.ch.uid, this.ch.display_order, this.nextChapter.display_order);
        console.log('moveDown' + ev);
    }

    moveDelete(ev) {
        console.log('moveDelete' + ev);
    }

    addChapter(ch: Chapter) {
        // console.log('moveDelete' + ev);
        this.site.addChapter(ch.uid, this.chapters.length + "", this.type);
    }

    saveChapter(ev) {
        this.site.updateChapter(this.ch.uid, this.newTitle);
        this.editMode = false;
    }

    drop(ev) {
        console.log('Drop tree 111:', ev.container.data, ev.item.data, ev);
        this.site.actionSetChapter(ev.item.data, ev.container.data);
    }
}
