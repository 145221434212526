import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import * as Highcharts from 'highcharts';
import PatternFill from "highcharts/modules/pattern-fill";
import Annotations from "highcharts/modules/annotations";
import {SiteService} from "../../shared/services/site.service";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {
    AnnotationsOptions,
    Chart,
    ChartCallbackFunction, DashStyleValue,
    PatternObject,
    PatternOptionsObject,
    SeriesOptionsType,
    SeriesTooltipOptionsObject,
    XrangePointOptionsObject
} from "highcharts";
import {K} from "../../shared/models/K";
import {DomSanitizer} from "@angular/platform-browser";
import {H} from "../../shared/helpers/H";
import {Plot} from "../../shared/models/models";
import {PdmYearlyCalcedData} from "../../shared/models/PdmYearConfig";


if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    //  Exporting(Highcharts);
    //  ExportData(Highcharts);
    Annotations(Highcharts);
    //  Debugger(Highcharts);
}

@Component({
    selector: 'statmens-bars',
    template: `
        <mat-card class=" " fxLayout="row">
            <highcharts-chart [Highcharts]="Highcharts"
                              [options]="chartOptions"
                              [(update)]="updateFlag"
                              (chartInstance)="onChartInstance($event)">
            </highcharts-chart>
            <div>

            </div>
        </mat-card>
    `,
    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensBarsComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
    chart: Chart;
    errors: any[] = [];
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;
    updateFlag: boolean = false;
    previousSerie = [];
    historyCount: number = 5;

    _mainUnit: string = "KWh";
    svg: any;

    constructor(public myapp: AppService,
                public site: SiteService,
                public sanitizer: DomSanitizer) {
        super();
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {

            if (eventName === SiteService.PDMS_YEARLY_PLOT_DATA_CALCED) {
                this.populateData();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.populateData();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_SELECTED) {
                this.populateData();
            }
        });
    }

    ngOnInit() {
        this.chartOptions = K.chartOptions;
        this.chartOptions.chart.width = 1200;
        this.chartOptions.chart.height = 600;
        this.chartOptions.chart.type = 'column';
        // this.chartOptions.xAxis.type = 'category';
        this.chartOptions.title.text = 'Consommation corrigée à 30j';
        this.chartOptions.plotOptions.column.stacking = 'normal';

        this.chartOptions.series = [];
        this.createSeries();
        //this.populateData();
        //this.updateFlag = true;


    }

    replot() {
        console.log("replot() : ", this.chartOptions.series);
        this.populateData();
    }

    createSeries() {
        if (this.chart) {
            this.chart.series = [];
            this.chart.redraw();
        }

        this.chartOptions.xAxis['categories'] = ['', 'jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'].map(i => H.ucfirst(i));
        this.chartOptions.xAxis['title'].text = undefined;
        this.chartOptions.xAxis['type'] = 'category';
        this.chartOptions.yAxis['min'] = 1000;
        this.chartOptions.series = []; //  && this.plot.isMain()
        if (this.site.selectedPdm.getIsHaveMultipleSeries()) {
            for (let i = 0; i < this.historyCount * 2; i++) {
                let SerieNum = 1;
                if (i >= this.historyCount) SerieNum = 2;
                const ser = this.getSerie(i, this.chartOptions.series.length + 1, true, SerieNum, 2);
                this.chartOptions.series.push(ser);
            }
        } else {
            for (let i = 0; i < this.historyCount; i++) {
                const ser = this.getSerie(i, this.chartOptions.series.length + 1, true, 1, 2);
                this.chartOptions.series.push(ser);
            }
        }
    }

    populateData() {
        for (let y = 0; y < this.historyCount; y++) {
            const yearOfCurrentSerie = this.site.year + y + 1 - this.historyCount;
            let data = [];
            this.site.selectedPdmYearlyPlotDataMap.forEach((item, i) => {
                if (item.releve_year === yearOfCurrentSerie)
                    data.push(this.getPointsForBars(item, i, y, 1));
            });
            data = K.months.map((month, i) => {
                if (data[i]) return data[i]; else return {x: i, y: 0, category: H.ucfirst(K.months[i]), custom: ""};
            });
            if (yearOfCurrentSerie === this.site.refYear)
                this.previousSerie = data;

            // console.log("populateData() : ", yearOfCurrentSerie, this.site.refYear, data);
            if (this.chart && this.chart.series && this.chart.series[y])
                this.chart.series[y].setData(data, true);
        }
    }

    getPointsForBars(item: PdmYearlyCalcedData, i, indexOfSerie: number, serieNum = 1) {
        const yearOfCurrentSerie = this.site.year + indexOfSerie + 1 - this.historyCount;

        if (this.site.year === yearOfCurrentSerie) {
            const evol = this.getEvolutiontoRefYear(item);
            return {
                x: item.releve_num, y: item.ve.corr, year: yearOfCurrentSerie, date: item.date,
                category: H.ucfirst(K.months[item.releve_num - 1]),
                custom: evol.percent, name: evol.delta
            };
        } else {
            return {
                x: item.releve_num, y: item.ve.corr, year: yearOfCurrentSerie, date: item.date,
                category: H.ucfirst(K.months[item.releve_num - 1]),
                custom: 0, name: 0
            };
        }

    }

    getEvolutiontoRefYear(item: PdmYearlyCalcedData) {
        const relKey = H.getReleveKey(item.releve_year, item.releve_num);
        const relKeyOfRefYear = Number(H.getReleveKey(this.site.refYear, item.releve_num));
        const calcedItemOfRefYear = this.site.selectedPdmYearlyPlotDataMap.get(relKeyOfRefYear);
        let delta = 0, percent = 0;
        if (calcedItemOfRefYear) {
            delta = item.ve.corr - calcedItemOfRefYear.ve.corr;
            percent = 100 * (delta / calcedItemOfRefYear.ve.corr);
        }
        // console.log("getCalcedDataItemOfRefYear", relKey, ' => ', calcedItemOfRefYear);
        return {delta, percent};
    }

    getSerie(serieIndex: number, serieGlobalIndex: number, showInLegend = true, serieNum = 1, serieTot = 1): SeriesOptionsType {
        let year = this.site.year - this.historyCount + serieIndex + 1;
        let assetIndex = this.historyCount - serieIndex - 1;
        if (serieIndex >= this.historyCount) {
            year -= this.historyCount;
            assetIndex += this.historyCount;
        }

        const s = {
            animation: {
                duration: 600,
                easing: "in"
            },
            shadow: false,
            color: {
                pattern: this.getPattern(assetIndex)
            },
            index: serieGlobalIndex,
            id: year + '_corr_' + serieNum + "-" + serieGlobalIndex,
            showInLegend,
            borderWidth: assetIndex === 4 && this.historyCount === 5 ? 0.5 : 1.5,
            borderColor: assetIndex === 4 && this.historyCount === 5 ? "#000000" : "#ffffff",
            name: year + " ",
            type: 'column',
            tooltip: {
                headerFormat: "{series.name}" + (year === this.site.refYear ? '®' : ''),
                pointFormat: '<br>{point.category}, {point.y:.2f} ' + this._mainUnit,
            },
            data: [],
            stack: year,
            //lineWidth: serieIndex === (this._series.length - 1) && this.plot.cumul ? 3 : 2
        } as SeriesOptionsType;

        // deltas
        if (s.type === "column" || s.type === "line") {
            if (year === this.site.year && serieIndex < 5) {
                s.dataLabels = {
                    useHTML: false,
                    inside: false,
                    enabled: true,
                    rotation: s.type === "column" ? -90 : 0,
                    align: "left",
                    verticalAlign: "top",
                    style: {
                        color: "#000000",
                        backgroundColor: "#ffff00",
                        fontWeight: 'normal'
                    },
                    //x: 15,
                    y: -5,
                    format: "®{point.name:,.1f} " + this._mainUnit + "; <b>{point.custom: .1f}%</b>",
                };
            }
        }
        // s.name = this.getLegendForPlot(year, s.type, false, serieNum, serieTot);

        return s;
    }

    getPattern(index = 0, fill = null, bg = null, scale = 1): PatternOptionsObject {
        const paths = [
            "M0 0 L5 0 L5 5 L0 5 z",
            "M0 0 L5 0 L5 3 L0 3 z",
            "M0 0 L5 0 L5 2 L0 2 z",
            "M0 0 L3 0 L3 3 L0 3 z",
            "M0 5 L100 5 L-100 5 L5 5 L5 -100 L5 100 L5 5",
        ];
        const pattern = {
            color: '#000000',
            aspectRatio: 1,
            width: 5,
            height: 5,
            image: null,
            opacity: 1,
            path: null,
            x: 0,
            y: 0
        } as PatternOptionsObject;

        pattern.path = {
            d: paths[0],
            strokeWidth: 0,
            stroke: "#fff",
            r: 10,
        };
        pattern.color = K.chartColors[index];
        pattern.path.fill = fill ? fill : K.chartColors[index];
        pattern.backgroundColor = bg ? bg : '#FFFFFF';
        const rotates = ['rotate(0)', 'rotate(-45)', 'rotate(0)', 'rotate(45)', 'rotate(20)'];

        if (index === 0) {
            pattern.height = 3;
            pattern.path.d = paths[0];
            pattern.patternTransform = rotates[0];
        }
        if (index === 1) {
            pattern.height = 3;
            pattern.path.d = paths[2];
            pattern.patternTransform = 'rotate(-45)' + ",scale(1.2)";
        }
        if (index === 2) {
            pattern.height = 4;
            pattern.path.d = paths[1];
            pattern.patternTransform = rotates[index] + ",scale(1.2)";
        }
        if (index === 3) {
            pattern.height = 3;
            pattern.path.d = paths[2];
            pattern.patternTransform = rotates[index] + ",scale(1.2)";
        }
        if (index === 4) {
            pattern.x = 0;
            pattern.height = 4;
            pattern.width = 4;
            pattern.path.d = paths[3];
            pattern.patternTransform = "scale(" + scale + ")";
        }
        if (index === 5) {
            pattern.x = 0;
            pattern.height = 9;
            pattern.width = 9;
            pattern.y = 0;
            pattern.backgroundColor = "#ff0000";
            pattern.path.d = paths[4];
            pattern.path.strokeWidth = 1;
            pattern.path.stroke = "#ffffff";
            pattern.patternTransform = "scale(" + scale + ")";
        }

        //   pattern.image='https://seeklogo.com/images/B/Burberry_pattern-logo-361C603AF3-seeklogo.com.png';
        // pattern.image = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSI0OSIgdmlld0JveD0iMCAwIDI4IDQ5Ij48ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJoZXhhZ29ucyIgZmlsbD0iYmx1ZSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMTMuOTkgOS4yNWwxMyA3LjV2MTVsLTEzIDcuNUwxIDMxLjc1di0xNWwxMi45OS03LjV6TTMgMTcuOXYxMi43bDEwLjk5IDYuMzQgMTEtNi4zNVYxNy45bC0xMS02LjM0TDMgMTcuOXpNMCAxNWwxMi45OC03LjVWMGgtMnY2LjM1TDAgMTIuNjl2Mi4zem0wIDE4LjVMMTIuOTggNDF2OGgtMnYtNi44NUwwIDM1Ljgxdi0yLjN6TTE1IDB2Ny41TDI3Ljk5IDE1SDI4di0yLjMxaC0uMDFMMTcgNi4zNVYwaC0yem0wIDQ5di04bDEyLjk5LTcuNUgyOHYyLjMxaC0uMDFMMTcgNDIuMTVWNDloLTJ6Ii8+PC9nPjwvZz48L3N2Zz4=";
        // console.log("Pattern:" + index, pattern, isRef);
        return pattern;
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    ngOnDestroy() {
        // if (this.chart) Highcharts.charts.splice(this.chart.index, 1);
    }

    ngAfterViewInit(): void {
        // console.info('ngAfterViewInit():' + this.plot.plotType);

    }

    onChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }
}
