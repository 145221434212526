<div class="bloc-site-editor flex-container" fxLayout="row" style="min-height: 600px">

    <mat-tab-group animationDuration="0ms" fxFlex (selectedTabChange)="selectTab($event)">
        <mat-tab label="Paramètres de base">
            <div class="p-8" fxLayout="column">
                <form [formGroup]="formSite" *ngIf="formSite">
                    <div fxFlex fxLayout="row">
                        <div fxFlex="50" class="p-8 pr-16 border-right">
                            <div class="bat-name" fxLayout="row">
                                <span fxFlex="80px">BâtID: [{{ site.clientSite.ep_bat_id }}]</span>
                                <span fxFlex
                                      class="color-blue font-weight-bold">{{ site.clientSite.ep_bat_name }}</span>
                            </div>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="ref" placeholder="ref">
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="name" placeholder="Nom de site">
                            </mat-form-field>
                            <mat-form-field class="full-width" *ngIf="globalPdm&&globalPdm.length">
                                <mat-select formControlName="weather" placeholder="Meteo">
                                    <mat-option [value]="pdm" *ngFor="let pdm of globalPdm">{{ pdm }}</mat-option>
                                </mat-select>

                            </mat-form-field>
                            <mat-card *ngIf="!globalPdm.length" class="m-4 p-0">
                                <mat-icon class="color-blue" inline="true">hourglass_top</mat-icon>
                                Chargement des stations météo
                            </mat-card>
                            <mat-form-field class="full-width">
                                <mat-select formControlName="canton" placeholder="Canton">
                                    <mat-option value="ARGOVIE">ARGOVIE</mat-option>
                                    <mat-option value="BASEL">BASEL</mat-option>
                                    <mat-option value="BASEL">BASEL</mat-option>
                                    <mat-option value="BERNE">BERNE</mat-option>
                                    <mat-option value="FRIBOURG">FRIBOURG</mat-option>
                                    <mat-option value="GENEVE">GENEVE</mat-option>
                                    <mat-option value="JURA">JURA</mat-option>
                                    <mat-option value="NEUCHATEL">NEUCHATEL</mat-option>
                                    <mat-option value="VALAIS">VALAIS</mat-option>
                                    <mat-option value="VAUD">VAUD</mat-option>
                                    <mat-option value="ZURICH">ZURICH</mat-option>
                                </mat-select>
                            </mat-form-field>


                            <mat-form-field class="full-width">
                                <input matInput formControlName="address" placeholder="Adresse">
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="npaLoc" placeholder="NPA, Localité">
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <input matInput formControlName="map_url" placeholder="Url map">
                            </mat-form-field>
                            <mat-form-field class="full-width">
                                <textarea matInput formControlName="notes" placeholder="Notes"></textarea>
                            </mat-form-field>


                            <mat-form-field class="full-width">
                                <input matInput formControlName="statmens_prefix" placeholder="Préfixe Statmens">
                            </mat-form-field>

                            <mat-form-field class="full-width prefixed">
                                <mat-select formControlName="ep_db_name" placeholder="Nom bdd">
                                    <mat-option strEnh enh="ucfirst"
                                                [value]="bdd"
                                                *ngFor="let bdd of epLinks;let i=index">
                                        {{ bdd }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full-width prefixed">
                                <mat-select formControlName="releve_day" placeholder="Jour de relevé">
                                    <mat-option strEnh enh="ucfirst"
                                                [value]="day"
                                                *ngFor="let day of myapp.k.weekDays;let i=index">
                                        {{ day }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="full-width">
                                <mat-select formControlName="type_suivi" placeholder="Type de suivi">
                                    <mat-option value="">Pas de suivi</mat-option>
                                    <mat-option *ngFor="let kv of myapp.k.TypeEngagementLabel|keyvalue"
                                                [value]="kv.key">
                                        {{ kv.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                            <mat-checkbox formControlName="gc">Grand consommateur</mat-checkbox>

                        </div>
                        <div class="p-8">

                            <div class="full-width mt-16" fxLayout="column">
                                <h4 fxFlex="100">Configuration des tarifs</h4>
                                <div class="checkbox-group" fxLayout="row">
                                    <mat-form-field fxFlex="50">
                                        <input matInput formControlName="year_tariff_default"
                                               placeholder="Année tarifaire">
                                    </mat-form-field>
                                    <mat-form-field fxFlex="50">
                                        <input matInput formControlName="year_tariff_display"
                                               placeholder="Année tarifaire à afficher">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="full-width mt-16" fxLayout="column" formGroupName="features">
                                <h4 fxFlex="100">Fonctionnalitées</h4>
                                <div class="checkbox-group" fxLayout="row wrap">
                                    <mat-checkbox formControlName="mdg_dashboard" fxFlex="33"> mdg_dashboard
                                    </mat-checkbox>
                                    <mat-checkbox formControlName="mdg_edit" fxFlex="33"> mdg_edit</mat-checkbox>
                                </div>
                            </div>
                            <div class="full-width mt-16" fxLayout="column">
                                <h4 fxFlex="100">Variables explicatives spéciales</h4>
                                <div class="checkbox-group mb-8" fxLayout="row wrap"
                                     *ngIf="site.clientSite&&site.clientSite.vars_extra">
                                    <mat-checkbox [name]="v" fxFlex="33"
                                                  [ngModel]="this.site.clientSite.vars_extra[v]"
                                                  [ngModelOptions]="{standalone: true}"
                                                  *ngFor="let v of extraVars"
                                                  (change)="setExtraVars(v,$event.checked)"> {{ v }}
                                    </mat-checkbox>
                                </div>
                                <h4 fxFlex="100" (dblclick)="site.loadStatmensConfigs();loadBatimentsTempList()">Associer au bâtiment EP</h4>
                                <div>
                                    <div *ngFor="let bat of batimentsForBddMap|keyvalue" fxLayout="row"
                                         class="border-bottom pb-4">
                                        <div fxFlex="50px">ID: [{{ bat.value.batID }}]</div>
                                        <div fxFlex><b class="color-blue">{{ bat.value.batName }}</b></div>
                                        <div fxFlex>{{ bat.value.uid }}</div>
                                        <div fxFlex>
                                            <button (click)="linkToEpBat(bat.value)" mat-raised-button color="primary"
                                                    class="btn-xs-25">Associer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div fxLayout="row" class="">
                    <div style="flex-grow: 1"></div>
                    <button fxFlex="150px" mat-raised-button color="primary" (click)="saveSite()">Sauvegarder</button>
                </div>

            </div>
        </mat-tab>

        <mat-tab label="Compteurs et relevés">
            <app-bmens-meters></app-bmens-meters>
        </mat-tab>

        <mat-tab label="Données énergetiques">
            <bloc-energy-stats></bloc-energy-stats>
        </mat-tab>

        <mat-tab label="Dates de relevés">
            <div fxLayout="row" class="full-width">
                <div class="p-8 " fxLayout="column" fxFlex="915px">
                    <mat-card class="m-0 p-8">
                        <button *ngFor="let y of yearsForButtons" class="ml-4 btn-xs-25" mat-raised-button
                                color="primary"
                                [ngClass]="{active:y===site.year}"
                                (click)="selectYearForDelays(y)">{{ y }}
                        </button>
                    </mat-card>
                    <div class="calandar-table">
                        <div fxLayout="row" class="header-row">
                            <div fxFlex="5">Mois</div>
                            <div fxFlex="22">Dernerer sem. <b>[Dist.]</b></div>
                            <div fxFlex="22">Prem. Sem. <b>[dist.]</b></div>
                            <div fxFlex="15">Fin du mois</div>
                            <div fxFlex>Gagnant</div>
                            <div fxFlex="120px"></div>
                            <div fxFlex="50px"></div>
                        </div>
                        <mat-card *ngIf="!site.clientSite.releve_day" style="border: 5px solid red">
                            <h2 style="color: red">Vous devez definir un jour de relevé</h2>
                        </mat-card>

                        <ng-container *ngFor="let m of months;let i=index">
                            <div fxLayout="row" class="data-row" *ngIf="calendarItemsforYear[i] as calItem"
                                 fxLayoutAlign="space-between center">
                                <div fxFlex="5">{{ calItem.month }}</div>
                                <div fxFlex="22" [ngClass]="{active:calItem.date1===calItem.winner}">
                                    {{ calItem.date1 }} <b>[{{ calItem.distOfD1 }}]</b></div>
                                <div fxFlex="22" [ngClass]="{active:calItem.date2===calItem.winner}">
                                    {{ calItem.date2 }} <b>[{{ calItem.distOfD2 }}]</b></div>
                                <div fxFlex="15">{{ calItem.endOfMonth }}</div>
                                <div fxFlex>{{ calItem.winner }}</div>
                                <div fxFlex="120px" class="no-border">
                                    <input matInput #delay type="text"
                                           [value]="getVal(i)"
                                           placeholder="YYYY-MM-DD">
                                </div>
                                <div fxFlex="50px">
                                    <button fxFlex="200px"
                                            mat-raised-button color="default"
                                            class="btn-xs-25"
                                            (click)="saveReveleDelay(delay,i+1 ,calItem.winner)">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        year: {{ site.year }}
                    </div>
                </div>
                <div fxFlex class="p-8">
                    <mat-card class="m-0 mb-8 p-8" fxLayout="row" fxLayoutAlign="space-between center">
                        <h3 fxFlex>Dates de relevés finaux:</h3>
                        <button class="ml-4 btn-xs-25" mat-raised-button color="primary">Del all</button>
                    </mat-card>
                    <ng-container *ngFor="let item of site.siteCalendar">
                        <mat-card *ngIf="item.relatedId.includes(site.year)" class="p-4 m-0 mb-8" fxLayout="row">
                            <span fxFlex="180px">Relevé Num: {{ item.numReleve }} {{ item.relatedId }}</span>
                            <b class="color-blue" *ngIf="site.releveDelaysMap.has(item.relatedId)">
                                {{ site.releveDelaysMap.get(item.relatedId).date }}
                                <button (click)="deleteReleveDelay(item.relatedId)">X</button>
                            </b>
                            <b *ngIf="!site.releveDelaysMap.has(item.relatedId)">{{ item.winner }}</b>
                        </mat-card>
                    </ng-container>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="Gestion des lieux">
            <div class="place-item"
                 *ngFor="let place of site.clientSite.places | keyvalue">
                <div fxFlex fxLayout="row">
                        <span *ngIf="editPlaceMode!==place.key">
                            {{ place.value.label }}
                        </span>

                    <mat-form-field *ngIf="editPlaceMode===place.key" appearance="legacy" fxFlex="300px">
                        <input matInput placeholder="Emplacement libellé"
                               [(ngModel)]="site.clientSite.places[place.key].label"
                               (keyup.enter)="savePlace()">
                    </mat-form-field>
                </div>
                <div>
                    <mat-icon (click)="savePlace(false,place.key)" class="cursor-pointer" matListIcon>delete</mat-icon>
                    <mat-icon (click)="editPlaceMode=place.key" class="cursor-pointer" matListIcon>edit</mat-icon>
                </div>
            </div>
            <div fxLayout="row" class="">
                <div style="flex-grow: 1"></div>
                <button fxFlex="200px" mat-raised-button color="default" class="btn-xs-25"
                        (click)="savePlace(true)">Ajouter emplacement
                </button>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>
