<router-outlet></router-outlet>
<div *ngIf="showButPanel" class="p-16 h-full">
    <h1>Chargement....</h1>

    <button mat-raised-button (click)="logout()">
        Recommencer la procédure de connexion
    </button>
    <!--
    <button mat-raised-button (click)="myapp.showConsole(myapp.router.url,myapp.user)">
        Log
    </button>
    -->
    <button mat-raised-button class="ml-8" (click)="myapp.sendErrorReport()">Envoyer rapport d'erreur</button>
</div>

<div class="overlay-drop-shadow" *ngIf="pdfSource" fxLayout="column" fxLayoutAlign="start center">
    <mat-card class=" popup-fixed" fxFlex="95" style="width: 80%">
        <div fxLayout>
            <div fxFlex>
                {{ title }}
            </div>
            <button mat-icon-button (click)="pdfSource=null">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <ngx-extended-pdf-viewer *ngIf="pdfSource" useBrowserLocale="true"
                                 [height]="'99%'"
                                 [src]="pdfSource"
                                 [textLayer]="true"
                                 [zoom]="'page-width'"
                                 [showHandToolButton]="true"
                                 [enablePrint]="true">
        </ngx-extended-pdf-viewer>
    </mat-card>
</div>
