import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {BaseComponent} from "../../../shared/BaseComponent";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {PdmYearConfig} from "../../../shared/models/PdmYearConfig";
import {Pdm} from "../../../shared/models/Pdm";

@Component({
    selector: 'row-item',
    template: `
        <div class="bloc-pdm-calc" [ngClass]="{active:selectedDataItem&&selectedDataItem.uid===dataItem.uid}" fxLayout="column">
            <div class="full-width row-values" fxLayout="row">
                <div [fxFlex]="colWidth[0]" fxLayout="row" fxFlexAlign="start center ">
                    <icon-fluid [fluid]="dataItem.fluid" class="mr-4"></icon-fluid>
                    <span *ngIf="parentPdm&&!parentPdm.is_vpdm">
                        {{parentPdm.label_bilan}}
                    </span>
                    <span *ngIf="parentPdm&&parentPdm.is_vpdm">
                        {{dataItem.fluid}}
                    </span>
                    ({{site.clientSite.getPlaceById(parentPdm.uid_place)}})
                </div>
                <div [fxFlex]="colWidth[1]">{{col1|number:'2.0-0'}} MWh {{col2|number:'1.1-1'}} %</div>
                <div [fxFlex]="colWidth[2]">{{col3|number:'1.0'}} HTVA</div>
                <div [fxFlex]="colWidth[3]">{{col4|number:'1.0-0'}} MWh</div>
                <div [fxFlex]="colWidth[4]">{{col5|number:'1.0-0'}} Fr.</div>
                <div [fxFlex]="colWidth[5]">{{col6|number}}</div>
                <div [fxFlex]="colWidth[6]">{{month + 1|number:'2.0-0'}}.{{year}}/{{yearRef}}</div>
                <div fxFlex fxLayout="row">
                    <div fxFlex=""></div>
                    <button class="btn-xs-25 mr-8" mat-raised-button [matBadge]="commentsStats['pending']"
                            (click)="callback.emit({dataItem,action:'show'})"
                            matBadgePosition="after" matBadgeColor="accent" matBadgeSize="medium" matBadgeOverlap="true">
                        <mat-icon class="color-blue" [inline]="true">leaderboard</mat-icon>
                        Selectionner
                    </button>
                </div>
            </div>

        </div>
    `,
    styleUrls: ['./mensuel.component.scss']
})
export class RowItemComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('commentsStats') commentsStats: any;
    @Input('year') year: number;
    @Input('yearRef') yearRef: number;
    @Input('month') month: number;
    @Input('colWidth') colWidth: string[];
    @Input('selectedDataItem') selectedDataItem: PdmYearConfig;
    @Input('dataItem') dataItem: PdmYearConfig;
    @Output('callback') callback: EventEmitter<any> = new EventEmitter<any>();

    predictedValue = -1;
    realCons = -1;
    realCost = -1;
    count: number = 0;
    parentPdm: Pdm;

    constructor(public myapp: AppService, public site: SiteService) {
        super();
    }

    //db.getCollection('conventions').find({_id:ObjectId('624ea10192401900047dadeb')})
    get col1(): number {
        return Number(this.realCons - this.predictedValue);
    }

    get col2(): number {
        if (this.predictedValue > 0) {
            return Number(100 * (this.col1 / this.predictedValue));
        }
        return 0;
    }

    get col3() {
        return -1;
    }

    get col4() {
        return this.realCons;
    }

    get col5() {
        return this.realCost;
    }

    get col6() {
        return this.realCost;
    }

    /*









     */
    ngOnChanges(changes: SimpleChanges) {
        this.populateCols();
    }

    populateCols() {
        this.predictedValue = this.dataItem.predicted_cons ? this.dataItem.predicted_cons[this.month] : 0;
        this.realCons = this.dataItem.real_cons ? this.dataItem.real_cons[this.month] : 0;
        this.realCost = this.dataItem.real_cost ? this.dataItem.real_cost[this.month] : 0;
        this.parentPdm = this.site.pdms.filter(item => item.uid === this.dataItem.uid_pdm).at(0);
    }

    ngOnInit() {
        this.populateCols();
        console.log("RowItemComponent:init", this.month);
    }

    ngAfterViewInit(): void {

    }
}
