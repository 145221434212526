import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {H} from "../../../shared/helpers/H";
import moment from "moment";
import {interval, Subscription} from "rxjs";
import {ReportSuivi, SuiviDocument} from 'app/shared/models/Suivi.model';

@Component({
    selector: 'suivi-documents',
    template: `
        <div class="component-holder relative" *ngIf="report&&reportRef">
            <p [title]="isRef">
                <mat-icon inline>fiber_manual_record</mat-icon>
                Informations complémentaires et remarques
            </p>


            <mark-down *ngIf="isRef" [content]="this.reportRef.notes" [report]="reportRef" field="notes"></mark-down>

            <!--
            <mark-down *ngIf="report.note_factors" [content]="this.report.note_factors" [report]="report" field="note_factors">
            </mark-down>
            
            -->
            <mark-down *ngIf="!isRef" [content]="this.report.note_goals" [report]="report" field="note_goals"></mark-down>
            <mark-down *ngIf="!isRef" [content]="this.report.note_previsions" [report]="report" field="note_previsions"></mark-down>
            <mark-down *ngIf="!isRef" [content]="this.report.note_updates" [report]="report" field="note_updates"></mark-down>

            <!-- docs -->
            <ng-container *ngFor="let docs of tablesToDisplay">
                <h2 *ngIf="docs===docsTechnical" class="color-blue">
                    Schéma de comptage et liste des compteurs</h2>
                <h2 *ngIf="docs===docsAppendix" class="color-blue mt-32">
                    Annexes</h2>
                <table class="table-actions">
                    <tr class="table-actions-head">
                        <td></td>
                        <td>Nom du fichier</td>
                        <td>Auteur</td>
                        <td>Type</td>
                        <td>Taille</td>
                        <td>Mise à jour</td>
                        <td></td>
                    </tr>
                    <ng-container *ngFor="let docUidSection of docs">
                        <ng-container *ngIf="site.documentsMap.get(docUidSection.uid) as fullDoc">
                            <tr class="table-actions-row">
                                <td class="icon" style="width: 30px">
                                    <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline
                                              *ngIf="fullDoc.storage_metas.contentType==='application/pdf'">picture_as_pdf
                                    </mat-icon>
                                </td>
                                <td class="col1 cursor-pointer" style="width: auto" (click)="selectDoc(fullDoc)">
                                    {{ fullDoc.title }}
                                </td>
                                <td style="width: 100px">{{ fullDoc.user_metas.firstName }} {{ fullDoc.user_metas.lastName }}</td>
                                <td style="width: 100px">{{ fullDoc.storage_metas.contentType }}</td>
                                <td style="width: 75px">{{ fileSize(fullDoc.storage_metas.size) }}</td>
                                <td style="width: 150px">{{ fullDoc.storage_metas.updated|amDateFormat:'lll' }}</td>
                                <td style="width: 30px">
                                    <mat-icon
                                            (click)="removeDoc(docUidSection)"
                                            style="font-size: 20px;margin: -10px 0 -10px 5px;color: #000" inline>delete
                                    </mat-icon>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>

                    <tr class="table-actions-footer noprint">
                        <ng-container *ngIf="docs===docsTechnical">
                            <td colspan="7" (click)="showDocumentsChooser('TECHNICAL')">
                                <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline>add_box</mat-icon>
                                Ajouter
                            </td>
                        </ng-container>
                        <ng-container *ngIf="docs===docsAppendix">
                            <td colspan="7" (click)="showDocumentsChooser('APPENDIX')">
                                <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline>add_box</mat-icon>
                                Ajouter
                            </td>
                        </ng-container>
                    </tr>
                </table>
            </ng-container>

            <ng-container *ngFor="let r of allPlatformReports">
                <a [href]="r.oldUrl" target="_blank">Open {{ r.year }}</a>
                <mark-down *ngIf="r&&r.isRef" [content]="r.notes" [report]="r" field="notes"></mark-down>
                <mark-down *ngIf="r&&r.note_factors" [content]="r.note_factors" [report]="r" field="note_factors"></mark-down>
                <mark-down *ngIf="r&&!r.isRef&&r.year!==this.reportRef.year" [content]="r.note_goals" [report]="r" field="note_goals"></mark-down>
                <mark-down *ngIf="r&&!r.isRef&&r.year!==this.reportRef.year" [content]="r.note_previsions" [report]="r"
                           field="note_previsions"></mark-down>
                <mark-down *ngIf="r&&!r.isRef&&r.year!==this.reportRef.year" [content]="r.note_updates" [report]="r" field="note_updates"></mark-down>
            </ng-container>

            <div class="document-overlay" *ngIf="showDocumentOverlay">
                <div class="document-scroller-header" fxLayout="row">
                    <h2 fxFlex>Choisir le document à ajouter</h2>
                    <button class="btn-xs-25 mr-4" mat-raised-button (click)="sortDocs('alpha')">Tri alpha</button>
                    <button class="btn-xs-25 mr-8" mat-raised-button (click)="sortDocs('newest')">Tri + récents</button>
                    <mat-icon (click)="showDocumentOverlay=false">close</mat-icon>
                </div>
                <div class="document-scroller">
                    <ul>
                        <li *ngFor="let doc of site.documents" (click)="myapp.showConsole(doc)" fxLayout="row">
                            <button fxFlex="100px" mat-raised-button class="btn-xs-25 mr-4" (click)="selectDocument(doc)">Choisir</button>
                            <span fxFlex>{{ doc.title }} </span>
                            <span class="p-4">{{ doc.dateAddedStr }} </span>
                            <span class="p-4">{{ doc.dateStr }} </span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviDocumentsComponent implements OnInit, OnChanges {
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('report') report: ReportSuivi;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();
    /*
    mapFieldToMDField = {
        notes: "notes_md",
        note_factors: "notes_factors_md",
        note_goals: "notes_goals_md",
        note_previsions: "notes_previsions_md",
        note_updates: "notes_updates_md",
    };

    attachmentType: string;
    author: string;
    fullPath: string;
    section: string;//APPENDIX TECHNICAL
    title: string;
    uid: string;
    user: UserMini;
     */
    docsTechnical: SuiviDocument[] = [];
    docsAppendix: SuiviDocument[] = [];
    tablesToDisplay = [];

    documents: SuiviDocument[];
    allPlatformReports = [];
    showDocumentOverlay = false;
    selectedSectionForUpload = '';
    fillingStatus = {
        notes_md: 0,
        notes_factors_md: 0,
        notes_goals_md: 0,
        notes_previsions_md: 0,
        notes_updates_md: 0,
    };
    subscriptionInterval: Subscription;

    get isRef() {
        return this.reportRef && this.report && this.report.type === 'HIDDEN_REF_DATA';
    }

    constructor(public myapp: AppService, public site: SiteService) {
    }

    ngOnInit() {
        this.site.siteLoadingStatus.subscribe(loadingLevel => {
            if (loadingLevel === SiteService.MAX_SITE_LOADING_STATUS) {
                this.loadDocs();
            }
        });
        moment.locale('fr');
    }

    fileSize(size: number) {
        const inKb = size / 1024;
        const inMb = inKb / 1024;
        if (inMb > 1) return inMb.toFixed(1) + " Mo";
        if (inKb > 1) return inKb.toFixed(1) + " Ko";
    }

    loadDocs() {
        this.docsTechnical = [];
        this.docsAppendix = [];
        let docsToScan = this.report.documents;

        docsToScan.forEach(doc => {
            const _doc = new SuiviDocument(doc);
            if (doc.section === 'TECHNICAL')
                this.docsTechnical.push(_doc);
            if (doc.section === 'APPENDIX')
                this.docsAppendix.push(_doc);
        });
        if (this.isRef) this.tablesToDisplay = [this.docsTechnical, this.docsAppendix];
        else this.tablesToDisplay = [this.docsAppendix];
        //console.log("loadDocs()", this.isRef, docsToScan, this.tablesToDisplay, this.docsTechnical, this.docsAppendix);
    }

    selectDoc(doc) {
        console.log("selectDoc", doc);
        let pdfSource;
        doc._storageFileSubscription.subscribe(u => {
            console.log(u);
            pdfSource = "https://api.optimigration.ch/endpoint/cors?f=" + H.utf8_to_b64(u);
            this.myapp.globalDocumentViewer.next({title: "Titre", pdfSource, metas: doc.storage_metas});
        });
    }

    removeDoc(doc) {
        this.report.documents = this.report.documents.filter(d => d.uid !== doc.uid);
        this.saveSuivi(this.report);
        /*
        if (this.isRef) {
            this.reportRef.documents = this.reportRef.documents.filter(d => d.uid !== doc.uid);
            this.saveSuivi(this.reportRef);
        } else {
            this.report.documents = this.report.documents.filter(d => d.uid !== doc.uid);
            this.saveSuivi(this.report);
        }*/

        //console.log('removeDoc', this.report.documents, this.reportRef.documents);
        this.loadDocs();
    }

    sortDocs(typeSort: string) {
        if (typeSort === 'newest') {
            this.site.documents = this.site.documents.sort((a, b) => b.ts - a.ts);
        }
        if (typeSort === 'alpha') {
            this.site.documents = this.site.documents.sort((a, b) => a.title.localeCompare(b.title));
        }
    }

    selectDocument(doc) {
        console.log('selectDocument:' + doc.mime_type, this.selectedSectionForUpload, doc);
        if (!doc.storage_metas.contentType.toLowerCase().includes('pdf')) {
            this.myapp.showError("Ajouter uniquement des fichiers PDF SVP !");
            return;
        }
        const docExist = this.report.documents.some(it => it.uid === doc.uid);
        if (!docExist) {
            const suiviDoc = new SuiviDocument(
                {
                    section: this.selectedSectionForUpload,
                    uid: doc.uid,
                    full_path: doc.full_path
                });
            this.report.documents.push(suiviDoc);
            this.saveSuivi(this.report);
            this.showDocumentOverlay = false;
            this.loadDocs();
        } else {
            this.myapp.showError("Document déja ajouté !");
        }

        /*
        if (this.isRef) {
            this.reportRef.documents.push(suiviDoc);
            this.saveSuivi(this.reportRef);
        } else {
            this.report.documents.push(suiviDoc);
            this.saveSuivi(this.report);
        }*/

    }

    saveSuivi(suivi: ReportSuivi) {
        const dataToDave = {};
        dataToDave['documents'] = suivi.documents;
        this.site.api.saveSuivi(this.report, dataToDave)
            .subscribe(resp => {
                if (resp.status == 1) {
                    this.myapp.showMessage("Document ajouté");
                }
                console.log("saveSuivi ", suivi, resp);
            });
    }

    showDocumentsChooser(sectionName) {
        this.selectedSectionForUpload = sectionName;
        this.showDocumentOverlay = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadDocs();
    }

}
