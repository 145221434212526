import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {ReportSuivi} from 'app/shared/models/Suivi.model';
import {SiteTariffData} from "../../../shared/models/models";

@Component({
    selector: 'suivi-energy',
    template: `
        <div class="component-holder">
            <h2 class="color-blue"> {{ title }}</h2>
            <p>
                <mat-icon inline>fiber_manual_record</mat-icon>
                {{ intro }}
            </p>
            <div title="Tableau récapitulatif" *ngIf="rows&&rows.length">
                <table class="energy-table" (click)="myapp.showConsole(rows)">
                    <tr class="table-energy-row-head">
                        <td style="width: 150px" class="table-col1">Agent énergétique</td>
                        <td style="width: 100px">Quantité</td>
                        <td style="width: 120px" *ngIf="!isProduction">Coûts</td>
                        <td style="width: 40px" *ngIf="isProduction">Rendement Moy.</td>
                        <td style="width: 100px" *ngIf="isProduction">Part d’autoconsommation</td>
                        <td style="width: 200px" *ngIf="isUsage">Facteurs d'émission CO2eq.*</td>
                        <td style="width: 200px" *ngIf="isUsage">Tonnes de CO2eq. émis</td>
                        <td style="width: 150px" *ngIf="isUsage">Fact. de Pondération*</td>
                        <td style="width: 100px" *ngIf="!isProduction">Prix unitaire</td>
                    </tr>
                    <ng-container *ngIf="isProduction">
                        <tr class="table-row" *ngFor="let row of rows">
                            <td class="table-col1" style="width: auto">{{ row._tariff.label }}</td>
                            <td>{{ row.prod|number }}  <span>{{ row.unitStr }}</span></td>
                            <td>{{ row.efficiency * 100|number }}  <span class="short">%</span></td>
                            <td>{{ (row.energy / row.prod) * 100|number:'1.1-2' }}  <span class="short">%</span></td>
                        </tr>
                    </ng-container>

                    <ng-container *ngIf="!isProduction">
                        <ng-container *ngFor="let row of rows">
                            <tr class="table-row" *ngIf="row._tariff&&!row._tariff.hidden">
                                <td class="table-col1">{{ row._tariff.label }}</td>
                                <td>{{ row.energyAdapted|number:'1.0-0' }}  <span>{{ row.unitStr }}</span></td>
                                <td>{{ row.cost|number }} <span style="min-width: 10px">Fr.</span></td>
                                <td *ngIf="isUsage">{{ row.ceges }} <span>kg CO2/MWh</span></td>
                                <td *ngIf="isUsage">{{ row.cegesEmitted|number }}  <span>tCO2eq.</span></td>
                                <td *ngIf="isUsage">{{ row.fp }}</td>
                                <td>{{ row.priceAdapted|number:'1.1-2' }}  <span>{{ row._tariff.priceUnitStr }}</span></td>
                            </tr>
                        </ng-container>
                    </ng-container>

                </table>
            </div>
            <p class="text-muted" *ngIf="!rows||rows.length===0">
                Cette section est vide pour le moment...
            </p>

        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviEnergyComponent implements OnInit, OnChanges {
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('report') report: ReportSuivi;
    @Input('type') type: string;
    rows: SiteTariffData[] = [];
    year: number;
    title: string = "Consommation d'energie";
    intro: string = "Quantité d'énergie consommée durant l'année";

    constructor(public myapp: AppService, public site: SiteService) {
    }

    get isUsage() {
        return this.type === 'CONSOMMATION';
    }

    get isProduction() {
        return this.type === 'PRODUCTION';
    }

    ngOnInit() {
        this.site.siteLoadingStatus.subscribe(level => {
            if (level === SiteService.MAX_SITE_LOADING_STATUS) {
                this.bootstrap();
            }
        });
    }

    // check commit  with message 'VERSION MIGRATION' to see version of this component related to Pdm
    bootstrap() {
        //  console.log("SuiviEnergyComponent:bootstrap: metrics", this.site.metricsMap);
        if (this.reportRef) {
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        this.year = this.report.year;
        //console.log('ngOnChanges():', changes);
        //   this.bootstrap();
        this.setTexts();
    }

    setTexts() {
        if (this.type === "ACHAT") {
            this.title = "Achat d’énergie";
            this.intro = "Quantité d'énergie achetée durant l'année";
            this.rows = Array.from(this.site.clientSite.purchase_map.values())
                .filter(it => it.year === this.report.year)
                .map(it => {
                    it._tariff = this.site.clientSite.tariff_config_map.get(it.uid_tarif);
                    if (!it._tariff) {
                        this.myapp.showError("Tarif orphelin pour " + it.unit);
                    }
                    return it;
                });
        }
        if (this.type === "CONSOMMATION") {
            this.title = "Consommations d'énergie";
            this.intro = "Quantité d'énergie consommée durant l'année";
            this.rows = Array.from(this.site.clientSite.usage_map.values())
                .filter(it => it.year === this.report.year)
                .map(it => {
                    it._tariff = this.site.clientSite.tariff_config_map.get(it.uid_tarif);
                    return it;
                });
        }
        if (this.type === "PRODUCTION") {
            this.title = "Production d’énergie";
            this.intro = "Quantité d'énergie produite durant l'année";
            this.rows = Array.from(this.site.clientSite.production_map.values())
                .filter(it => it.year === this.report.year)
                .map(it => {
                    it._tariff = this.site.clientSite.tariff_config_map.get(it.uid_tarif);
                    return it;
                });
        }
    }

}
