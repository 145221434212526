import {Optimise} from "../helpers/Optimise";

export class PdmRegDef {
    public static DIRECTION_IMPORT = 'IMPORT';
    public static DIRECTION_EXPORT = 'EXPORT';

    public static REG_TYPE_DATA_STD = 'DATA_STD';
    public static REG_TYPE_DATA_COST = 'DATA_COST';
    public static REG_TYPE_VAR = 'VAR';
    public static REG_TYPE_SYNCED = 'SYNCED';

    public static TYPE_T_NA = 'NA';
    public static TYPE_T_NT = 'NT';
    public static TYPE_T_HT = 'HT';
    public static TYPE_T_BT = 'BT';
    public static TYPE_T_FT = 'FT';

    // public static TYPE_D_NA = 'NA';
    public static TYPE_D_ENERGY = 'ENERGY';
    public static TYPE_D_REAC = 'REAC';
    public static TYPE_D_POINTE = 'POINTE';
    public static TYPE_D_VAR = 'VAR';

    public static TYPE_C_NA = 'NA';                       // ENERGY ONLY
    public static TYPE_C_EO = 'EO';                       // ENERGY ONLY
    public static TYPE_C_DO = 'DO';                       // DAY ONLY
    public static TYPE_C_ED = 'ED';                       // ENERGY AND DAY
    public static TYPE_C_MN = 'MN';                       // MANUAL
    public static TYPE_C_MC = 'MC';                       // MACRO

    public static TYPES_OF_D = [
        PdmRegDef.TYPE_D_ENERGY,
        PdmRegDef.TYPE_D_REAC,
        PdmRegDef.TYPE_D_POINTE,
    ];

    public static REG_TYPES = [
        'DATA_STD',
        'DATA_COST',
        'VAR',
        'SYNCED',
    ];

    /*
    public static REG_SUB_TYPES_DATA_STD = [
        'STD_ENERGY',
        'STD_ENERGY_TRANSPORT',
        'STD_ENERGY_PROD',
        'STD_POINTE_REAL',
        'STD_POINTE_FACT',
        'STD_REAC_CONS',
        'STD_REAC_INDEX',
    ];
    public static REG_SUB_TYPES_DATA_COST = [
        'COST_DATA_ONLY',
        'COST_DAY_ONLY',
        'COST_DATA_DAY',
        'COST_FINAL',
        'COST_MANUAL',
        'COST_MACRO',
    ];
    public static REG_SUB_TYPES_VAR = [
        'VAR_EXPL',
        'VAR_INFO',
    ];
    public static REG_SUB_TYPES_SYNCED = [
        'FOREIGN_REG',
        'FOREIGN_TOTS',
        'FULL_MACRO',
    ];
    */

    uid: string;
    uid_pdm: string;
    num: number;
    disp_order: number; // calc
    uid_site: string;
    uid_client: string;

    fluid: string;//cached from pdm on create
    agent: string;
    label: string; // => change to label
    type: string; // change to type

    calced_field: string;
    visible_fields: string[] = [];
    fields_types = {d: '', t: '', c: ''};
    auto_entry = {i: '', d: '', t: '', c: ''};

    sync_config: PdmRegSyncConfig;    // Macro for D and C

    is_derniere_cons: boolean;
    is_derniere_cost: boolean;
    is_ctot_paid: boolean;
    is_import: boolean; // DIRECTION_* constant
    is_transport: boolean;
    is_econ_target: boolean;

    ts_created: number;
    ts_updated: number;

    hash_d?: string;
    hash_c?: string;

    cloned_from?: string;

    get d_type() {
        return this.fields_types.d;
    }

    set d_type(v: string) {
        console.log("set d_type", this.fields_types, v);
        this.fields_types.d = v;
    }

    get t_type() {
        return this.fields_types['t'];
    }

    set t_type(v: string) {
        this.fields_types.t = v;
    }

    get c_type() {
        return this.fields_types['c'];
    }

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
            if (key.includes("is_") && this[key] !== undefined) {
                if (Number(this[key]) === 1) this[key] = true;
                if (Number(this[key]) === 0) this[key] = false;
            }
        });
        if (!this.fields_types) this.fields_types = {d: '', t: '', c: ''};
        if (!this.visible_fields) this.visible_fields = [];
        this.hash_c = this.getTypeHash('c');
        this.hash_d = this.getTypeHash('d');
    }

    getTypeHash(target: string = 'd') {
        let retval = '';
        let importPrefix = (this.is_import ? "Imp" : "Exp");
        if (target === 'd') {
            if (this.is_derniere_cons) {
                retval = target + "Tot" + importPrefix;// && this.d_type !== PdmRegDef.TYPE_D_NA
                if (this.d_type)
                    retval += this.getEnergyHash();
                if (this.t_type && this.t_type !== PdmRegDef.TYPE_T_NA)
                    retval += this.getTariffHash();
                //if (this.c_type !== PdmRegDef.TYPE_D_NA || !this.c_type)  retval += "C" + this.c_type.toLowerCase();
            } else retval = "NotVarExplD";
        }
        if (target === 'c') {
            if (this.is_derniere_cost) {
                retval = target + "Tot" + importPrefix;//&& this.d_type !== PdmRegDef.TYPE_D_NA
                if (this.d_type) {
                    retval += this.getEnergyHash();
                }
                if (this.t_type && this.t_type !== PdmRegDef.TYPE_T_NA) {
                    retval += this.getTariffHash();
                }
                // if (this.c_type !== PdmRegDef.TYPE_D_NA || !this.c_type) retval += "C" + this.c_type.toLowerCase();
            } else retval = "NotVarExplC";
        }
        return retval;
    }

    getEnergyHash() {
        let d_hash = "";
        // if (this.d_type === PdmRegDef.TYPE_D_NA) d_hash = "x";
        if (this.d_type === PdmRegDef.TYPE_D_ENERGY) d_hash = "e";
        if (this.d_type === PdmRegDef.TYPE_D_POINTE) d_hash = "p";
        if (this.d_type === PdmRegDef.TYPE_D_REAC) d_hash = "r";
        return "D" + d_hash;
    }

    getTariffHash() {
        let t_hash = "";
        if (this.t_type === PdmRegDef.TYPE_T_NT) t_hash = "nn";
        if (this.t_type === PdmRegDef.TYPE_T_HT) t_hash = "hi";
        if (this.t_type === PdmRegDef.TYPE_T_BT) t_hash = "lo";
        if (this.t_type === PdmRegDef.TYPE_T_FT) {
            if (this.c_type === PdmRegDef.TYPE_C_MN) t_hash = "fmn";
            if (this.c_type === PdmRegDef.TYPE_C_EO) t_hash = "feo";
            if (this.c_type === PdmRegDef.TYPE_C_ED) t_hash = "fed";
            if (this.c_type === PdmRegDef.TYPE_C_DO) t_hash = "fdo";
        }
        return t_hash === '' ? 'Terr' : "T" + t_hash;
    }

    getMacro(f: string) {
        return this[f + "_conf"] && this[f + "_conf"].macro ? this[f + "_conf"].macro : '';
    }

    getEntryType(field: string): string {
        if (this.type === 'SYNCED') {
            return 'N/A';
        }
        if (field === 'k' || field === 'a') {
            return "MANUAL";
        }
        if (field === 'd' || field === 't' || field === 'c' || field === 'i') {
            const conf = this[field + '_conf'] as PdmFieldConfig;
            if (conf && conf.data_entry) {
                return conf.data_entry
                    .replace("CALC_", "")
                    .replace("ENTRY_", "");
            }
        } else return "Err";
    }

    isVisible(field: string) {
        return Array.isArray(this.visible_fields) && this.visible_fields.includes(field);
    }

    public shouldShow(f) {
        let retVal = false;

        if (f === "i" && this.d_type === PdmRegDef.TYPE_D_ENERGY
            && this.isVisible(f)) retVal = true;


        if (f === "d") {
            if (this.type === "VAR")
                retVal = true;
            else {
                return this.d_type === PdmRegDef.TYPE_D_ENERGY;
            }
        }
        if (this.type === "VAR") {
            if (f === "t") retVal = false;// Alway visible for now
            if (f === "c") retVal = false
            if (f === "k") retVal = false
            if (f === 'a') retVal = false;
        } else {
            if (f === "t") retVal = true;// Alway visible for now
            if (f === "c") retVal = true;// Alway visible for now
            if (f === "k" && this.isVisible(f)) retVal = true;
            if (f === 'a' && this.isVisible(f)) retVal = true;
        }

        return retVal;
    }

    public isManualEntry(f) {
        // manual entry is forbidden only when field is calculated
        return this.getEntryType(f) === "MANUAL";
    }

}

export class PdmRegSyncConfig {
    public static SYNC_REG = 'reg';
    public static SYNC_TOT = 'tot';
    sync_type: string;
    uid_pdm: string;
    reg_num?: number;
    tot_type?: string;
    tot_label?: string;
    reg_label?: string;
    pdm_prefix: string;
    pdm_label: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        //console.log("PdmRegSyncConfig::NEW()", dynProps, this);
    }

    toStr() {
        return this.sync_type + "|" + this.uid_pdm + "|" + (this.reg_num || this.tot_type);
    }
}

export class PdmFieldConfig {
    type: string; // energie,pointe,reac,facteur
    conv_fact: number; // facteur de multiplication pour atteindre l'unité fixée par la convention du système
    display: string;
    data_entry: string; // ENTRY_MANUAL, CALC, MACRO, EPLINK
    ep_link_key?: number;
    // db_name?: number;
    // ep_link_col?: string;
    macro?: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        //console.log("constructore:", dynProps, this);
    }
}
