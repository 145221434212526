import {Routes} from "@angular/router";
import {AdminLayoutComponent} from "./shared/components/layouts/admin-layout/admin-layout.component";
import {AuthLayoutComponent} from "./shared/components/layouts/auth-layout/auth-layout.component";
import {AuthGuard} from "./shared/guards/auth.guard";
import {ProtoHebdoComponent} from './views/proto-hebdo/proto-hebdo.component';
import {SiteSelectComponent} from "./views/site-select/site-select.component";
import {ActionsComponent} from "./views/actions/actions.component";
import {DocumentsComponent} from "./views/documents/documents.component";
import {SiteHomeComponent} from "./views/site-home/site-home.component";
import {SuiviComponent} from "./views/reports/suivi/suivi.component";
import {GlobalDataComponent} from "./views/admin/global-data/global-data.component";
import {StatmensConfigComponent} from "./views/admin/statmens-config/statmens-config.component";
import {MensuelComponent} from "./views/reports/mensuel/mensuel.component";
import {PdmEditorComponent} from "./views/admin/pdm-editor/pdm-editor.component";
import {SaisieComponent} from "./views/admin/saisie/saisie.component";
import {BmensComponent} from "./views/reports/bmens/bmens.component";
import {UsersComponent} from "./views/admin/manager/users/users.component";
import {NotFoundComponent} from "./views/sessions/not-found/not-found.component";
import {ErrorComponent} from "./views/sessions/error/error.component";
import {CurrentSessionsComponent} from "./views/admin/manager/current-sessions.component";
import {ForgotPasswordComponent} from "./views/sessions/forgot-password/forgot-password.component";
import {InviteComponent} from "./views/sessions/invite/invite.component";
import {ClientsComponent} from "./views/admin/manager/clients/clients.component";
import {MonitorReportingComponent} from "./views/admin/manager/monitor-reporting.component";
import {MonitorEconComponent} from "./views/admin/manager/monitor-econ.component";
import {MonitorBmensComponent} from "./views/admin/manager/monitor-bmens.component";

export const rootRouterConfig: Routes = [
    {
        path: "session",
        component: AuthLayoutComponent,
        children: [
            {
                path: "login",
                loadChildren: () =>
                    import("./views/sessions/sessions.module").then(
                        (m) => m.SessionsModule
                    ),
                data: {title: "Session"},
            },
            {
                path: 'reset',
                component: ForgotPasswordComponent,
                data: {title: 'Redéfinir le mot de passe'}
            },
            {
                path: 'invite/:token',
                component: InviteComponent,
                data: {title: "Accepter l'invitation"}
            },
            {
                path: '404',
                component: NotFoundComponent,
                data: {title: 'Not Found'}
            },
            {
                path: 'error',
                component: ErrorComponent,
                data: {title: 'Error'}
            }
        ],
    },
    {
        path: "",
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'site-home',
                pathMatch: 'full'
            },
            {
                path: "site-home",
                component: SiteHomeComponent,
                data: {title: "Site home", breadcrumb: ""},
            },
            {
                path: "proto-hebdo",
                component: ProtoHebdoComponent,
                data: {title: "ProtoHebdo", breadcrumb: ""},
            },
            {
                path: "proto-hebdo/:site",
                component: ProtoHebdoComponent,
                data: {title: "ProtoHebdo", breadcrumb: ""},
            },
            {
                path: "proto-hebdo/:site/:year/:week",
                component: ProtoHebdoComponent,
                data: {title: "ProtoHebdo", breadcrumb: ""},
            },
            {
                path: "bmens",
                component: BmensComponent,
                data: {title: "mensual", breadcrumb: ""},
            },
            {
                path: "bmens/:site/:key",
                component: BmensComponent,
                data: {title: "mensual", breadcrumb: ""},
            },
            {
                path: "docs",
                component: DocumentsComponent,
                data: {title: "Documents", breadcrumb: ""},
            },
            {
                path: "actions",
                component: ActionsComponent,
                data: {title: "Actions", breadcrumb: ""},
            },
            {
                path: "report-mensual",
                component: MensuelComponent,
                data: {title: "annual", breadcrumb: ""},
            },
            {
                path: "statmens-conf",
                component: StatmensConfigComponent,
                data: {title: "StatmensConfigComponent", breadcrumb: ""},
            },
            {
                path: "saisie",
                component: SaisieComponent,
                data: {title: "Saisie", breadcrumb: ""},
            },
            {
                path: "pdm-editor",
                component: PdmEditorComponent,
                data: {title: "Pdm editor", breadcrumb: ""},
            },
            {
                path: "users",
                component: UsersComponent,
                data: {title: "Utilisateurs", breadcrumb: ""},
            },
            {
                path: "clients",
                component: ClientsComponent,
                data: {title: "Clients", breadcrumb: ""},
            },
            {
                path: "global-data",
                component: GlobalDataComponent,
                data: {title: "PDM global", breadcrumb: ""},
            },
            {
                path: "sites",
                component: SiteSelectComponent,
                data: {title: "Home", breadcrumb: ""},
            },
            {
                path: "suivi",
                children: [
                    {
                        path: '',
                        component: SuiviComponent,
                    },
                    {
                        path: ':site/:year',
                        component: SuiviComponent,
                    },
                ],
                data: {title: "Rapport de suivi", breadcrumb: ""},
            },
            {
                path: "sessions-monitor",
                component: CurrentSessionsComponent,
                data: {title: "Current sessions", breadcrumb: ""},
            },
            {
                path: "proto-monitor",
                component: MonitorReportingComponent,
                data: {title: "Monitor reporting", breadcrumb: ""},
            },
            {
                path: "monitor-econ",
                component: MonitorEconComponent,
                data: {title: "Monitor economy", breadcrumb: ""},
            },
            {
                path: "monitor-bmens",
                component: MonitorBmensComponent,
                data: {title: "Monitor relevés", breadcrumb: ""},
            },
            {
                path: ":err", pathMatch: 'full',
                redirectTo: "session/404",
            }
        ],
    },
    {
        path: "**",
        redirectTo: "session/404",
    },
];
