import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Plot, SiteTariffData} from "../../../shared/models/models";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {EnergyStats, EnergyStatsItem, EnergyStatsProduction} from "../../../shared/models/EnergyStats.model";
import {ReportSuivi} from "../../../shared/models/Suivi.model";

@Component({
    selector: 'suivi-energy-evol',
    template: `
        <div class="component-holder">
            <h2 class="color-blue">{{ title }}</h2>
            <div title="Tableau récapitulatif" *ngIf="site.clientSite.tariff_config">
                <ng-container *ngFor="let tariff of site.clientSite.tariff_config" class="energy-table-bloc">
                    <div *ngIf="!tariff.hidden" class="energy-table-bloc">
                        <p>
                            <mat-icon inline>fiber_manual_record</mat-icon>
                            Evolution de la consommation de {{ tariff.label }}
                        </p>
                        <table class="energy-table">
                            <tr class="table-energy-row-head">
                                <td style="width: 40px">Année</td>
                                <td colspan="2" style="width: 100px">Quantité</td>
                                <td style="width: 100px">Evolution</td>
                                <td style="width: 100px">Coûts</td>
                                <td style="width: 200px">Facteurs d'émission CO2eq.*</td>
                                <td style="width: 200px">Tonnes de CO2eq. émis</td>
                                <td style="width: 60px">FP*</td>
                                <td style="width: 180px">Prix unitaire</td>
                            </tr>
                            <tr class="table-row" *ngFor="let row of getTariffDataSorted(tariff.uid)">
                                <td class="table-col1" [title]="tariff.label">{{ row.year }}</td>
                                <td>{{ row.energyAdapted|number:'1.2-2' }}</td>
                                <td style="font-size: 12px">
                                    <span>{{ tariff.unitStr }}</span>
                                </td>
                                <td>{{ row._evol|number:'1.1-1' }} <span>%</span></td>
                                <td>{{ row.cost|number }} Fr.</td>
                                <td>{{ row.ceges }} <span>kg CO2/MWh</span></td>
                                <td>{{ row.cegesEmitted|number }}  <span>tCO2eq.</span></td>
                                <td>{{ row.fp }}</td>
                                <td>{{ row.priceAdapted |number:'1.1-2' }}
                                    <span>{{ tariff.priceUnitStr }}</span>
                                </td>
                            </tr>
                        </table>

                        <mark-down [content]="this.report.notes_tariffs[tariff.uid]"
                                   [tariffUID]="tariff.uid"
                                   [report]="report" field="notes_tariffs"></mark-down>
                    </div>
                </ng-container>
            </div>
        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviEnergyEvolComponent implements OnInit, OnChanges {
    @Input('type') type: string;
    @Input('report') report: ReportSuivi;
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('reportsOfSameConvention') reportsOfSameConvention: ReportSuivi[];

    title: string = "Consommation d'energie";

    energyDataAll: SiteTariffData[] = [];//all usages across years and tarifs
    rows: EnergyStatsItem[] = [];


    constructor(public myapp: AppService, public site: SiteService) {
    }

    ngOnInit() {
        this.site.siteLoadingStatus.subscribe(level => {
            //console.log('siteLoadingStatus():', level);
            if (level === SiteService.MAX_SITE_LOADING_STATUS) {
                this.bootstrap();
            }
        });

    }

    bootstrap() {
        this.energyDataAll = Array.from(this.site.clientSite.usage_map.values());
        this.energyDataAll = this.energyDataAll
            .filter(it => it.year <= this.report.year && it.year >= this.reportRef.year)
            .sort((a, b) => {
                return a.uid_tarif.localeCompare(b.uid_tarif) || (a.year - b.year);
                //return a.uid.localeCompare(b.uid);
            });
        this.populateEvolution();
        console.log('this.energyDataAll:', this.energyDataAll);
    }


    getTariffDataSorted(uidTarif: string): SiteTariffData[] {
        let retval: SiteTariffData[] = [];
        if (this.type === 'CONSOMMATION') {
            retval = this.energyDataAll
                .filter(it => it.uid_tarif === uidTarif)
                .sort((a, b) => a.year - b.year);
        }
        return retval;
    }

    ngOnChanges(changes: SimpleChanges) {
        //  console.log('ngOnChanges():', changes);
        this.bootstrap();
    }

    populateEvolution() {
        let amountOfrefYear = 0;
        // console.log("this.metersToAgentMap", this.metersToAgentMap, this.availableAgents);
        this.energyDataAll.forEach((item, key) => {
            if (item.year === this.reportRef.year) amountOfrefYear = item.energy;

            item._evol = amountOfrefYear > 0 ? 100 * (item.energy - amountOfrefYear) / amountOfrefYear : 0;
            if (amountOfrefYear === 0) amountOfrefYear = item.energy;
        });
    }
}
