import {Md5} from 'ts-md5';


export class H {
    public static openUrlNewTab(url: string) {
        window.open(url, "_blank");
    }

    public static print(mess: any) {
        console.log(mess);
    }

    public static getMd5(toHash) {
        if (!toHash) return "";
        return Md5.hashStr(toHash);
    }

    public static addDaysToDate(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    public static arrayFromRange(start, count) {
        if (arguments.length == 1) {
            count = start;
            start = 0;
        }

        let foo = [];
        for (let i = 0; i < count; i++) {
            foo.push(start + i);
        }
        return foo;
    }

    public static utf8_to_b64(str) {
        return window.btoa(unescape(encodeURIComponent(str)));
    }

    public static b64_to_utf8(str) {
        return decodeURIComponent(escape(window.atob(str)));
    }

    public static negateArray(values) {
        return values.map(v => -v);
    }

    public static cleanNumberString(str) {
        console.log("clean", str);
        if (!str) str = '';
        let t = 0;
        const val = str.toString()
            .replace(/[^\d.]/g, '')
            .replace(/\./g, match => ++t === 2 ? '' : match);
        return val.toString();
    }

    public static toSnake(key: string) {
        const result = key.replace(/([A-Z])/g, " $1");
        const snake = result.split(' ').join('_').toLowerCase();
        console.log("toSnake", snake);
        return snake;
    }

    public static unixTs(js = false): number {
        if (js) return Math.floor(Date.now());
        else
            return Math.floor(Date.now() / 1000);
    }

    public static arrayUnique(arr: any []) {
        return arr.filter((v, i, a) => a.indexOf(v) === i);
    }

    public static arrayCumul(arr: number []) {
        let acc = 0;
        if (!arr) return [];
        return arr.map((item, i) => {
            const retVal = acc + item;
            acc += item;
            return retVal;
        });
    }

    public static arrayDelete(arr: any [], item: any) {
        return arr.filter((v, i, a) => v !== item);
    }

    public static getDayName(dateStr, locale = "fr") {
        const date = new Date(dateStr);
        return date.toLocaleDateString(locale, {weekday: 'short'});
    }

    public static getDayNum(date: Date): number {
        const start = new Date(date.getFullYear(), 0, 0);
        const diff = Number(date.getTime() - start.getTime());
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }

    public static dateToStrMysql(d: Date): string {
        let dayOfMonth = String(d.getDate());
        if (dayOfMonth.length === 1) {
            dayOfMonth = '0' + dayOfMonth;
        }
        let month = String(d.getMonth() + 1);
        if (month.length === 1) {
            month = '0' + month;
        }
        return d.getFullYear() + "-" + month + "-" + dayOfMonth;
    }

    public static dateToStr(d: Date, short = true, shortYear = false): string {
        let dayOfMonth = String(d.getDate());
        if (dayOfMonth.length === 1) {
            dayOfMonth = '0' + dayOfMonth;
        }
        let month = String(d.getMonth() + 1);
        if (month.length === 1) {
            month = '0' + month;
        }
        let shortDate = '';
        if (shortYear)
            shortDate = dayOfMonth + '.' + month + '.' + d.getFullYear().toString().substring(2, 4);
        else
            shortDate = dayOfMonth + '.' + month + '.' + d.getFullYear();
        if (!short) shortDate = shortDate + " " + d.toTimeString().split(' ')[0];
        return shortDate;
    }

    public static stringToNumericChars(str) {
        const ret = str.split('').map((char) => {
            return parseInt(char.charCodeAt(0) + 1, 10);
        }).join('');
        //  console.log('stringToNumericChars', str, ret);
        return ret;
    }

    public static randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static randomStr(length) {
        let result = '';
        let characters = 'abcdefghijklmnopqrstuvwxyz012345678978963214569874563214569874563214';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    public static customObjectToJson(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    public static customMapToObject(obj: Map<string, any>) {
        return Object.fromEntries(obj.entries());
    }

    public static hashCode(str: string): string {
        var hash = 0;
        if (str.length === 0) {
            return hash.toString(36);
        }
        for (let i = 0; i < str.length; i++) {
            let char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash.toString(36);
    }

    public static ucfirst(s) {
        if (!s) return s;
        s = s.toLowerCase();
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    public static getReleveKey(year, releveNum): string {
        if (releveNum < 10) releveNum = "0" + releveNum;
        return year + "" + releveNum;
    }

    public static wordWrap(str, maxWidth, newLineStr = "\n") {
        let done = false;
        let res = '';
        while (str.length > maxWidth) {
            let found = false;
            // Inserts new line at first whitespace of the line
            for (let i = maxWidth - 1; i >= 0; i--) {
                if (H.testWhite(str.charAt(i))) {
                    res = res + [str.slice(0, i), newLineStr].join('');
                    str = str.slice(i + 1);
                    found = true;
                    break;
                }
            }
            // Inserts new line at maxWidth position, the word is too long to wrap
            if (!found) {
                res += [str.slice(0, maxWidth), newLineStr].join('');
                str = str.slice(maxWidth);
            }

        }

        return res + str;
    }

    public static formatNumber(number, decimals = 2) {
        number = number.toFixed(2) + '';
        let x = number.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + "'" + '$2');
        }
        if (x2.toString().length > decimals) x2 = x2.substr(0, decimals);
        return x1 + x2;
    }

    public static testWhite(x) {
        let white = new RegExp(/^\s$/);
        //let white = new RegExp(/^\S\r\n$/);
        return white.test(x.charAt(0));
    }

    public static removeNullsFromObject(object) {
        Object.keys(object).forEach(k => {
            if (!object[k]) delete object[k];
        });
        return object;
    }

    public static nl2br(str, is_xhtml = false) {
        const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ?
            '<br ' + '/>' : '<br>';
        // Adjust comment to avoid issue on phpjs.org display

        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    public static incrStatMap(map: Map<string, number>, key: string, incrAmount = 1): Map<string, number> {
        if (map.has(key)) {
            const currCount = map.get(key);
            map.set(key, currCount + incrAmount);
        } else {
            map.set(key, incrAmount);
        }
        return new Map([...map.entries()].sort());
    }

    public static getInitialsFromEmail(email: string) {
        const nameChunk = email.split('@')[0];
        let names = nameChunk.split('.'),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    public static getFirstNameFromEmail(email: string) {
        let retval = '';
        const nameChunk = email.split('@')[0];
        const names = nameChunk.split('.');
        let firstNameInitial = '';
        if (names.length > 1) {
            firstNameInitial = names[0].substring(0, 1).toUpperCase() + '.';
            retval = firstNameInitial + " " + H.ucfirst(names[1]);
        } else {
            retval = H.ucfirst(names[0]);
        }

        return retval;
    }
}

