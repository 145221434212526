import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import * as Highcharts from 'highcharts';
import PatternFill from "highcharts/modules/pattern-fill";
import Exporting from "highcharts/modules/exporting";
import Annotations from "highcharts/modules/annotations";
import Debugger from "highcharts/modules/debugger";

if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    Exporting(Highcharts);
    Annotations(Highcharts);
    Debugger(Highcharts);
}
import {H} from "../../shared/helpers/H";
import {SiteService} from "../../shared/services/site.service";
import {Plot, PlotUnit} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {AxisPlotLinesOptions, Chart} from "highcharts";
import {K} from "../../shared/models/K";
import {OptimiseChart} from "../../shared/helpers/OptimiseChart";
import {BehaviorSubject, from, Observable, Subscription} from "rxjs";
import {Optimise} from "../../shared/helpers/Optimise";
import {concatMap} from "rxjs/operators";
import {Pdm} from "../../shared/models/Pdm";
import {PdmSignature} from "../../shared/models/PdmSignature";
import {PdmYearlyConfig, PdmYearModel} from "../../shared/models/PdmYearConfig";

@Component({
    selector: 'statmens-cassure',
    template: `
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxFlex>
                    <highcharts-chart [Highcharts]="Highcharts"
                                      id="cassure-finder-plot"
                                      [options]="chartOptions" [(update)]="updateFlag"
                                      (chartInstance)="onChartInstance($event)">
                    </highcharts-chart>
                </div>
                <table fxFlex="560px" class="table-values" *ngIf="tempSignature&&tempSignature.points&&showTable">
                    <tr (click)="myapp.showConsole(tempSignature)">
                        <td></td>
                        <td>ind</td>
                        <td>X</td>
                        <td>Y</td>
                        <td>Tg</td>
                        <td>Td</td>
                        <td>tTot</td>
                        <td>penteG</td>
                        <td>consG</td>
                        <td>penteD</td>
                        <td>consD</td>
                        <td>ecart</td>
                        <td>conn</td>
                    </tr>
                    <tr *ngFor="let pt of tempSignature.points;let i=index;">
                        <td>{{ i + 1 }}</td>
                        <td (click)="tempSignature.sortBy('index')">{{ pt.index }}</td>
                        <td [class.inf]="pt.x<pt.connexion"
                            (click)="tempSignature.sortBy('x')">{{ pt.x|number:'1.2-2' }}
                        </td>
                        <td (click)="tempSignature.sortBy('y')">{{ pt.y|number:'1.2-2' }}</td>
                        <td>{{ pt.tGauche|number:'1.2-2' }}</td>
                        <td>{{ pt.tDroite|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('tTotal')">{{ pt.tTotal|number:'1.2-2' }}</td>
                        <td>{{ pt.leftSlope|number:'1.2-2' }}</td>
                        <td>{{ pt.leftConstant|number:'1.1-1' }}</td>
                        <td>{{ pt.rightSlope|number:'1.1-1' }}</td>
                        <td>{{ pt.rightConstant|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('ecart')">{{ pt.ecart|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('connexion')">{{ pt.connexion|number:'1.2-2' }}</td>
                    </tr>
                </table>
                <div fxFlex="360px">
                    <div fxLayout="column">
                        <div class="config-panel" *ngIf="tempSignature">
                            <h2> Valeur: {{ tempSignature.cassure|number }}</h2>
                            <h3 style="margin: 10px 0 4px 0">Options </h3>
                            <mat-checkbox (change)="updateSignature()" class="mr-16" [(ngModel)]="tempSignature.sd">
                                Saut discret
                            </mat-checkbox>
                            <mat-checkbox (change)="updateSignature()" [(ngModel)]="tempSignature.isManual">
                                Saisie manuelle
                            </mat-checkbox>

                            <div class="p-4 cassure-input" *ngIf="tempSignature.isManual">
                                <input matInput placeholder="Cassure manuelle" type="number" step="0.1"
                                       [(ngModel)]="tempSignature.manual" (keyup.enter)="updateSignature()">
                            </div>
                            <h3 style="margin: 10px 0 4px 0">Plat </h3>
                            <mat-radio-group [(ngModel)]="tempSignature.flat" (change)="updateSignature()">
                                <mat-radio-button class="mr-8" [value]="'NONE'"> Normal</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'LEFT'"> Gauche</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'RIGHT'"> Droite</mat-radio-button>
                            </mat-radio-group>

                            <ng-container *ngIf="!plot.isCost()">
                                <h3 style="margin: 10px 0 4px 0">Unité Y</h3>
                                <mat-radio-group [value]="plot.plotUnit.unit_plot" (change)="setUnitFactor($event.value)">
                                    <mat-radio-button class="mr-8" [value]="'K'"> Kilo</mat-radio-button>
                                    <mat-radio-button class="mr-8" [value]="'M'"> Mega</mat-radio-button>
                                    <mat-radio-button class="mr-8" [value]="'G'"> Giga</mat-radio-button>
                                </mat-radio-group>
                            </ng-container>

                            <h3 style="margin: 10px 0 4px 0">Type de cassure</h3>
                            <mat-radio-group [(ngModel)]="tempSignature.type" (change)="updateSignature()">
                                <mat-radio-button class="mr-8" [value]="'CHAUD'"> Chaud</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'FROID'"> Froid</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'MIXTE'"> Mixte</mat-radio-button>
                            </mat-radio-group>
                            <div fxLayout="row">
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="updateSignature(true)">
                                    Save
                                </button>
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="chartUpdate()">
                                    chartUpdate
                                </button>
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="bootstrap()">
                                    boot
                                </button>
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="optimiseChart.applyForCassure(this)">
                                    apply
                                </button>
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="renderChart()">
                                    Render
                                </button>
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="showTable=!showTable">
                                    Table {{ showTable }}
                                </button>
                            </div>

                            <div fxLayout="row" *ngIf="tempSignature&&tempSignature.calc_cache" class="pt-8">
                                <button mat-raised-button *ngFor="let kv of tempSignature.calc_cache|keyvalue;" class="mr-4 p-4 btn-xs-25"
                                        [class.active]="site.selectedConfigForPdmAndYear.cassure_chosen_reg_index==kv.key"
                                        (click)="drawSignatureByIndex(kv.key)">{{ kv.key }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensCassureComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('plot') plot: Plot;
    @Input('pdm') dataPdm: Pdm;
    @Input('year') year: number;
    showTable = false;

    chart: Chart;
    errors: any[] = [];
    Highcharts: typeof Highcharts = Highcharts;
    //public chartOptions: Highcharts.Options = {...K.chartOptions};
    public chartOptions: Highcharts.Options = Object.assign({}, K.chartOptions)
    updateFlag = false;
    signature: any; // for SIGNATURE
    cassure = 0;    // for SIGNATURE
    yMin = 600000;
    optimiseChart: OptimiseChart;
    public tempSignature: PdmSignature = null;

    constructor(public myapp: AppService, public site: SiteService,) {
        super();
    }

    ngOnInit() {
        this.site.siteSpecificEventTriggerer.subscribe(event => {
            //  if (event === SiteService.STATMENS_CONFIG_RELOADED) {
            if (event === SiteService.PDMS_DATA_POPULATED && this.site.refYear) {
                if (this.dataPdm && this.chart) {
                    this.bootstrap();
                }
            }
            if (event === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.bootstrap('siteSpecificEventTriggerer:PDMS_YEARLY_CONFIG_CHANGED');
            }
        });

        this.site.loadStatmensConfigs();
    }

    bootstrap(debugCalledBy = "") {
        this.optimiseChart = new OptimiseChart(this.plot, this.site.selectedPdm, this.site.dataSummaryMap);
        this.optimiseChart.applyForCassure(this); // generate this.chartOption according to plot and call chartUpdate
        if (this.chart)
            this.renderChart();
    }

    setUnitFactor(newValue) {
        const plotUnit = new PlotUnit(this.plot.getKey(), this.site.selectedPdm.fluid);
        plotUnit.setUnit(newValue);
    }

    renderChart(from = null) {
        this.tempSignature = new PdmSignature(this.site.year, this.site.selectedPdmYearlyPlotDataMap, this.site.selectedConfigForPdmAndYear);
        if (!this.dataPdm) {
            console.error('StatmensCassureComponent:renderChart', 'dataPdm EMPTY');
            return;
        }
        if (!this.chart) {
            console.error('StatmensCassureComponent:renderChart', 'chart null');
            return;
        }

        if (this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index)
            this.drawSignatureByIndex(this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index);
        this.drawMainPointsAndCassure();
    }

    drawMainPointsAndCassure() {
        // console.log("drawMainPointsAndCassure() ", this.tempSignature);
        this.chartUpdateSerie(0, this.tempSignature.points, 'Update Signature-cassure');
        if (!this.tempSignature.cassure) {
            console.error("Cassure not defined for", this.site.year);
            return;
        }
        this.tempSignature.cassure = Number(this.tempSignature.cassure);
        const line = {
            id: 'cassure',
            label: 'Cassure:' + this.tempSignature.cassure.toFixed(2),
            dashStyle: 'longdashdot',
            color: '#FF0000',
            width: 1,
            value: this.tempSignature.cassure
        } as any;
        this.tempSignature.points.forEach(item => {
            if (item.y < this.yMin) this.yMin = item.y;
        })

        if (this.chart.xAxis && this.chart.xAxis.length > 0) {
            this.chart.xAxis[0].removePlotLine('cassure');
            this.chart.xAxis[0].addPlotLine(line as AxisPlotLinesOptions);
        }
    }

    drawSignatureByIndex(index) {
        this.tempSignature.calc();
        const serieLines = this.tempSignature.calc_cache[index].plot.map(pt => {
            pt['y'] = pt['y'];// * this.plot.getUnitFactor();
            return pt;
        });
        this.tempSignature.cassure = this.tempSignature.calc_cache[index].pt.connexion;
        this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index = Number(index);
        this.drawMainPointsAndCassure();
        this.chartUpdateSerie(1, serieLines);

        // this.chartUpdate();
    }

    updateSignature(save = false) {
        this.tempSignature.calc();
        this.drawMainPointsAndCassure();
        this.drawSignatureByIndex(this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index);
        if (save) {
            console.log('Saving cassure', this.site.selectedConfigForPdmAndYear);
            this.site.updateSignatureConfig(this.tempSignature);
        }
    }

    // called from OptimiseChart
    public chartUpdate() {
        this.optimiseChart.setTitle(); // set chartOption settings and create series
        this.chartOptions.yAxis['min'] = this.yMin;
        this.chartOptions.xAxis['title'].text = this.optimiseChart.getXAxisLabel().replace("-STATION-", this.site.clientSite.weather);
        this.chartOptions.xAxis['tickInterval'] = 5;
        if (this.chart) this.chart.update(this.chartOptions, false, false);
    }

    chartUpdateSerie(index, data, debugInfos = "") {
        if (!this.chart) {
            return;
        }
        if (this.chart.series && this.chart.series[index]) {
            this.chart.xAxis[0].setCategories(undefined);
            this.chart.series[index].setData(data, true, true);
        } else {
            console.log("SERIE PAS TROUVöE", index, data);
        }
    }

    onChartInstance(chart) {
        this.chart = chart;
        this.renderChart();
    }

    ngAfterViewInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("ngOnChanges---------- ", changes);
        this.bootstrap('ngOnChanges');
    }

    ngOnDestroy() {
        // if (this.chart) Highcharts.charts.splice(this.chart.index, 1);
    }

    raiseError(message, year: number, severity, type = 'data') {
        this.errors.push({message, severity, ts: H.unixTs(), year, type});
    }
}
