import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {PlotlyComponent, PlotlyService} from "angular-plotly.js";
import {plotEfficiencyData, plotEfficiencyLayout, plotFinancialData, plotFinancialLayout, plotGoalsData, plotGoalsLayout} from "./plots-config";
import {Plotly} from "angular-plotly.js/lib/plotly.interface";
import {ReportSuivi} from "../../../shared/models/Suivi.model";
import {H} from "../../../shared/helpers/H";

@Component({
    selector: 'suivi-energy-efficiency',
    template: `
        <div class="mb-32" fxLayout="column" fxLayoutAlign="start stretch">
            <h2>Synhèse des objectifs d'économies</h2>

            <plotly-plot class="plotly-plot"
                         [updateOnDataChange]="true"
                         [divId]="'plotGoals'"
                         [data]="plotDataGoals"
                         [layout]="plotLayoutGoals"
                         [config]="myapp.k.defaultConfig">
            </plotly-plot>
            <div fxFlex></div>
        </div>
        <div class="mb-32" fxLayout="column" fxLayoutAlign="start stretch" [hidden]="true">
            <h2>Synhèse des flux financiers</h2>
            <plotly-plot
                    class="plotly-plot"
                    [updateOnDataChange]="true"
                    [data]="plotDataFinancial"
                    [divId]="'plotFinancial'"
                    [layout]="plotLayoutFinancial"
                    [config]="myapp.k.defaultConfig">
            </plotly-plot>
            <div fxFlex></div>
        </div>
        <div class="mb-32" fxLayout="column" fxLayoutAlign="start stretch">
            <h2>Evolution de l'efficacité prévisionelle et effective</h2>
            <plotly-plot
                    class="plotly-plot"
                    [updateOnDataChange]="true"
                    [divId]="'plotEfficiency'"
                    [data]="plotDataEfficiency" [layout]="plotLayoutEfficiency"
                    [config]="myapp.k.defaultConfig">
            </plotly-plot>
            <div fxFlex></div>

        </div>

        <div class="footnote">
            * L’efficacité prévisionnelle évolutive inclue les actions de performance énergétiques qui sont identifiées après la signature de la
            convention.
        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviEnergyEfficiencyComponent implements OnInit, OnChanges {

    @Input('reportRef') reportRef: ReportSuivi;
    @Input('report') report: ReportSuivi;
    @Input('year') year: number;
    @Input('goals') goals: any[];

    plotLayoutEfficiency = plotEfficiencyLayout as Plotly.Layout;
    plotDataEfficiency = plotEfficiencyData;

    plotLayoutGoals = plotGoalsLayout as Plotly.Layout;
    plotDataGoals = plotGoalsData;

    plotLayoutFinancial = plotFinancialLayout as Plotly.Layout;
    plotDataFinancial = plotFinancialData;


    constructor(public myapp: AppService, public site: SiteService, public plotlyServ: PlotlyService) {
    }

    ngOnInit() {
        //console.log("PdmYearlyConfigComponent", 1);
        this.site.siteLoadingStatus.subscribe(level => {
            if (level === SiteService.MAX_SITE_LOADING_STATUS) {
                this.bootstrap();
            }
        });
    }

    bootstrap() {

    }

    plotGoals() {

    }

    plotEfficiency() {
        this.plotLayoutEfficiency.width = 950;
        this.plotLayoutEfficiency.height = 500;

        this.plotLayoutEfficiency.annotations[0].y = this.reportRef.goal;
        this.plotLayoutEfficiency.annotations[0].text = "Cible " + this.reportRef.goal + "%";

        this.plotLayoutGoals.width = 950;
        this.plotLayoutGoals.height = 500;
        this.plotLayoutFinancial.width = 950;
        this.plotLayoutFinancial.height = 500;

        this.plotLayoutGoals.xaxis.ticktext = this.plotLayoutGoals.xaxis.ticktext.map((v, i) => {
            return this.reportRef.year_start + i - 1;
        });
        //if (!this.goals || this.goals.length !== this.plotDataGoals[0].y.length) return;
        if (!this.goals || this.goals.length === 0) return;
        this.plotDataFinancial[0].y = this.plotDataGoals[0].y.map((v, i) => {
            return -this.goals[i].invest_net;
        });
        this.plotDataFinancial[1].y = this.plotDataGoals[0].y.map((v, i) => {
            return -this.goals[i].invest_real;
        });
        this.plotDataFinancial[2].y = this.plotDataGoals[0].y.map((v, i) => {
            return this.goals[i].acc_econ;
        });
        this.plotDataFinancial[3].y = this.plotDataGoals[0].y.map((v, i) => {
            return this.goals[i].flow;
        });
        this.plotDataGoals[0].y = this.plotDataGoals[0].y.map((v, i) => {
            return this.goals[i].estimated_economy_elec * 1e-6;
        });
        this.plotDataGoals[1].y = this.plotDataGoals[1].y.map((v, i) => {
            return this.goals[i].estimated_economy_chaleur * 1e-6;
        });
        this.plotDataGoals[5].y = this.plotDataGoals[5].y.map((v, i) => {
            return this.goals[i].estimated_economy_carbon;
        });


        this.plotLayoutFinancial.xaxis.ticktext = this.plotLayoutFinancial.xaxis.ticktext.map((v, i) => {
            if (i === 0)
                return this.reportRef.year;
            else return Number(this.reportRef.year_start) + i - 1;
        });
        this.plotLayoutEfficiency.xaxis.ticktext = this.plotLayoutEfficiency.xaxis.ticktext.map((v, i) => {
            if (i === 0)
                return this.reportRef.year;
            else return Number(this.reportRef.year_start) + i - 1;
        });
        this.plotDataEfficiency[0].y = this.plotDataEfficiency[0].y.map((v, i) => {
            return this.goals[i].goal_minimum;
            //  return this.goals[i].usage;
        });
        this.plotDataEfficiency[1].y = this.plotDataEfficiency[1].y.map((v, i) => {
            return this.goals[i].efficiency_predicted;
        });
        this.plotDataEfficiency[2].y = this.plotDataEfficiency[2].y.map((v, i) => {
            return this.goals[i].efficiency_real;
        });
        this.plotDataEfficiency[3].y = [this.reportRef.goal, this.reportRef.goal];

    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
        this.plotEfficiency();
    }

}

/*
{
                year: i + " (" + currYear + ")",
                goal_theorical: goals.theoretical[i],
                goal_minimum: goals.minimum[i],
                efficiency_predicted: 100 * (estimatedEconAccumulator + usage) / usage,
                efficiency_real: 100 * (realEconAccumulator + usage) / usage,
            };
 */
