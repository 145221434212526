import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Plot} from "../../../shared/models/models";
import {HttpClient} from "@angular/common/http";
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {SiteService} from "../../../shared/services/site.service";
import {LayoutService} from "../../../shared/services/core/layout.service";
import {BaseComponent} from "../../../shared/BaseComponent";
import {K} from "../../../shared/models/K";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {PdmDataSummary} from "../../../shared/models/PdmDataSummary";
import {PdmDataRowExported} from "../../../shared/models/PdmDataRow";
import {H} from "../../../shared/helpers/H";


@Component({
    selector: 'app-statmens-config',
    templateUrl: './statmens-config.component.html',
    styleUrls: ['./statmens-config.component.scss'],
    animations: egretAnimations
})
export class StatmensConfigComponent extends BaseComponent implements OnInit {

    selectedField: string = null;
    errMessages: string[] = [];
    dispMode = 'config';
    monthsLabels = K.months;
    public plotTypes = ['Simple', 'CassureCheck', 'ModelSimul', 'ModelQuality'];
    public plotsGroupIndex: number = 1;
    public historyCount: number = 5;
    public fluidsAvailable = [];

    public previewPath = "";
    public previewPdfExists = false;
    private loadingStatus = 0;

    plotAnnual: Plot = null;
    plot1: Plot = null;
    plot2: Plot = null;
    plotNoiseForceUpdate: number;
    plotsCache = {};

    links = [1, 2, 3, 4, 5, 6, 7, 8];
    sections = [];
    selectedSection = undefined;
    debugIncoming = {};

    constructor(private http: HttpClient, private cdr: ChangeDetectorRef,
                public myapp: AppService, public api: ApiService, public site: SiteService,
                // public ws: WebSocketService,
                public layout: LayoutService) {
        super();
        // this.ws.connect();
        // this.ws.messages$.asObservable()
        //     .subscribe((value: any) => {
        //         console.log("VAL SOCKET: ", value);
        //     });
    }

    ngOnInit(): void {
        this.componentLoadingStatus.next(1);
        this.site.siteLoadingStatus.subscribe(status => {
            // console.log('siteLoadingStatus', status);
            if (status === SiteService.MAX_SITE_LOADING_STATUS) {
                this.renderFluidSelector();
                this.loadingStatus = status;
                this.site.loadSitePdms();
                this.site.loadStatmensConfigs();
            }
        });
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            if (eventName === SiteService.PDMS_DATA_POPULATED) {
                if (this.site.year) {
                    this.scanReleveGenSummary();
                    this.renderButtons();
                    this.myapp.storeCurrentRoute();
                }
            }
        });

    }

    // bootstraping methods (entry points from RX events
    renderFluidSelector() {
        if (!this.site.pdms) {
            this.myapp.showError("Pdms non chargés");
            if (!this.site.tried_loading_pdms)
                this.site.loadSitePdms();
            return;
        }
        this.site.pdms.forEach(spot => {
            if (!this.fluidsAvailable.includes(spot.fluid)) {
                this.fluidsAvailable.push(spot.fluid);
            }
        });
        this.componentLoadingStatus.next(2);// spots loaded
    }

    renderButtons() {
        this.sections = [];
        let currSummary = this.site.dataSummaryMap.get(this.site.year.toString());
        //currSummary = this.sortObjectKeys(currSummary);
        if (!currSummary) return;
        Object.keys(currSummary).forEach(k => {
            if (k.includes('_cumul') && !k.includes('ve2') && currSummary[k] > 0) this.sections.push(k.replace("_cumul", '').toUpperCase());
        });
        this.sections.sort().reverse();
    }

    selectPdm(pdm) {
        this.site.selectedPdm = pdm;
        this.errMessages = [];
        this.site.selectedPdm = pdm;
        this.selectedField = null;
        console.log("selectPdm", this.site.selectedPdm);

        if (this.site.selectedPdm.relevesCache) {
            console.log("StatMensConfig::: INIT:: assignVirtualRegs():", this.site.selectedPdm);
            this.scanReleveGenSummary();
        } else
            this.site.populatePdmCache(true, false, "StatMensConfig:SelectPdm");
    }

    /// TODO: remove
    scanReleveGenSummary() {
        console.log("scanReleveGenSummary:");
        const filteredRows = Array.from(this.site.selectedPdm.relevesCache.values()).filter(row => {
            return row.releve_year > (this.site.year - 10)
                && row.releve_year <= this.site.year
                && !(row.releve_year === this.site.year && row.releve_num > (this.site.selectedMonthIndex + 1));
        });

        /*
        this.site.availableYearsInRows = [];
        filteredRows.forEach(row => {
            this.site.availableYearsInRows.push(row.releve_year);
            this.site.availableYearsInRows = H.arrayUnique(this.site.availableYearsInRows).sort();
        });*/


        this.site.dataSummaryMap = new Map<string, PdmDataSummary>();
        filteredRows.forEach(pdmData => {
            if (this.site.dataSummaryMap.has(pdmData.releve_year.toString())) {
                const currentAccumulator: PdmDataSummary = this.site.dataSummaryMap.get(pdmData.releve_year.toString());
                currentAccumulator.releves_count = pdmData.releve_num;
                currentAccumulator.days += pdmData.days;
                currentAccumulator.dist = pdmData.dist;
                currentAccumulator.last_date = pdmData.date;
                currentAccumulator.ve2Label = pdmData.exported.ve2Label;

                Object.keys(pdmData.exported).forEach(k => {
                    if (k.includes("_")) {
                        currentAccumulator[k + "_last"] = 30 * pdmData.exported[k] / pdmData.days;
                        currentAccumulator[k + "_cumul"] += pdmData.exported[k];
                    }
                });
                //console.log("YEAR exists: Num:" + pdmData.releve_year, currentAccumulator);
            } else {
                //console.log("YEAR NEWW: Num:" + pdmData.releve_num, pdmData.metas, pdmData.date);
                let accumulator = {
                    releves_count: 1,
                    last_date: pdmData.date,
                    dist: pdmData.dist || 0,
                    days: pdmData.days,
                    ve2Label: pdmData.exported.ve2Label
                };
                Object.keys(pdmData.exported).forEach(k => {
                    if (k.includes("_")) {
                        //otherwise its a label or other meta
                        accumulator[k + "_last"] = 30 * pdmData.exported[k] / pdmData.days;
                        accumulator[k + "_cumul"] = pdmData.exported[k];
                    }
                });
                const constDataSummary = new PdmDataSummary(accumulator);
                // console.log("---------------- PdmDataSummary() ", constDataSummary);
                if (constDataSummary.dist > 15)
                    this.site.dataSummaryMap.set(pdmData.releve_year.toString(), constDataSummary);
            }
        });

        console.info(" scanReleveGenSummary() ", this.site.dataSummaryMap);
        this.rePlot();
    }

    saveInPdmRefYearForSelectedYear(newRefYear: number) {
        this.site.refYear = null;
        // this.site.saveSelectedPdmConfig('ref_year', {year_ref: Number(newRefYear)}, true);
    }

    checkIfPdfExists() {
        this.previewPath = this.site.uid + "." + this.site.selectedPdm.uid + "." + this.site.year;
        this.site.api.getIsStatMensPdfExist(this.previewPath).subscribe(res => {
            //console.log("checkIfPdfExists():", res);
            this.previewPdfExists = res.body === 1;
        });
    }

    savePlotsCaheToServer() {
        this.previewPdfExists = false;
        this.site.api.saveStatMensPlotsCacheToServer(this.previewPath,
            this.plotsCache,
            this.site.selectedPdm.data_summary,
            this.site.clientSite.name
        ).subscribe(res => {
            console.log("savePlotsCaheToServer():", res);
            this.api.generateStatmensPdf(this.previewPath).subscribe(resp => {
                this.checkIfPdfExists();
            });
        });
    }

    rePlot(section: string = null) {
        this.site.seriesData = {};
        if (section)
            this.selectedSection = section;
        if (!this.selectedSection)
            this.selectedSection = "VE1_D";

        this.plotAnnual = null;
        this.plot1 = null;
        this.plot2 = null;

        if (this.selectedSection === "SIGNATURE" || this.selectedSection === "MODELE") {
            if (this.selectedSection === "SIGNATURE") {
                this.plot1 = new Plot(this.site.year, this.historyCount, 'scatter', Plot.SECTION_SIGNATURE, 'CONS', false, true, PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);

                /// WARNING: Plot.correct doesnt affect values in cassureFinder component
                this.plot2 = new Plot(this.site.year, 1, 'scatter', Plot.SECTION_SIGNATURE_FINDER, 'CONS', true, true, PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);
            }
            if (this.selectedSection === "MODELE") {
                this.plot1 = new Plot(this.site.year, this.historyCount, 'line', Plot.SECTION_QUALITY, 'CONS', false, false, PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);
                this.plot2 = new Plot(this.site.year, this.historyCount, 'line', Plot.SECTION_DERNIERE, 'CONS', true, false, PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);
            }
        } else {
            // annual plot
            this.plotAnnual = new Plot(this.site.year, this.historyCount, 'column',
                Plot.SECTION_ANNUAL, 'CONS', false, true, this.selectedSection.toLowerCase());
            if (!this.selectedSection.toUpperCase().includes("PT_C"))
                this.plot1 = new Plot(this.site.year, this.historyCount, 'column', Plot.SECTION_BASIC,
                    'COST', false, true, this.selectedSection.toLowerCase(), this.site.selectedMonthIndex);
            else this.plot1 = null;
            this.plot2 = new Plot(this.site.year, this.historyCount, 'line', Plot.SECTION_BASIC,
                'COST', true, false, this.selectedSection.toLowerCase(), this.site.selectedMonthIndex);
        }

        this.plotNoiseForceUpdate = H.unixTs();
        this.checkIfPdfExists();
    }

    sendDebugSocket() {
        console.log("sendDebugSocket TEST");
        this.site.test();
    }

    /*
    From @Output sub components
     */
    showDebug(ev) {
        console.log("SowDebug", ev);
    }

    updateLocalCache(ev) {
        const {key, svg, raw, title, subTitle, lastReleve} = ev;
        this.plotsCache[key] = {svg, raw, title, subTitle, lastReleve};
        this.plotsCache['METAS'] = {pdm: this.site.selectedPdm.label, site: this.site.clientSite.name, client: this.site.clientSite.uid_client};
        //console.log("updateLocalCache", this.plotsCache);
    }

    formCallBack(ev) {
        const {plotId, data} = ev;
        this.debugIncoming[plotId] = data;
        /*
        const {arg, obj, value, col} = ev;
        if (arg === "plotError") {
            this.errMessages.push(value);
        }*/
    }
}
