import {PdmDataRow} from "./PdmDataRow";
import {PdmRegDef} from "./PdmRegDef";
import {Plot} from "./models";
import {Optimise} from "../helpers/Optimise";
import {PdmYearlyConfig} from "./PdmYearConfig";

export class Pdm {
    public static STATUS_DRAFT = 'DRAFT';
    public static STATUS_PUBLISHED = 'PUBLISHED';

    uid: string;
    uid_site: string;
    uid_place: string;
    uid_client: string;

    pdm_num: string;
    pdm_prefix: string;
    fluid: string;
    agent: string;
    offset: number;

    label: string;
    label_short: string;
    label_place: string;
    label_bilan: string;

    is_vpdm: boolean;
    is_pci: boolean;
    is_entrant: boolean;

    type: string;
    description: string;
    date_start: string;
    date_end: string;

    ve1: string;//dtot, dtotE, dTotI
    ve2: string;//any reg

    status: string;
    ts_create: number;
    ts_update: number = 0;

    yearly_config: any = null;
    yearly_config_map: Map<number, PdmYearlyConfig> = new Map<number, PdmYearlyConfig>();
    data_summary?: any = {};
    relevesCache?: Map<string, PdmDataRow>;

    ref: string;// only present when query All pdm for importation

    // central helper to extract tot fields and their sum from regs.
    // If rowData is not provided the func will return number of fields for each tot
    public static getTotFields(regs: PdmRegDef[], rowData: PdmDataRow = null) {
        let extraCells = {};

        regs.forEach(reg => {
            // console.log("HASH", reg.getTypeHash('d'), reg.getTypeHash('c'));
            let importPrefix = (reg.is_import ? "Imp" : "Exp");
            let d = 1;
            let c = 1;

            if (rowData) {
                d = Number(rowData.data['d' + reg.num]) || 0;
                c = Number(rowData.data['c' + reg.num]) || 0;
            }

            if (reg.is_derniere_cons) {
                if (!d) d = 0;
                if (reg.d_type && reg.d_type === PdmRegDef.TYPE_D_ENERGY) {
                    const key = 'dTot' + importPrefix + "De";
                    if (extraCells[key])
                        extraCells[key] += d || 0;
                    else
                        extraCells[key] = d || 0;

                    // dTot = nb de reg concernés par dTotIDe et dTotEDe
                    if (extraCells['dTot'])
                        extraCells['dTot']++;
                    else
                        extraCells['dTot'] = 1;
                }
            }

            if (reg.is_derniere_cost) {
                if (isNaN(c)) c = 0;
                if (extraCells['cTot'])
                    extraCells['cTot'] += c;
                else
                    extraCells['cTot'] = c;
            }

            if (!reg.getTypeHash('d').includes('NotVarExpl')) {
                let d = 1;
                if (isNaN(d)) d = 0;
                if (rowData) d = Number(rowData.data['d' + reg.num]);
                if (extraCells[reg.getTypeHash('d')]) {
                    extraCells[reg.getTypeHash('d')] += d;
                } else extraCells[reg.getTypeHash('d')] = d;
            }

            if (!reg.getTypeHash('c').includes('NotVarExpl')) {
                let c = 1;
                if (isNaN(c)) c = 0;
                if (rowData) c = Number(rowData.data['c' + reg.num]);
                if (extraCells[reg.getTypeHash('c')]) {
                    extraCells[reg.getTypeHash('c')] += c;
                } else extraCells[reg.getTypeHash('c')] = c;
            }
            //
        });

        if (rowData) {
            extraCells['dTot'] = Number(extraCells['dTotImpDe']) || 0;
            if (extraCells['dTotExpDe']) extraCells['dTot'] -= Number(extraCells['dTotExpDe']) || 0;
            let cTotImpDe = 0;
            let cTotExpDe = 0;
            Object.keys(extraCells).forEach(key => {
                if (key.includes('cTotImpDe')) cTotImpDe += extraCells[key];
                if (key.includes('cTotExpDe')) cTotExpDe += extraCells[key];
            });
            extraCells['cTotImpDe'] = cTotImpDe;
            extraCells['cTotExpDe'] = cTotExpDe;
        }
        //console.log("getTotFields:", extraCells);
        extraCells = Optimise.sortObjectKeys(extraCells);
        return extraCells;
    }

    /// TODO: make getter
    getIsHaveMultipleSeries() {
        //return false;
        return this.ve2 && this.ve2.length > 3;
    }

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (dynProps['is_vpdm'] === 1) this.is_vpdm = true;
        else this.is_vpdm = false;
        if (this.yearly_config) {
            /// TODO: check why backend return string not object
            if (typeof this.yearly_config === "string")
                this.yearly_config = JSON.parse(this.yearly_config);
            this.yearly_config_map = new Map<number, PdmYearlyConfig>();
            Object.keys(this.yearly_config).forEach(year => {
                this.yearly_config_map.set(Number(year), new PdmYearlyConfig(this.yearly_config[year]));
            });
        }
    }

    /// TODO: PdmData row is already a mensual data, can optimise algo
    /// TODO: filter never used,
    getMensualData(filter: any = null): PdmDataRow[] {
        const rawArr = Array.from(this.relevesCache.values())
            .sort((a, b) => a.date.localeCompare(b.date));
        if (filter) return rawArr.filter(item => item.releve_year === Number(filter));
        else return rawArr;
    }


    /// TODO: replace with func that calc this for every year and store output cache
    //  TODO: deltas will be calced in real time between the values of 2 selected years
    // calc for every year the 12 plotVars and calc deltas with ref year month to month
    getDataForYear(yearOfSerie: number, plot: Plot): any[] {
        let accumulator = 0;
        let accumulator2 = 0;
        let daysAccumulator = 0;
        const corrected: boolean = plot.correct;
        const cumulated: boolean = plot.cumul;
        const valKey: string = plot.valKey;
        const valKey2: string = plot.valKey.toUpperCase().replace("VE1", "VE2").toLowerCase();
        const rowsForYear = this.getMensualData(yearOfSerie);
        const unitFactor = plot.getUnitFactor();

        /*rowsForYear.forEach((dt, i) => {
            console.log("# rowsForYear.", dt.metas, "|" + i, dt.date, dt.dist, dt.days, dt.releve_year, dt.data_key, dt.releve_num);
        });
        */

        const retVal = rowsForYear.map((item, index) => {
            let val = item.exported[valKey];
            let val2 = item.exported[valKey2];
            const dataForYearAndMonth = rowsForYear[index];
            const dataForYearRefAndMonth = rowsForYear[index];
            const days = item.days;

            if (corrected && Number(days > 0)) {
                val = Number(val) * 30 / Number(days);
                val2 = Number(val2) * 30 / Number(days);
            } else {
                val = Number(val);
                val2 = Number(val2);
            }
            val = val * unitFactor;
            accumulator += val;
            val2 = val2 * unitFactor;
            accumulator2 += val2;

            daysAccumulator += days;

            if (dataForYearAndMonth && dataForYearRefAndMonth) {
                const deltaToRefYear = Number(dataForYearAndMonth.ve) - Number(dataForYearRefAndMonth.ve);
                const deltaPercent = 100 * deltaToRefYear / Number(dataForYearRefAndMonth.ve);

                return {
                    val: cumulated ? accumulator : val,
                    val2: cumulated ? accumulator2 : val2,
                    days: item.dist,
                    date: item.date,
                    deltaToRefYear,
                    deltaPercent,
                    ...item
                };
            } else {
                if (!dataForYearAndMonth)
                    throw new Error("EP-ERR: Données manquantes année courante");
                if (!dataForYearRefAndMonth)
                    throw new Error("EP-ERR: Données manquantes année Réf.");
                //console.error("dataForYear[index] is undefined:", index, dataForYear, 'YEAR:' + yearOfSerie, debug);
                return {
                    val: cumulated ? accumulator : val,
                    date: item.date,
                    deltaToRefYear: -1,
                    deltaPercent: -1,
                    ...item
                };
            }

        });
        //console.log("Cumul", retVal);
        return retVal;
    }

    // get config for year, if not present create empty config
    getYearlyConfigForYear(year: number) {
        if (!this.yearly_config) this.yearly_config = {};
        if (!this.yearly_config[year.toString()]) {
            this.yearly_config[year.toString()] = new PdmYearlyConfig({'year_ref': year - 1, 'year': year});
            this.yearly_config_map.set(Number(year), new PdmYearlyConfig(this.yearly_config[year]));
        }
        return this.yearly_config_map.get(year);
    }
}
