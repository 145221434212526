import {H} from "../helpers/H";
import {evaluate, index, mean, multiply} from "mathjs";
import * as MlMatrix from "ml-matrix";
import {PlotUnit} from "./models";
import {PdmDataMeteo, PdmDataRow} from "./PdmDataRow";
import {K} from "./K";
import {PdmSignature} from "./PdmSignature";
import {Optimise} from "../helpers/Optimise";
import * as MathJS from "mathjs";
import {Observable} from "rxjs";

/*
import regression from 'regression';
import {Equation, parse} from "algebra.js";
import {jsstats} from "js-stats/src/jsstats";
*/

export class PdmYearlyConfig {
    cassure_type: string;
    cassure_flat: string;
    cassure_final: number;
    cassure_manual: number;
    cassure_sd: boolean;
    cassure_chosen_reg_index: number = 0;

    year: number; // year of current config, redusdant with the parent key
    year_ref: number;
    vars_used: string[] = [];
    model: number[] = [];

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.cassure_chosen_reg_index === undefined)
            this.cassure_chosen_reg_index = 0;

    }
}

export class PdmYearlyCalcedData {
    releve_year: number;
    releve_num: number;
    month_len: number;
    days_cumul: number;
    date_prev: string = null;
    used_model: number; // year
    used_cassure: number; // of refYear
    date: string = null;
    ve: { raw: number, corr: number, cumul: number } = null;
    var_meteo: { tmoy: number, hum: number, ray: number, djSI: number, djEP: number, used_cassure: number } = null;
    var_extra: { v1?: number, v2?: number, v3?: number } = null;
    var_regs: any = null; // key= REG{regNum}

    var_labels = {};
    ts: number = 0;

    constructor(curr_releve: PdmDataRow, pdmYearlyConfig: PdmYearlyConfig, ve_acc: number, days_cumul: number) {
        this.releve_year = curr_releve.releve_year;
        this.releve_num = curr_releve.releve_num;
        this.month_len = curr_releve.days;
        this.days_cumul = days_cumul;
        this.date_prev = curr_releve.date_prev;
        this.date = curr_releve.date;
        this.used_cassure = pdmYearlyConfig.cassure_final;
        const meteoProcessor = new PdmDataMeteo(curr_releve.vars_meteo);
        this.var_meteo = {
            tmoy: meteoProcessor.m1,
            hum: meteoProcessor.m2,
            ray: meteoProcessor.m3,
            djSI: meteoProcessor.getDJ('c', 12, 20),
            djEP: meteoProcessor.getDJ('c', this.used_cassure, this.used_cassure),
            used_cassure: this.used_cassure
        };
        this.var_extra = {};
        Object.keys(curr_releve.vars_horaire).sort()
            .forEach((key, index) => {
                const varKey = 'v' + Number(index + 1); //v1 v2 v3
                this.var_labels[varKey] = key;// storing var label in labels
                this.var_extra[varKey] = curr_releve.vars_horaire[key];
            });

        this.var_regs = {};
        for (let i = 1; i < 5; i++) {
            const varKey = 'reg' + i; // reg1 reg2 reg3 ...
            if (curr_releve.vars_reg['d' + i]) {
                this.var_labels[varKey] = curr_releve.vars_reg['d' + i + '_lb'];
                this.var_regs[varKey] = curr_releve.vars_reg['d' + i];
            }
        }
        this.ve = {
            raw: curr_releve.ve,
            corr: curr_releve.ve * 30 / this.month_len,
            cumul: ve_acc + curr_releve.ve
        };
    }

    updateDj(curr_releve: PdmDataRow, newPdmYearlyConfig: PdmYearlyConfig) {
        this.used_cassure = newPdmYearlyConfig.cassure_final;
        const meteoProcessor = new PdmDataMeteo(curr_releve.vars_meteo);
        this.var_meteo.djSI = meteoProcessor.getDJ('c', 12, 20);
        this.var_meteo.djEP = meteoProcessor.getDJ('c', this.used_cassure, this.used_cassure);
    }
}

export class PdmYearConfig {
    fluid: string;
    agent: string;
    pdm_type: string;

    uid: string;
    uid_site: string;
    uid_client: string;
    uid_pdm: string;

    year: number;
    year_ref: number;
    tarif: number;

    real_cons: number[];
    real_cost: number[];
    predicted_cons: number[];
    predicted_cost: number[];

    economies: string;

    ts_created: string;
    ts_updated: string;

    plots_units: Map<string, PlotUnit> = new Map<string, PlotUnit>();

    investment: number = 0;
    fp: number = 0;
    ceges: number = 0;

    cassure: number = 0;
    signature_year: PdmSignature = null;
    model_year: PdmYearModel = null;
    signature_year_ref: PdmSignature = null;// cache used signature for calculations
    model_year_ref: PdmYearModel = null;//cache used model for calculations
    texts: string;
    influence_params: string;
    subventions: number = 0;
    ts: number;//ts calced

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
            if (key === 'plots_units' && dynProps[key]) {
                this.plots_units = new Map<string, PlotUnit>(Object.entries(dynProps[key]));
            }
        });

    }

    getUnitForPlot(key: string): PlotUnit {
        if (this.plots_units && this.plots_units.has(key))
            return this.plots_units.get(key);
        else {
            return new PlotUnit(key, this.fluid);
        }
    }

    getJson(): any {
        return H.customObjectToJson(this);
    }
}

export class PdmYearModel {
    r2: number;
    model: number[];
    used_cassure: number = 0;
    varExpList: string[];
    onlyPositiveCons = false;
    ts: number;//ts calced
    private x: any[] = [];
    private b: any[] = [];
    plotLabels = {};

    constructor(dynProps: any = {}) {
        this.varExpList = [];
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        this.ts = H.unixTs();
    }

    calcModel(dataRowsForYear: PdmDataRow[]) {
        this.x = [];
        this.b = [];

        let counterOk = 0, counterKO = 0;
        K.months.forEach((name, m) => {
            const dataItem = dataRowsForYear[m];
            if (!dataItem) {
                console.log("rawDataForYear !dataItem", dataRowsForYear);
                return;
            }
            let consItem = Number(dataRowsForYear[m].ve || 0);//* unit_mult
            if (this.onlyPositiveCons && consItem === 0) {

            } else {
                const xVal = this.getVarExpForMonthIndex(dataRowsForYear[m], m);
                this.x.push(xVal);
                this.b.push([consItem, 0, 0]);
                //console.log('X-B', this.x, this.b, dataRowsForYear);
            }
        });

        try {
            const bMat = new MlMatrix.Matrix(this.b).transpose();
            const xMat = new MlMatrix.Matrix(this.x);
            const xInvTrans = MlMatrix.inverse(xMat, true).transpose();
            const a = multiply(bMat.to2DArray(), xInvTrans.to2DArray());
            this.model = a[0];
            counterOk++;
            this.ts = H.unixTs();
        } catch (e) {
            counterKO++;
            console.log(e.message);
        }
        this.calcModelQuality(dataRowsForYear);
        // console.log('calc', rawDataForYear, this);
    }

    calcModelQuality(rawDataForYear: PdmDataRow[]) {

        let sumPredicted = 0;
        let sumValues = 0;
        let predictedValues = [];
        let deltaInPercent = [];
        let refYearValues = [];
        let SCE = 0;
        let SCR = 0;   // R2=SCE/(SCR+SCE)

        K.months.forEach((m, ind) => {
            const dataForMonth = rawDataForYear[ind];

            if (!dataForMonth) {
                console.error('calcModelQuality:dataForMonth empty for monthIndex', ind, "rawDataForYear", rawDataForYear, "Model:", this.model);
                return;
            }
            const consItem = Number(dataForMonth.ve);
            if (this.onlyPositiveCons && consItem === 0) return;
            refYearValues.push(consItem);
            sumValues += consItem;
            const xVal = this.getVarExpForMonthIndex(dataForMonth, ind);
            // console.log("calcModelQuality", this, this.model, xVal);
            const res = evaluate('a * b', {a: this.model, b: xVal});
            SCR += Math.pow((res - consItem), 2);
            predictedValues.push(res);
            deltaInPercent.push(consItem ? 100 * (res - consItem) / consItem : 0);
            sumPredicted += res;


        });
        /// TODO: number of month is not always 12
        const totAvg = sumValues / 12;
        predictedValues.forEach((predictedVal, index) => {
            const refVal = refYearValues[index];
            SCE += Math.pow((refVal - totAvg), 2);
        });

        this.r2 = Math.sqrt(SCE / (SCE + SCR));
        // console.log("calcModelQuality", this, totAvg);
    }

    predict(rows: PdmDataRow[], debug = {}) {
        const predictedValues = [];
        try {
            K.months.forEach((m, ind) => {
                const dataForMonth = rows[ind];
                if (!dataForMonth) {
                    return;
                }
                const consItem = Number(dataForMonth.ve);
                const xVal = this.getVarExpForMonthIndex(dataForMonth, ind, debug);// xVal in Ax=B
                //debug['var' + ind] = xVal;

                const res = evaluate('a * x', {a: this.model, x: xVal});
                // console.log('predict:Result: ', res);
                // console.log('predictDebug: ', dataForMonth, xVal, this.model);
                if (this.onlyPositiveCons && consItem === 0) predictedValues.push(0);
                else
                    predictedValues.push(res);
            });
        } catch (e) {
            console.error("predict", e.message);
        }

        return predictedValues;
    }

    getVarExpForMonthIndex(dataForMonth, ind, debug = {}) {
        //const dataForMonth = rawDataForYear[ind];

        if (!dataForMonth.vars_meteo_obj)
            dataForMonth.vars_meteo_obj = new PdmDataMeteo(dataForMonth.vars_meteo);
        const varExpls = this.varExpList.map(varname => {
            let retVal = -1;
            const rowDate = new Date(dataForMonth.date);
            const DAY_NUM = H.getDayNum(rowDate);
            if (varname === 'DAY_COS')
                retVal = Math.cos(DAY_NUM * Math.PI / 180);
            if (varname === 'DAY_NUM')
                retVal = DAY_NUM;
            if (varname === 'DAY_CNT')
                retVal = dataForMonth.days;
            if (varname === 'M_1')
                retVal = dataForMonth.vars_meteo_obj.m1;
            if (varname === 'M_2')
                retVal = dataForMonth.vars_meteo_obj.m2;
            if (varname === 'M_3')
                retVal = dataForMonth.vars_meteo_obj.m3;
            if (varname === 'DJ_EP')
                retVal = dataForMonth.vars_meteo_obj.getDJ('c', this.used_cassure, this.used_cassure);
            if (varname === 'DJ_SI')
                retVal = dataForMonth.vars_meteo_obj.getDJ('c', 12, 20);
            if (varname.includes('EXTRA_')) {
                const varNameClean = varname.replaceAll('EXTRA_', '');
                if (dataForMonth.vars_horaire && dataForMonth.vars_horaire[varNameClean])
                    retVal = Number(dataForMonth.vars_horaire[varNameClean]);
                //console.log("getVarExpForMonthIndex", varNameClean, dataForMonth.vars_horaire, retVal);
            }
            if (varname.includes('REG_')) {
                const varNameClean = varname.replaceAll('REG_', '');
                if (dataForMonth.data && dataForMonth.data[varNameClean])
                    retVal = Number(dataForMonth.data[varNameClean]);
                //console.log("varExplReg", varNameClean, dataForMonth.data, retVal);
            }

            if (!debug[ind]) debug[ind] = {};
            debug[ind][varname] = retVal;
            debug[ind]['date'] = dataForMonth.date;
            return retVal;
        });
        //console.log("getVarExpForMonthIndex", this.varExpList, varExpls);
        return varExpls;
    }

    getModelStr() {
        let retVal = "";
        const varexplLabelMap = {
            DAY_CNT: "Nb Jours",
            DAY_COS: "cos(Num jour)",
            DAY_NUM: "Num jour",
            M_1: "T° Moy",
            M_2: "Humidité",
            M_3: "Rayonnement",
            DJ_SI: "DJC SI",
            DJ_EP: "DJC[" + this.used_cassure.toFixed(1) + "]"
        };
        if (this.model) {
            const strArr = this.model.map((item, index) => {
                //console.log("getModelStr", item, index);
                const newLine = (this.model.length >= 5 && index === 3) ? '<br>' : "";
                return Math.round(item * 1000) / 1000 + "<span>®</span> <b>" + varexplLabelMap[this.varExpList[index]] + "</b>" + newLine;
            });

            retVal = strArr.join(" + ") + " r2: " + this.r2.toFixed(3);
        }

        return retVal;
    }

    getJson(): any {
        return {
            r2: this.r2,
            model: this.model,
            used_cassure: this.used_cassure,
            varExpList: this.varExpList,
            onlyPositiveCons: this.onlyPositiveCons,
            ts: this.ts
        };
    }
}

export class PlotPoint {
    x: number;
    y: number;
    index: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }
}

export class PlotPointSignature extends PlotPoint {
    tGauche: number;
    tDroite: number;
    tTotal: number;
    leftAvg: number;
    leftSlope: number;
    leftConstant: number;
    rightAvg: number;
    rightSlope: number;
    rightConstant: number;
    connexion: number;
    ecart: number;
    p1: number;
    p2: number;
    p3: number;
    p4: number;

    constructor(dynProps: any = {}) {
        super();
    }
}
